import React, { Component } from "react";
import Image24 from "../images/faq1.png";
import "../styles/newhp.css";
import { Row, Col, Card, Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { useState } from "react";
import Footer from "./footer";
import image1 from "../images/kam1.jpeg";
import image2 from "../images/devops.png";
import image3 from "../images/shield.png";
import image4 from "../images/human-brain.png";
import { NavLink } from "react-router-dom";
import image5 from "../images/cloud.png";
import image6 from "../images/settings.png";
import { Link } from "react-router-dom";
import "../styles/navbar.css";

export default class Devops4week extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: 0,
      qfa1: false,
      qfa2: false,
      qfa3: false,
      qfa4: false,
      qfa5: false,
      qfa6: false,
      qfa7: false,
      qfa8: false,
      qga11: false,
      qfa9: false,
      qfa10: false,
      ques11: false,

      ques13: false,
      ques14: false,
      ques15: false,
      ques16: false,
      ques17: false,
      ques20: false,
      ques21: false,
      ques22: false,
      ques23: false,
      ques24: false,
      qfa13: false,
      qfa14: false,
      qfa15: false,
      qfa16: false,
      qfa17: false,
      qfa18: false,
      qfa20: false,
      qfa50: false,
      ques50: false,
      new1: false,
      new11: false,
      qfa23: false,
      qfa24: false,
      qfa21: false,
      qfa22: false,
      qfa18: false,
      qfa12: false,
      ques12: false,
      ques10: false,
      ques9: false,
      ques8: false,
      ques7: false,
      ques6: false,
      ques5: false,
      ques4: false,
      ques3: false,
      code: "91",
      ques2: false,
      ques1: false,
    };
  }
  toggle = () => {
    this.setState({ qfa1: !this.state.qfa1 });
  };
  toggle1 = () => {
    this.setState({ qfa2: !this.state.qfa2 });
  };
  toggle2 = () => {
    this.setState({ qfa3: !this.state.qfa3 });
  };
  toggle3 = () => {
    this.setState({ qfa4: !this.state.qfa4 });
  };
  toggle4 = () => {
    this.setState({ qfa5: !this.state.qfa5 });
  };
  toggle50 = () => {
    this.setState({ qfa50: !this.state.qfa50 });
  };
  toggle5 = () => {
    this.setState({ qfa6: !this.state.qfa6 });
  };
  toggle6 = () => {
    this.setState({ qfa7: !this.state.qfa7 });
  };
  toggle7 = () => {
    this.setState({ qfa8: !this.state.qfa8 });
  };
  toggle8 = () => {
    this.setState({ qfa9: !this.state.qfa9 });
  };
  toggle9 = () => {
    this.setState({ qfa10: !this.state.qfa10 });
  };
  toggle11 = () => {
    this.setState({ qfa11: !this.state.qfa11 });
  };
  toggle12 = () => {
    this.setState({ qfa12: !this.state.qfa12 });
  };
  toggle13 = () => {
    this.setState({ qfa13: !this.state.qfa13 });
  };
  toggle14 = () => {
    this.setState({ qfa14: !this.state.qfa14 });
  };
  toggle15 = () => {
    this.setState({ qfa15: !this.state.qfa15 });
  };
  toggle16 = () => {
    this.setState({ qfa16: !this.state.qfa16 });
  };
  toggle17 = () => {
    this.setState({ qfa17: !this.state.qfa17 });
  };
  toggle20 = () => {
    this.setState({ qfa20: !this.state.qfa20 });
  };
  toggle21 = () => {
    this.setState({ qfa21: !this.state.qfa21 });
  };
  toggle22 = () => {
    this.setState({ qfa22: !this.state.qfa22 });
  };
  toggle23 = () => {
    this.setState({ qfa23: !this.state.qfa23 });
  };
  toggle24 = () => {
    this.setState({ qfa24: !this.state.qfa24 });
  };

  render() {
    return (
      <div
        style={{
          overflow: "hidden",
          backgroundImage:
            "linear-gradient(to right, #ff7686 5%, #911bed 20%, #2e38bf 30%, #090920 45%)",
          backgroundSize: "100% 100%",
          backgroundPosition: "left top",
        }}
      >
        <div>
          <nav class="navbar navbar-expand-lg bg-body-tertiary " id="navbar">
            <div class="container-fluid">
              <a class="navbar-brand" href="#">
                <img id="logo" src={image1} alt="logo"></img>
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    {/* <a class="nav-link active" aria-current="page" href="#">
                Home
              </a> */}
                    <a
                      className="nav-link"
                      href="/"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Home
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      className="nav-link"
                      href="/Skill"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Why skills&nbsp;?
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/Corporates"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Corporates
                    </a>
                  </li>
                  <div className="courseonly">
                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="/technologies"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Courses
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="tag" src={image2}></img> &nbsp;
                              Software dev
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Database
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Game development
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Mobile development
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Programming languages
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Web Development
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="shield" src={image3}></img>&nbsp;
                              Security
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Certification
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Governance,risk,& compliance
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security architecture & engineering
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security operations
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security testing
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="brain" src={image4}></img>&nbsp;
                              Machine learning
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Big data
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Business intelligence
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Data visualization
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Databases
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Languages & libraries
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Machine learning
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="cloud" src={image5}></img> &nbsp;
                              Cloud
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Cloud architecture & design
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Cloud platforms
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Salesforce CRM
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="shield" src={image3}></img>&nbsp; IT
                              Ops
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Client operating systems
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Collaboration platforms
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Configuration management
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Containers
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              IT automation
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Network architecture
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Virtualization
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </div>
                  <div className="courseonly1">
                    <li class="nav-item dropdown dropdown-mega position-static">
                      <a
                        href="/technologies"
                        class="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="Outside"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Courses
                      </a>

                      <div class="dropdown-menu shadow container-fluid col-11 w-70 mx-5">
                        <div class="mega-content px-md-4">
                          <div class="conatiner-fluid">
                            <div class="row">
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-3">
                                  <img class="tag" src={image2}></img>
                                  <h5
                                    class="drop-title my-2 mx-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Software dev
                                  </h5>
                                </div>
                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Database
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Game development
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Mobile development
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Programming languages
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Web development
                                  </a>
                                </div>
                                <div class="logs mb-3">
                                  <img class="shield" src={image3}></img>
                                  <h5
                                    class="drop-title my-3 mt-4 mx-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Certification
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Governance,risk,& compliance
                                  </a>
                                  <a
                                    class="dropdown-item "
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security architecture & engineering
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security operations
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security testing
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-2">
                                  <img class="brain" src={image4}></img>
                                  <h5
                                    class="drop-title mt-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Data & machine learning
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Big data
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Business intelligence
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Data visualization
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Databases
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Languages & libraries
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Machine learning
                                  </a>
                                </div>
                                <div class="logs mb-3">
                                  <img class="cloud" src={image5}></img>
                                  <h5
                                    class="drop-title my-3 px-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud architecture & design
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud platforms
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Salesforce CRM
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-3">
                                  <img class="setting" src={image6}></img>
                                  <h5
                                    class="drop-title my-2 mx-1"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    IT Ops
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Client operating systems
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Collaboration platforms
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Configuration management
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Containers
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    IT automation
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Network architecture
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Virtualization
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      href="about"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Company
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/ContactUs"
                      style={{ fontFamily: "Prompt" }}
                    >
                      ContactUs&nbsp;
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/Register"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Free Register&nbsp;
                    </a>
                  </li>
                </ul>
                {/* <form class="d-flex" role="search">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button class="btn btn-outline-success" type="submit">
              Search
            </button>
          </form> */}
              </div>
            </div>
          </nav>
        </div>
        <div
          className="topcompany"
          style={{
            // backgroundColor: "rgb(4,27,61)",
            color: "white",
            textAlign: "center",
            fontSize: "38px",
            fontWeight: "900",

            fontFamily: "Prompt",
          }}
        >
          <br />

          <div className="col-sm-11 col-md-10 mx-auto my-5">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <h3 className="display-5 font-weight-bold">
                      Program Overview
                    </h3>
                  </div>
                  <div className="d-flex justify-content-between mr-3"></div>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-3">
                <p className="lead my-1 text-white">Duration</p>
                <p className=" my-1 font-weight-bold banner-highlights text-warning">
                  4 Weeks
                  <br />
                </p>
              </div>
              <div className="col-sm-6 col-md-3">
                <p className="lead my-1 text-white">Format</p>
                <p className=" my-1 font-weight-bold banner-highlights text-warning">
                  Online
                </p>
              </div>
              <div className="col-sm-6 col-md-3">
                <p className="lead my-1 text-white">Hiring Partners</p>
                <p className=" my-1 font-weight-bold banner-highlights text-warning">
                  100+ companies
                </p>
              </div>{" "}
              <div className="col-sm-6 col-md-3">
                <p className="lead my-1 text-white">Max CTC upto</p>
                <p className=" my-1 font-weight-bold banner-highlights text-warning">
                  21L/annum
                </p>
              </div>
            </div>
          </div>
          <div className="row m-0 bg-white">
            <div className="col-sm-11 col-md-10 mx-auto my-5">
              <div>
                <h2 className="display-5 font-weight-bold bannerTitleText">
                  About KambGeeks
                </h2>
              </div>
              <div>
                <p className="lead cm p-text">
                  Welcome to KambGeeks DevOps Mastery, a premier three-month
                  program designed to launch your career into the dynamic world
                  of DevOps! As a leader in project-based career programs, we
                  guarantee Placement Guidance upon completing this
                  comprehensive journey. KambGeeks is committed to providing
                  top-notch mentoring and career guidance from experts
                  representing renowned companies such as Google, Microsoft, and
                  Flipkart. Our mission is clear: to secure job placements in
                  leading companies offering competitive salaries. Dive deep
                  into our comprehensive DevOps Program, meticulously crafted to
                  cover the most relevant technologies in your preferred
                  language (English), empowering you to thrive as a DevOps
                  professional. Join us for 4 Weeks of immersive exploration
                  into the exciting realm of DevOps, and unlock your potential
                  in this high-demand field!
                </p>
              </div>
            </div>
            <div className="row m-0  ">
              <div className="col-sm-11 col-md-10 mx-auto py-5 text-white">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <h2 className="display-5 font-weight-bold bannerTitleText">
                      The Program Details
                    </h2>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div
                    style={{
                      padding: "3%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Row style={{ margin: "0px" }}>
                      <Col md="12" lg="12" sm="12" xs="12  text-dark">
                        <Fade right>
                          <div
                            class="faq-container"
                            style={{ padding: "1% 5% 2% 2%" }}
                          >
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                DevOps structure
                              </h3>
                              <br />
                              <h4
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontSize: "20",
                                  lineHeight: "2em",
                                  fontWeight: "400",
                                }}
                              >
                                In this 4-week DevOps workshop, participants
                                will delve into the fundamentals of DevOps
                                principles, tools, and practices. They will gain
                                hands-on experience with CI/CD pipelines,
                                automation, and advanced DevOps concepts to
                                enhance their skills and understanding in this
                                dynamic field.
                              </h4>

                              {this.state.qfa50 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  <ul>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Understanding of DevOps Concepts:
                                      </strong>{" "}
                                      Participants will develop a comprehensive
                                      understanding of DevOps principles,
                                      practices, and methodologies, including
                                      continuous integration, continuous
                                      delivery, and infrastructure as code.
                                    </li>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Familiarity with Tools and Technologies:
                                      </strong>{" "}
                                      Participants will become familiar with a
                                      wide range of DevOps tools and
                                      technologies, including version control
                                      systems (e.g., Git), build automation
                                      tools (e.g., Jenkins), containerization
                                      platforms (e.g., Docker), and
                                      configuration management tools (e.g.,
                                      Ansible).
                                    </li>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Hands-On Experience:
                                      </strong>{" "}
                                      Through practical exercises and projects,
                                      participants will gain hands-on experience
                                      in implementing DevOps practices, setting
                                      up pipelines, automating deployments, and
                                      managing infrastructure as code.
                                    </li>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Collaborative Learning Environment:
                                      </strong>{" "}
                                      Participants will engage in group
                                      discussions, collaborative projects, and
                                      knowledge-sharing sessions to foster a
                                      supportive learning community and exchange
                                      best practices.
                                    </li>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Real-World Applications:
                                      </strong>{" "}
                                      The program will focus on real-world case
                                      studies and examples to illustrate the
                                      application of DevOps principles in
                                      various industries and scenarios.
                                    </li>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Career Development:
                                      </strong>{" "}
                                      Participants will gain insights into
                                      career paths in DevOps and acquire
                                      valuable skills that are in high demand in
                                      the industry, enhancing their career
                                      prospects and opportunities for
                                      advancement.
                                    </li>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Certification Preparation:
                                      </strong>{" "}
                                      Upon completion of the program,
                                      participants will be prepared to pursue
                                      relevant DevOps certifications, validating
                                      their knowledge and skills in the field.
                                    </li>
                                    <br />
                                    <p style={{ fontWeight: "800" }}>
                                      Workshop for DevOps
                                    </p>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Week 1: Introduction to DevOps
                                      </strong>
                                      <ul>
                                        <li>Understanding DevOps Principles</li>
                                        <li>
                                          Overview of Continuous Integration and
                                          Continuous Delivery
                                        </li>
                                        <li>
                                          Introduction to Infrastructure as Code
                                        </li>
                                        <li>
                                          Overview of DevOps Tools and
                                          Technologies
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Week 2: DevOps Tools and Practices
                                      </strong>
                                      <ul>
                                        <li>
                                          Version Control Systems (e.g., Git)
                                        </li>
                                        <li>
                                          Build Automation Tools (e.g., Jenkins)
                                        </li>
                                        <li>
                                          Containerization Platforms (e.g.,
                                          Docker)
                                        </li>
                                        <li>
                                          Configuration Management Tools (e.g.,
                                          Ansible)
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Week 3: DevOps Pipelines and Automation
                                      </strong>
                                      <ul>
                                        <li>
                                          Setting Up Continuous Integration
                                          Pipelines
                                        </li>
                                        <li>Automating Deployment Processes</li>
                                        <li>
                                          Implementing Infrastructure as Code
                                        </li>
                                        <li>
                                          Introduction to DevOps Orchestration
                                          Tools
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Week 4: Advanced DevOps Concepts
                                      </strong>
                                      <ul>
                                        <li>Advanced CI/CD Strategies</li>
                                        <li>
                                          Monitoring and Logging in DevOps
                                        </li>
                                        <li>Security Practices in DevOps</li>
                                        <li>
                                          Continuous Improvement and Learning
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </p>
                              )}
                              {this.state.ques50 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black", textAlign: "left" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques50: false },
                                      this.toggle50
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black", textAlign: "left" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques50: true },
                                      this.toggle50
                                    )
                                  }
                                />
                              )}
                            </div>
                            <div className="text-center mt-4 mb-3">
                              <a
                                className="btn get-started-free-btn"
                                href="/Register"
                              >
                                Register For 1 Month Program
                              </a>
                            </div>

                            {/* <div class="faq active">
                      <h3 class="faq-title">
                        How much is the fee for this training?
                      </h3>
                      {this.state.qfa3 && (
                        <p class="faq-text">
                          The course fee is highly affordable and we provide
                          liberty to split the payments as during training &
                          after placement. Also, you can pay in installments
                        </p>
                      )}
                      {this.state.ques3 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: false }, this.toggle2)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: true }, this.toggle2)
                          }
                        />
                      )}
                    </div> */}
                            {/* <br /> */}
                          </div>
                        </Fade>
                      </Col>

                      <Col />
                    </Row>
                  </div>
                  <div className="col-sm-11 col-md-10 mx-auto my-5">
                    <div>
                      <h2
                        className="display-5 font-weight-bold bannerTitleText"
                        style={{ textAlign: "center" }}
                      >
                        For Detailed DevOps Program Join our One to One
                        Mentorship
                      </h2>
                    </div>
                    <div>
                      <Row style={{ margin: "0px" }}>
                        <Col md="6" lg="6" sm="6" xs="12  text-dark">
                          <Fade right>
                            <div
                              class="faq-container"
                              style={{ padding: "1% 5% 2% 2%" }}
                            >
                              <div class="faq active">
                                <h3
                                  class="faq-title"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "600",
                                  }}
                                >
                                  Module-0: Docker
                                </h3>

                                {this.state.qfa1 && (
                                  <p
                                    class="faq-text"
                                    style={{
                                      fontFamily: "Prompt",
                                      color: "black",
                                      textAlign: "left",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <ul>
                                      <li>Introduction</li>
                                      <li>
                                        The cloud Architecture and Landscape
                                      </li>
                                      <li>AWS Fundamentals</li>
                                      <li>Amazon EC2</li>
                                      <li>Introduction to Microsoft Azure</li>
                                      <li>Create an Azure Account</li>
                                      <li>Azure Architecture</li>
                                      <li>
                                        Create App and website hosted in Azure
                                      </li>
                                      <li>Introduction to Jenkins</li>
                                      <li>
                                        Continuous Integration with Jenkins
                                      </li>
                                      <li>Configure Jenkins</li>
                                      <li>Jenkins Management</li>
                                      <li>Scheduling build Jobs</li>
                                      <li>POLL SCM</li>
                                      <li>Build Periodically</li>
                                      <li>Maven Build Scripts</li>
                                      <li>
                                        Support for the GIT version control
                                        System
                                      </li>
                                      <li>Different types of Jenkins Jobs</li>
                                      <li>Jenkins Build Pipeline</li>
                                      <li>Parent and Child Builds</li>
                                      <li>Sequential Builds</li>
                                      <li>
                                        Jenkins Master & Slave Node
                                        Configuration
                                      </li>
                                      <li>Jenkins Workspace Management</li>
                                      <li>Securing Jenkins</li>
                                      <li>Authentication</li>
                                      <li>Authorization</li>
                                      <li>Confidentiality</li>
                                      <li>Creating Users</li>
                                      <li>Jenkins Plugins</li>
                                      <li>Installing Jenkins Plugins</li>
                                      <li>SCM plugin</li>
                                      <li>Build and test</li>
                                    </ul>
                                  </p>
                                )}
                                {this.state.ques1 ? (
                                  <IoIosArrowDropdownCircle
                                    class="faq-toggle"
                                    style={{
                                      color: "black",
                                      textAlign: "left",
                                    }}
                                    onClick={() =>
                                      this.setState(
                                        { ques1: false },
                                        this.toggle
                                      )
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDroprightCircle
                                    class="faq-toggle"
                                    style={{
                                      color: "black",
                                      textAlign: "left",
                                    }}
                                    onClick={() =>
                                      this.setState(
                                        { ques1: true },
                                        this.toggle
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <br />
                              <div class="faq active">
                                <h3
                                  class="faq-title"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "600",
                                  }}
                                >
                                  Module-1: Introduction to Docker
                                </h3>

                                {this.state.qfa2 && (
                                  <p
                                    class="faq-text"
                                    style={{
                                      fontFamily: "Prompt",
                                      color: "black",
                                      textAlign: "left",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <div>
                                      <ul>
                                        <li>How to get Docker Image?</li>
                                        <li>What is Docker Image?</li>
                                        <li>Docker Installation</li>
                                        <li>
                                          Working with Docker Containers
                                          <ul>
                                            <li>What is Container</li>
                                            <li>Docker Engine</li>
                                            <li>
                                              Creating Containers with an Image
                                            </li>
                                            <li>Working with Images</li>
                                          </ul>
                                        </li>
                                        <li>Docker Command Line Interface</li>
                                        <li>Docker Compose</li>
                                        <li>Docker Hub</li>
                                        <li>Docker Trusted Registry</li>
                                        <li>Docker Swarm</li>
                                        <li>Docker Attach</li>
                                        <li>Docker File & Commands</li>
                                      </ul>
                                    </div>
                                  </p>
                                )}
                                {this.state.ques2 ? (
                                  <IoIosArrowDropdownCircle
                                    class="faq-toggle"
                                    style={{
                                      color: "black",
                                      textAlign: "left",
                                    }}
                                    onClick={() =>
                                      this.setState(
                                        { ques2: false },
                                        this.toggle1
                                      )
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDroprightCircle
                                    class="faq-toggle"
                                    style={{
                                      color: "black",
                                      textAlign: "left",
                                    }}
                                    onClick={() =>
                                      this.setState(
                                        { ques2: true },
                                        this.toggle1
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <br />
                              {/* <div class="faq active">
                      <h3 class="faq-title">
                        How much is the fee for this training?
                      </h3>
                      {this.state.qfa3 && (
                        <p class="faq-text">
                          The course fee is highly affordable and we provide
                          liberty to split the payments as during training &
                          after placement. Also, you can pay in installments
                        </p>
                      )}
                      {this.state.ques3 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: false }, this.toggle2)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: true }, this.toggle2)
                          }
                        />
                      )}
                    </div> */}
                              {/* <br /> */}
                              <div class="faq active">
                                <h3
                                  class="faq-title"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "600",
                                  }}
                                >
                                  Module-2: Earn to live in Terminal
                                </h3>

                                {this.state.qfa4 && (
                                  <p
                                    class="faq-text"
                                    style={{
                                      fontFamily: "Prompt",
                                      color: "black",
                                      fontWeight: "400",
                                      textAlign: "left",
                                    }}
                                  >
                                    <ul>
                                      <li>Process Monitoring</li>
                                      <li>Performance Monitoring</li>
                                      <li>Networking Tools</li>
                                      <li>Text Manipulation</li>
                                    </ul>
                                  </p>
                                )}
                                {this.state.ques4 ? (
                                  <IoIosArrowDropdownCircle
                                    class="faq-toggle"
                                    style={{ color: "black" }}
                                    onClick={() =>
                                      this.setState(
                                        { ques4: false },
                                        this.toggle3
                                      )
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDroprightCircle
                                    class="faq-toggle"
                                    style={{ color: "black" }}
                                    onClick={() =>
                                      this.setState(
                                        { ques4: true },
                                        this.toggle3
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <br />
                              <div class="faq active">
                                <h3
                                  class="faq-title"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "600",
                                  }}
                                >
                                  Module-3: Infrastructure Provisioning
                                </h3>

                                {this.state.qfa5 && (
                                  <p
                                    class="faq-text"
                                    style={{
                                      fontFamily: "Prompt",
                                      color: "black",
                                      textAlign: "left",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <ul>
                                      <li>AWS CDK</li>
                                      <li>CloudFormation</li>
                                      <li>Pulumi</li>
                                      <li>Terraform</li>
                                    </ul>
                                  </p>
                                )}

                                {this.state.ques5 ? (
                                  <IoIosArrowDropdownCircle
                                    class="faq-toggle"
                                    style={{ color: "black" }}
                                    onClick={() =>
                                      this.setState(
                                        { ques5: false },
                                        this.toggle4
                                      )
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDroprightCircle
                                    class="faq-toggle"
                                    style={{ color: "black" }}
                                    onClick={() =>
                                      this.setState(
                                        { ques5: true },
                                        this.toggle4
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <br />
                              <div class="faq active">
                                <h3
                                  class="faq-title"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "600",
                                  }}
                                >
                                  Module-4: Secret Management
                                </h3>

                                {this.state.qfa11 && (
                                  <p
                                    class="faq-text"
                                    style={{
                                      fontFamily: "Prompt",
                                      color: "black",
                                      textAlign: "left",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <ul>
                                      <li>Sealed Secrets</li>
                                      <li>Cloud Specific Tools</li>
                                      <li>Vault</li>
                                      <li>SOPS</li>
                                    </ul>
                                  </p>
                                )}

                                {this.state.ques11 ? (
                                  <IoIosArrowDropdownCircle
                                    class="faq-toggle"
                                    style={{ color: "black" }}
                                    onClick={() =>
                                      this.setState(
                                        { ques11: false },
                                        this.toggle11
                                      )
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDroprightCircle
                                    class="faq-toggle"
                                    style={{ color: "black" }}
                                    onClick={() =>
                                      this.setState(
                                        { ques11: true },
                                        this.toggle11
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <br />

                              <br />
                            </div>
                          </Fade>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12  text-dark">
                          <Fade right>
                            <div
                              class="faq-container"
                              style={{ padding: "1% 5% 2% 2%" }}
                            >
                              {/* <div class="faq active">
                      <h3 class="faq-title">
                        How much is the fee for this training?
                      </h3>
                      {this.state.qfa3 && (
                        <p class="faq-text">
                          The course fee is highly affordable and we provide
                          liberty to split the payments as during training &
                          after placement. Also, you can pay in installments
                        </p>
                      )}
                      {this.state.ques3 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: false }, this.toggle2)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: true }, this.toggle2)
                          }
                        />
                      )}
                    </div> */}
                              {/* <br /> */}

                              <div class="faq active">
                                <h3
                                  class="faq-title"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "600",
                                  }}
                                >
                                  Module-5: Infrastructure Monitoring
                                </h3>

                                {this.state.qfa12 && (
                                  <p
                                    class="faq-text"
                                    style={{
                                      fontFamily: "Prompt",
                                      color: "black",
                                      textAlign: "left",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <ul>
                                      <li>Zabbix</li>
                                      <li>Prometheus</li>
                                      <li>Datadog</li>
                                      <li>Grafana</li>
                                    </ul>
                                  </p>
                                )}

                                {this.state.ques12 ? (
                                  <IoIosArrowDropdownCircle
                                    class="faq-toggle"
                                    style={{ color: "black" }}
                                    onClick={() =>
                                      this.setState(
                                        { ques12: false },
                                        this.toggle12
                                      )
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDroprightCircle
                                    class="faq-toggle"
                                    style={{ color: "black" }}
                                    onClick={() =>
                                      this.setState(
                                        { ques12: true },
                                        this.toggle12
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <br />
                              <div class="faq active">
                                <h3
                                  class="faq-title"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "600",
                                  }}
                                >
                                  Module-6: Cloud Design Patterns
                                </h3>

                                {this.state.qfa13 && (
                                  <p
                                    class="faq-text"
                                    style={{
                                      fontFamily: "Prompt",
                                      color: "black",
                                      textAlign: "left",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <ul>
                                      <li>Availability</li>
                                      <li>Data Management</li>
                                      <li>Design and Implementation</li>
                                      <li>Management and Monitoring</li>
                                    </ul>
                                  </p>
                                )}

                                {this.state.ques13 ? (
                                  <IoIosArrowDropdownCircle
                                    class="faq-toggle"
                                    style={{ color: "black" }}
                                    onClick={() =>
                                      this.setState(
                                        { ques13: false },
                                        this.toggle13
                                      )
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDroprightCircle
                                    class="faq-toggle"
                                    style={{ color: "black" }}
                                    onClick={() =>
                                      this.setState(
                                        { ques13: true },
                                        this.toggle13
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <br />
                              <div class="faq active">
                                <h3
                                  class="faq-title"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "600",
                                  }}
                                >
                                  Module-7: Service Mesh
                                </h3>

                                {this.state.qfa14 && (
                                  <p
                                    class="faq-text"
                                    style={{
                                      fontFamily: "Prompt",
                                      color: "black",
                                      textAlign: "left",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <ul>
                                      <li>Istio</li>
                                      <li>Consul</li>
                                      <li>Linkerd</li>
                                      <li>Envoy</li>
                                    </ul>
                                  </p>
                                )}

                                {this.state.ques14 ? (
                                  <IoIosArrowDropdownCircle
                                    class="faq-toggle"
                                    style={{ color: "black" }}
                                    onClick={() =>
                                      this.setState(
                                        { ques14: false },
                                        this.toggle14
                                      )
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDroprightCircle
                                    class="faq-toggle"
                                    style={{ color: "black" }}
                                    onClick={() =>
                                      this.setState(
                                        { ques14: true },
                                        this.toggle14
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <br />
                              <div class="faq active">
                                <h3
                                  class="faq-title"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "600",
                                  }}
                                >
                                  Module-8: GitOps
                                </h3>

                                {this.state.qfa15 && (
                                  <p
                                    class="faq-text"
                                    style={{
                                      fontFamily: "Prompt",
                                      color: "black",
                                      textAlign: "left",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <ul>
                                      <li>ArgoCD</li>

                                      <li>FluxCD</li>
                                    </ul>
                                  </p>
                                )}

                                {this.state.ques15 ? (
                                  <IoIosArrowDropdownCircle
                                    class="faq-toggle"
                                    style={{ color: "black" }}
                                    onClick={() =>
                                      this.setState(
                                        { ques15: false },
                                        this.toggle15
                                      )
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDroprightCircle
                                    class="faq-toggle"
                                    style={{ color: "black" }}
                                    onClick={() =>
                                      this.setState(
                                        { ques15: true },
                                        this.toggle15
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </Fade>
                        </Col>

                        <Col />
                      </Row>
                    </div>
                    <div className="text-center mt-4 mb-3">
                      <a
                        className="btn get-started-free-btn"
                        href="/devops3month"
                      >
                        Join our DevOps Program
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </div>
    );
  }
}
