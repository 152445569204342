import React, { Component } from "react";
import Image24 from "../images/faq1.png";
import "../styles/newhp.css";
import { Row, Col, Card, Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { useState } from "react";
import Footer from "./footer";
import image1 from "../images/kam1.jpeg";
import image2 from "../images/devops.png";
import image3 from "../images/shield.png";
import image4 from "../images/human-brain.png";
import { NavLink } from "react-router-dom";
import image5 from "../images/cloud.png";
import image6 from "../images/settings.png";
import { Link } from "react-router-dom";
import "../styles/navbar.css";

export default class AI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: 0,
      qfa1: false,
      qfa2: false,
      qfa3: false,
      qfa4: false,
      qfa5: false,
      qfa6: false,
      qfa7: false,
      qfa8: false,
      qga11: false,
      qfa9: false,
      qfa10: false,
      ques11: false,

      ques13: false,
      ques14: false,
      ques15: false,
      ques16: false,
      ques17: false,
      ques20: false,
      ques21: false,
      ques22: false,
      ques23: false,
      ques24: false,
      qfa13: false,
      qfa14: false,
      qfa15: false,
      qfa16: false,
      qfa17: false,
      qfa18: false,
      qfa20: false,
      qfa23: false,
      qfa24: false,
      qfa21: false,
      qfa22: false,
      qfa18: false,
      qfa12: false,
      ques12: false,
      ques10: false,
      ques9: false,
      ques8: false,
      ques7: false,
      ques6: false,
      ques5: false,
      ques4: false,
      ques3: false,
      code: "91",
      ques2: false,
      ques1: false,
      password: "",
    };
  }
  toggle = () => {
    this.setState({ qfa1: !this.state.qfa1 });
  };
  toggle1 = () => {
    this.setState({ qfa2: !this.state.qfa2 });
  };
  toggle2 = () => {
    this.setState({ qfa3: !this.state.qfa3 });
  };
  toggle3 = () => {
    this.setState({ qfa4: !this.state.qfa4 });
  };
  toggle4 = () => {
    this.setState({ qfa5: !this.state.qfa5 });
  };
  toggle5 = () => {
    this.setState({ qfa6: !this.state.qfa6 });
  };
  toggle6 = () => {
    this.setState({ qfa7: !this.state.qfa7 });
  };
  toggle7 = () => {
    this.setState({ qfa8: !this.state.qfa8 });
  };
  toggle8 = () => {
    this.setState({ qfa9: !this.state.qfa9 });
  };
  toggle9 = () => {
    this.setState({ qfa10: !this.state.qfa10 });
  };
  toggle11 = () => {
    this.setState({ qfa11: !this.state.qfa11 });
  };
  toggle12 = () => {
    this.setState({ qfa12: !this.state.qfa12 });
  };
  toggle13 = () => {
    this.setState({ qfa13: !this.state.qfa13 });
  };
  toggle14 = () => {
    this.setState({ qfa14: !this.state.qfa14 });
  };
  toggle15 = () => {
    this.setState({ qfa15: !this.state.qfa15 });
  };
  toggle16 = () => {
    this.setState({ qfa16: !this.state.qfa16 });
  };
  toggle17 = () => {
    this.setState({ qfa17: !this.state.qfa17 });
  };
  toggle20 = () => {
    this.setState({ qfa20: !this.state.qfa20 });
  };
  toggle21 = () => {
    this.setState({ qfa21: !this.state.qfa21 });
  };
  toggle22 = () => {
    this.setState({ qfa22: !this.state.qfa22 });
  };
  toggle23 = () => {
    this.setState({ qfa23: !this.state.qfa23 });
  };
  toggle24 = () => {
    this.setState({ qfa24: !this.state.qfa24 });
  };
  render() {
    return (
      <div
        style={{
          overflow: "hidden",
          backgroundImage:
            "linear-gradient(to right, #ff7686 5%, #911bed 20%, #2e38bf 30%, #090920 45%)",
          backgroundSize: "100% 100%",
          backgroundPosition: "left top",
        }}
      >
        <div>
          <nav class="navbar navbar-expand-lg bg-body-tertiary " id="navbar">
            <div class="container-fluid">
              <a class="navbar-brand" href="#">
                <img id="logo" src={image1} alt="logo"></img>
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    {/* <a class="nav-link active" aria-current="page" href="#">
                Home
              </a> */}
                    <a
                      className="nav-link"
                      href="/"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Home
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      className="nav-link"
                      href="/Skill"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Why skills&nbsp;?
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/Corporates"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Corporates
                    </a>
                  </li>
                  <div className="courseonly">
                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="/technologies"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Courses
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="tag" src={image2}></img> &nbsp;
                              Software dev
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Database
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Game development
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Mobile development
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Programming languages
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Web Development
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="shield" src={image3}></img>&nbsp;
                              Security
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Certification
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Governance,risk,& compliance
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security architecture & engineering
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security operations
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security testing
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="brain" src={image4}></img>&nbsp;
                              Machine learning
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Big data
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Business intelligence
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Data visualization
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Databases
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Languages & libraries
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Machine learning
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="cloud" src={image5}></img> &nbsp;
                              Cloud
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Cloud architecture & design
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Cloud platforms
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Salesforce CRM
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="shield" src={image3}></img>&nbsp; IT
                              Ops
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Client operating systems
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Collaboration platforms
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Configuration management
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Containers
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              IT automation
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Network architecture
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Virtualization
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </div>
                  <div className="courseonly1">
                    <li class="nav-item dropdown dropdown-mega position-static">
                      <a
                        href="/technologies"
                        class="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="Outside"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Courses
                      </a>

                      <div class="dropdown-menu shadow container-fluid col-11 w-70 mx-5">
                        <div class="mega-content px-md-4">
                          <div class="conatiner-fluid">
                            <div class="row">
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-3">
                                  <img class="tag" src={image2}></img>
                                  <h5
                                    class="drop-title my-2 mx-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Software dev
                                  </h5>
                                </div>
                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Database
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Game development
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Mobile development
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Programming languages
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Web development
                                  </a>
                                </div>
                                <div class="logs mb-3">
                                  <img class="shield" src={image3}></img>
                                  <h5
                                    class="drop-title my-3 mt-4 mx-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Certification
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Governance,risk,& compliance
                                  </a>
                                  <a
                                    class="dropdown-item "
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security architecture & engineering
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security operations
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security testing
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-2">
                                  <img class="brain" src={image4}></img>
                                  <h5
                                    class="drop-title mt-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Data & machine learning
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Big data
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Business intelligence
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Data visualization
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Databases
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Languages & libraries
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Machine learning
                                  </a>
                                </div>
                                <div class="logs mb-3">
                                  <img class="cloud" src={image5}></img>
                                  <h5
                                    class="drop-title my-3 px-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud architecture & design
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud platforms
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Salesforce CRM
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-3">
                                  <img class="setting" src={image6}></img>
                                  <h5
                                    class="drop-title my-2 mx-1"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    IT Ops
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Client operating systems
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Collaboration platforms
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Configuration management
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Containers
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    IT automation
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Network architecture
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Virtualization
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      href="about"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Company
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/ContactUs"
                      style={{ fontFamily: "Prompt" }}
                    >
                      ContactUs&nbsp;
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/Register"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Free Register&nbsp;
                    </a>
                  </li>
                </ul>
                {/* <form class="d-flex" role="search">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button class="btn btn-outline-success" type="submit">
              Search
            </button>
          </form> */}
              </div>
            </div>
          </nav>
        </div>
        <div
          className="topcompany"
          style={{
            // backgroundColor: "rgb(4,27,61)",
            color: "white",
            textAlign: "center",
            fontSize: "38px",
            fontWeight: "900",

            fontFamily: "Prompt",
          }}
        >
          <br />

          <div className="col-sm-11 col-md-10 mx-auto my-5">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <h3 className="display-5 font-weight-bold">
                      Program Overview
                    </h3>
                  </div>
                  <div className="d-flex justify-content-between mr-3"></div>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-3">
                <p className="lead my-1 text-white">Duration</p>
                <p className=" my-1 font-weight-bold banner-highlights text-warning">
                  3 Months
                  <br />
                </p>
              </div>
              <div className="col-sm-6 col-md-3">
                <p className="lead my-1 text-white">Format</p>
                <p className=" my-1 font-weight-bold banner-highlights text-warning">
                  Online
                </p>
              </div>
              <div className="col-sm-6 col-md-3">
                <p className="lead my-1 text-white">Hiring Partners</p>
                <p className=" my-1 font-weight-bold banner-highlights text-warning">
                  100+ companies
                </p>
              </div>{" "}
              <div className="col-sm-6 col-md-3">
                <p className="lead my-1 text-white">Max CTC upto</p>
                <p className=" my-1 font-weight-bold banner-highlights text-warning">
                  21L/annum
                </p>
              </div>
            </div>
          </div>
          <div className="row m-0 bg-white">
            <div className="col-sm-11 col-md-10 mx-auto my-5">
              <div>
                <h2 className="display-5 font-weight-bold bannerTitleText">
                  About KambGeeks
                </h2>
              </div>
              <div>
                <p className="lead cm p-text">
                  Welcome to KambGeeks AI Bootcamp, the pinnacle of
                  project-based career programs in the AI realm! Our commitment
                  to excellence guarantees Placement Guidance upon completing
                  the intensive 4-week journey. KambGeeks AI Bootcamp is
                  engineered to deliver unparalleled mentoring and career
                  direction, drawing insights from luminaries at tech giants
                  like Google, Microsoft, and Flipkart. Our mission is to secure
                  placements in premier companies offering substantial salaries.
                  Dive into our AI-infused Development Program, meticulously
                  designed to immerse you in the latest AI technologies, all
                  presented in your preferred language (English). Join us for an
                  intensive 3-Month exploration into AI, and embark on the path
                  to becoming a sought-after in the AI landscape!
                </p>
              </div>
            </div>
            <div className="row m-0  ">
              <div className="col-sm-11 col-md-10 mx-auto py-5 text-white">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <h2 className="display-5 font-weight-bold bannerTitleText">
                      The Program Details
                    </h2>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div
                    style={{
                      padding: "3%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Row style={{ margin: "0px" }}>
                      <Col md="6" lg="6" sm="6" xs="12  text-dark">
                        <Fade right>
                          <div
                            class="faq-container"
                            style={{ padding: "1% 5% 2% 2%" }}
                          >
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-0: AI for everyone
                              </h3>

                              {this.state.qfa1 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      This will be a part of the preboot
                                      session, where we will talk about basic AI
                                      and how it is being used in industries.
                                      Moreover, we will shed some light on the
                                      applications of Deep Learning.
                                    </p>
                                    <li>What is AI?</li>
                                    <li>The terminology of AI</li>
                                    <li>
                                      The power of Machine Learning in the
                                      current era
                                    </li>
                                    <li>The limitations of Machine Learning</li>
                                    <li>
                                      A soft introduction to Deep Learning
                                    </li>
                                    <li>
                                      Some cool applications of Deep Learning
                                    </li>
                                  </ul>
                                </p>
                              )}
                              {this.state.ques1 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black", textAlign: "left" }}
                                  onClick={() =>
                                    this.setState({ ques1: false }, this.toggle)
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black", textAlign: "left" }}
                                  onClick={() =>
                                    this.setState({ ques1: true }, this.toggle)
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-1: Introduction to Artificial
                                Intelligence and current trends
                              </h3>

                              {this.state.qfa2 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  <div>
                                    <p>
                                      We will formally introduce AI and the
                                      current industry practices. We will
                                      discuss how to build and deploy
                                      state-of-the-art AI products.
                                    </p>
                                    <ul>
                                      <li>Introduction to AI</li>
                                      <li>Machine Learning basics</li>
                                      <li>
                                        Workflow of a Machine Learning projects
                                      </li>
                                      <li>
                                        Introduction to Deep Learning and
                                        difference between ML and DL
                                      </li>
                                      <li>Inducing AI using ML and DL</li>
                                      <li>How to choose an AI project?</li>
                                    </ul>
                                  </div>
                                </p>
                              )}
                              {this.state.ques2 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black", textAlign: "left" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques2: false },
                                      this.toggle1
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black", textAlign: "left" }}
                                  onClick={() =>
                                    this.setState({ ques2: true }, this.toggle1)
                                  }
                                />
                              )}
                            </div>
                            <br />
                            {/* <div class="faq active">
                      <h3 class="faq-title">
                        How much is the fee for this training?
                      </h3>
                      {this.state.qfa3 && (
                        <p class="faq-text">
                          The course fee is highly affordable and we provide
                          liberty to split the payments as during training &
                          after placement. Also, you can pay in installments
                        </p>
                      )}
                      {this.state.ques3 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: false }, this.toggle2)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: true }, this.toggle2)
                          }
                        />
                      )}
                    </div> */}
                            {/* <br /> */}
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-2: Introduction to Python
                              </h3>

                              {this.state.qfa4 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    fontWeight: "400",
                                    textAlign: "left",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      We will go through the basics of python
                                      with all essential beginner friendly
                                      concepts of python programming like
                                      datatypes, loops, data structures and
                                      functions, followed by assessments and
                                      assignments
                                    </p>
                                    <li>Python - Basic</li>
                                    <li>Why Python?</li>
                                    <li>Python IDE</li>
                                    <li>Hello World Program</li>
                                    <li>Variables & Names</li>
                                    <li>String Basics</li>
                                    <li>List</li>
                                    <li>Tuple</li>
                                    <li>Dictionaries</li>
                                    <li>Conditional Statements</li>
                                    <li>For and While Loop</li>
                                    <li>Functions</li>
                                    <li>Numbers and Math Functions</li>
                                    <li>Common Errors in Python</li>
                                  </ul>
                                </p>
                              )}
                              {this.state.ques4 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques4: false },
                                      this.toggle3
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState({ ques4: true }, this.toggle3)
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-3: Introduction to Python (Advanced)
                              </h3>

                              {this.state.qfa5 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      Since we have essential basics of python
                                      we will see some advanced concepts like
                                      comprehensions, file handling, regular
                                      expressions, object oriented programming,
                                      pickling and many more essential concepts.
                                    </p>
                                    <li>Python - Advanced</li>
                                    <li>Functions as Arguments</li>
                                    <li>List Comprehension</li>
                                    <li>File Handling</li>
                                    <li>Debugging in Python</li>
                                    <li>Class and Objects</li>
                                    <li>Lambda, Filters, and Map</li>
                                    <li>Python PIP</li>
                                    <li>Iterators</li>
                                    <li>Pickling</li>
                                    <li>Python JSON</li>
                                    <li>Python API and Web Scraping</li>
                                  </ul>
                                </p>
                              )}

                              {this.state.ques5 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques5: false },
                                      this.toggle4
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState({ ques5: true }, this.toggle4)
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-4: Introduction to Pandas for Data
                                Handling
                              </h3>

                              {this.state.qfa11 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      Since we need to handle huge amounts of
                                      data, we will be implementing data
                                      handling techniques with Pandas
                                      library.And we will explore the different
                                      miscellaneous functions of Pandas library
                                      in detail.
                                    </p>

                                    <li>Introduction to Pandas</li>
                                    <li>
                                      Series Data Structure - Querying and
                                      Indexing
                                    </li>
                                    <li>
                                      DataFrame Data Structure - Querying,
                                      Indexing, and loading
                                    </li>
                                    <li>Merging data frames</li>
                                    <li>Group by operation</li>
                                    <li>Pivot table</li>
                                    <li>Date/Time functionality</li>
                                    <li>Example: Manipulating DataFrame</li>
                                  </ul>
                                </p>
                              )}

                              {this.state.ques11 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques11: false },
                                      this.toggle11
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques11: true },
                                      this.toggle11
                                    )
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-5: Introduction to SQL
                              </h3>

                              {this.state.qfa12 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      We will dive into the SQL-based databases.
                                      We will understand the problems with
                                      file-based systems and how databases can
                                      overcome those challenges. We will learn
                                      the basics of SQL queries, schemas, and
                                      normalization.
                                    </p>

                                    <li>Data Modeling</li>
                                    <li>Normalization, and Star Schema</li>
                                    <li>ACID transactions</li>
                                    <li>
                                      Select, insert, update & delete (DML and
                                      DQL)
                                    </li>
                                    <li>Join operations</li>
                                    <li>
                                      Window functions (rank, dense rank, row
                                      number etc)
                                    </li>
                                    <li>Data Types, Variables and Constants</li>
                                    <li>
                                      Conditional Structures (IF, CASE, GOTO and
                                      NULL)
                                    </li>
                                    <li>Integrating Python with SQL</li>
                                  </ul>
                                </p>
                              )}

                              {this.state.ques12 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques12: false },
                                      this.toggle12
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques12: true },
                                      this.toggle12
                                    )
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-6: Exploratory Data Analysis with Python
                              </h3>

                              {this.state.qfa13 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      It is always needed to analyze the data
                                      and preprocess it , since the real world
                                      data is not always industry ready, so in
                                      this week we will be dealing with a lot of
                                      data cleaning and Exploratory data
                                      Analysis techniques which is a very
                                      crucial stage for any data science project
                                    </p>

                                    <li>Structured vs Unstructured Data</li>
                                    <li>
                                      Common Data issues and how to clean them
                                    </li>
                                    <li>Textual data cleaning</li>
                                    <li>
                                      Meaningful data transformation (Scaling
                                      and Normalisation)
                                    </li>
                                    <li>Handling missing data</li>
                                    <li>Outlier detection and correction</li>
                                    <li>Example: EDA on Movies DataSet</li>
                                  </ul>
                                </p>
                              )}

                              {this.state.ques13 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques13: false },
                                      this.toggle13
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques13: true },
                                      this.toggle13
                                    )
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-7: Data Visualisation in Python
                                (Matplotlib, Seaborn)
                              </h3>

                              {this.state.qfa14 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      Data Visualization is used to understand
                                      data in visual context so that the
                                      patterns , trends and correlations in the
                                      data can be understood. We will do a lot
                                      of visualization with libraries like
                                      Seaborn, Matplotlib etc inturn that leads
                                      to effective story telling.
                                    </p>

                                    <li>Read Complex JSON files</li>
                                    <li>Styling Tabulation</li>
                                    <li>Distribution of Data - Histogram</li>
                                    <li>Box Plot</li>
                                    <li>Pie Chart</li>
                                    <li>Donut Chart</li>
                                    <li>Stacked Bar Plot</li>
                                    <li>Relative Stacked Bar Plot</li>
                                    <li>Stacked Area Plot</li>
                                    <li>Scatter Plots</li>
                                    <li>Bar Plot</li>
                                    <li>Continuous vs Continuous Plot</li>
                                    <li>Line Plot</li>
                                    <li>Line Plot Covid Data</li>
                                  </ul>
                                </p>
                              )}

                              {this.state.ques14 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques14: false },
                                      this.toggle14
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques14: true },
                                      this.toggle14
                                    )
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-8: Machine Learning Refresher
                              </h3>

                              {this.state.qfa15 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      We will cover the basics of Machine
                                      Learning and connect the use cases in the
                                      domain of Machine Learning with the
                                      Artificial Intelligence.
                                    </p>

                                    <li>
                                      What is ML and how it is related to AI?
                                    </li>
                                    <li>Predictive Modelling</li>
                                    <li>Correlation</li>
                                    <li>Basics of regression</li>
                                    <li>Ordinary least squares</li>
                                    <li>Simple linear regression</li>
                                    <li>Model building</li>
                                    <li>Model assessment and improvement</li>
                                    <li>Diagnostics</li>
                                    <li>
                                      Multiple linear regression (model building
                                      and assessment)
                                    </li>
                                    <li>Random forest & decision tree</li>
                                  </ul>
                                </p>
                              )}

                              {this.state.ques15 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques15: false },
                                      this.toggle15
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques15: true },
                                      this.toggle15
                                    )
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-9: Machine Learning Continued
                              </h3>

                              {this.state.qfa16 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      We will cover more advanced concepts in
                                      ML.
                                    </p>

                                    <li>Classification</li>
                                    <li>Logistic regression</li>
                                    <li>K nearest neighbours</li>
                                    <li>Clustering</li>
                                    <li>K means</li>
                                    <li>Dimensionality reduction methods</li>
                                    <li>
                                      Principal component analysis and its
                                      variants
                                    </li>
                                    <li>Linear Discriminant Analysis</li>
                                    <li>Support vector machine</li>
                                  </ul>
                                </p>
                              )}

                              {this.state.ques16 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques16: false },
                                      this.toggle16
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques16: true },
                                      this.toggle16
                                    )
                                  }
                                />
                              )}
                            </div>
                            <br />
                          </div>
                        </Fade>
                      </Col>
                      <Col md="6" lg="6" sm="6" xs="12  text-dark">
                        <Fade right>
                          <div
                            class="faq-container"
                            style={{ padding: "1% 5% 2% 2%" }}
                          >
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-10: Introduction to Neural Networks
                              </h3>

                              {this.state.qfa17 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      Given the fundamental understanding of
                                      basic regression algorithms, we will now
                                      deep dive into the Neural Networks. We
                                      will learn the basic unit of neural
                                      networks and will slowly learn to create a
                                      network.
                                    </p>

                                    <li>A single neuron details</li>
                                    <li>
                                      The XOR problem and introduction to
                                      multi-layer perceptron
                                    </li>
                                    <li>
                                      Understanding the output & Activation
                                      Functions
                                    </li>
                                    <li>Derivatives of Activation Functions</li>
                                    <li>
                                      Gradient Descent for Neural Networks
                                    </li>
                                    <li>Backpropagation Algorithm</li>
                                    <li>Understanding Computational graph</li>
                                    <li>
                                      Backpropagation using computational graph
                                    </li>
                                    <li>Random initialization</li>
                                  </ul>
                                </p>
                              )}

                              {this.state.ques17 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques17: false },
                                      this.toggle17
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques17: true },
                                      this.toggle17
                                    )
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-11: Deep Neural Networks
                              </h3>

                              {this.state.qfa6 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    fontWeight: "400",
                                    textAlign: "left",
                                  }}
                                >
                                  <div>
                                    <p>
                                      After having the basic understanding of
                                      neural networks, we will look into deep
                                      neural networks and try to understand how
                                      to learn complex functions.
                                    </p>
                                    <ul>
                                      <li>Deep L-layer Neural Network</li>
                                      <li>
                                        Forward Propagation in a Deep Network
                                      </li>
                                      <li>
                                        Building Blocks of Deep Neural Networks
                                      </li>
                                      <li>Forward and Backward Propagation</li>
                                      <li>Parameters vs Hyperparameters</li>
                                      <li>
                                        Parameters learning and hyperparameters
                                        tuning
                                      </li>
                                    </ul>
                                  </div>
                                </p>
                              )}
                              {this.state.ques6 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black", textAlign: "left" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques6: false },
                                      this.toggle5
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black", textAlign: "left" }}
                                  onClick={() =>
                                    this.setState({ ques6: true }, this.toggle5)
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-12: Applied Deep Learning with Pytorch
                              </h3>

                              {this.state.qfa7 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    fontWeight: "400",
                                    textAlign: "left",
                                  }}
                                >
                                  <div>
                                    <p>
                                      We will dive into the practical aspects of
                                      deep learning using PyTorch. We will learn
                                      the basic terminologies and their
                                      significance. Moreover, we will learn how
                                      to implement neural networks in PyTorch.
                                    </p>
                                    <ul>
                                      <li>
                                        Understanding the learning aspect of
                                        neural networks
                                      </li>
                                      <li>PyTorch basics</li>
                                      <li>Tensor and Datasets in PyTorch</li>
                                      <li>Linear Regression in PyTorch</li>
                                      <li>
                                        Multiple Input Output Linear Regression
                                      </li>
                                      <li>Softmax Regression</li>
                                      <li>Shallow Neural Networks</li>
                                      <li>
                                        Splitting the data (train/test/dev)
                                      </li>
                                      <li>Understanding Bias and Variance</li>
                                      <li>Understanding overfitting</li>
                                      <li>Using regularization</li>
                                      <li>
                                        Regularization techniques (like dropout)
                                      </li>
                                    </ul>
                                  </div>
                                </p>
                              )}
                              {this.state.ques7 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black", textAlign: "left" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques7: false },
                                      this.toggle6
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black", textAlign: "left" }}
                                  onClick={() =>
                                    this.setState({ ques7: true }, this.toggle6)
                                  }
                                />
                              )}
                            </div>
                            <br />
                            {/* <div class="faq active">
                      <h3 class="faq-title">
                        How much is the fee for this training?
                      </h3>
                      {this.state.qfa3 && (
                        <p class="faq-text">
                          The course fee is highly affordable and we provide
                          liberty to split the payments as during training &
                          after placement. Also, you can pay in installments
                        </p>
                      )}
                      {this.state.ques3 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: false }, this.toggle2)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: true }, this.toggle2)
                          }
                        />
                      )}
                    </div> */}
                            {/* <br /> */}
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-13: Applied Deep Learning with Pytorch
                                Continued
                              </h3>

                              {this.state.qfa8 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      We will learn normalization and other
                                      related concepts. Moreover, we will look
                                      into the problems like vanishing gradient
                                    </p>
                                    <li>Implementing Deep Networks</li>
                                    <li>
                                      Convolutional Neural Network (Convolution,
                                      Activation Functions and Max Polling,
                                      Multiple Input and Output Channels, GPU in
                                      PyTorch)
                                    </li>
                                    <li>Normalizing Inputs</li>
                                    <li>Vanishing / Exploding Gradients</li>
                                    <li>
                                      Weight Initialization for Deep Networks
                                    </li>
                                    <li>
                                      Numerical Approximation of Gradients
                                    </li>
                                    <li>Gradient Checking</li>
                                    <li>Gradient Checking Implementation</li>
                                  </ul>
                                </p>
                              )}
                              {this.state.ques8 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques8: false },
                                      this.toggle7
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState({ ques8: true }, this.toggle7)
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-14: Introduction to Computer Vision with
                                Convolution Neural Networks
                              </h3>

                              {this.state.qfa9 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    fontWeight: "400",
                                    textAlign: "left",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      We will introduce computer vision and will
                                      try to understand how deep learning can
                                      help us perform various tasks.
                                    </p>
                                    <li>
                                      What is CV? (understanding with examples)
                                    </li>
                                    <li>Edge detection with examples</li>
                                    <li>Padding</li>
                                    <li>Strided Convolutions</li>
                                    <li>Convolutions Over Volume</li>
                                    <li>
                                      One Layer of a Convolutional Network
                                    </li>
                                    <li>
                                      Simple Convolutional Network Example
                                    </li>
                                    <li>Pooling Layers</li>
                                    <li>CNN Example</li>
                                  </ul>
                                </p>
                              )}

                              {this.state.ques9 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques9: false },
                                      this.toggle8
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState({ ques9: true }, this.toggle8)
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-15: Natural Language Processing with
                                Neural Networks
                              </h3>

                              {this.state.qfa20 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    fontWeight: "400",
                                    textAlign: "left",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      Given the idea about Computer Vision with
                                      Deep Neural Networks, now we will
                                      understand another use case, which is NLP
                                      with deep learning.
                                    </p>
                                    <li>
                                      Deep learning architectures for sequence
                                      processing
                                    </li>
                                    <li>Recurrent neural networks</li>
                                    <li>
                                      Managing context in RNNs and its drawbacks
                                    </li>
                                    <li>Introduction to LSTMs and GRUs</li>
                                  </ul>
                                </p>
                              )}

                              {this.state.ques20 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques20: false },
                                      this.toggle20
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques20: true },
                                      this.toggle20
                                    )
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-16: Natural Language Processing with
                                Neural Networks continued
                              </h3>

                              {this.state.qfa21 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    fontWeight: "400",
                                    textAlign: "left",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      After having the basic understanding of
                                      deep learning architecture for language
                                      models, we will now look into more complex
                                      architectures.
                                    </p>
                                    <li>
                                      Self Attention Networks: Transformers
                                    </li>
                                    <li>
                                      Introduction to Encoder-Decoder models
                                    </li>
                                    <li>Encoder-Decoder with RNNs</li>
                                    <li>Attention and Beam search</li>
                                    <li>
                                      Encoder and Decoder with Transformers
                                    </li>
                                    <li>
                                      Transfer Learning through Fine-Tuning
                                    </li>
                                  </ul>
                                </p>
                              )}

                              {this.state.ques21 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques21: false },
                                      this.toggle21
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques21: true },
                                      this.toggle21
                                    )
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-17: Introduction to LLMs and prompt
                                Engineering
                              </h3>

                              {this.state.qfa22 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    fontWeight: "400",
                                    textAlign: "left",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      After having the basic understanding of
                                      deep learning architecture for language
                                      models, we will now look into more complex
                                      architectures.
                                    </p>
                                    <li>
                                      Introduction to Large Language Models
                                    </li>
                                    <li>
                                      Description of GPT-3 and chatGPT
                                      architecture
                                    </li>
                                    <li>
                                      Application of LLMs in various fields
                                      (Life sciences, Legal Languages, etc.)
                                    </li>
                                    <li>Basic description of other LLMs</li>
                                  </ul>
                                </p>
                              )}

                              {this.state.ques22 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques22: false },
                                      this.toggle22
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques22: true },
                                      this.toggle22
                                    )
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Module-18: Prompt Engineering using OpenAI
                              </h3>

                              {this.state.qfa23 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  <ul>
                                    <p>
                                      We will now dive deeper into the prompt
                                      engineering and discuss the effective ways
                                      of using OpenAI API.
                                    </p>
                                    <li>Introduction to GPT 3.5 & 4 APIs</li>
                                    <li>
                                      Introduction & importance of Prompt
                                      Engineering
                                    </li>
                                    <li>Prompting Guidelines</li>
                                    <li>
                                      Outcomes of Prompt Engineering - Iterative
                                      learning, Summarizing, Inferring &
                                      Expanding
                                    </li>
                                    <li>Interactive ChatBot</li>
                                    <li>
                                      Application to summarize & identify the
                                      sentiment of customer feedback given to an
                                      e-commerce website
                                    </li>
                                  </ul>
                                </p>
                              )}

                              {this.state.ques23 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques23: false },
                                      this.toggle23
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques23: true },
                                      this.toggle23
                                    )
                                  }
                                />
                              )}
                            </div>
                            <br />
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                Final Projects
                              </h3>

                              {this.state.qfa24 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    fontWeight: "400",
                                    textAlign: "left",
                                  }}
                                >
                                  <ul>
                                    <li>Image segmentation using DNNs</li>
                                    <li>Gesture recognition using DNNs</li>
                                    <li>
                                      Building NER for pharmaceutical dataset
                                    </li>
                                    <li>
                                      Building and deploying Question Answering
                                      system with Hugging Face
                                    </li>
                                    <li>
                                      Face detection using Neural Style Transfer
                                    </li>
                                  </ul>
                                </p>
                              )}

                              {this.state.ques24 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques23: false },
                                      this.toggle24
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques24: true },
                                      this.toggle24
                                    )
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </Fade>
                      </Col>

                      <Col />
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </div>
    );
  }
}
