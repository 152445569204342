import React, { Component } from "react";
import Image24 from "../images/faq1.png";
import "../styles/newhp.css";
import { Row, Col, Card, Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { useState } from "react";
import Footer from "./footer";
import image1 from "../images/kam1.jpeg";
import image2 from "../images/devops.png";
import image3 from "../images/shield.png";
import image4 from "../images/human-brain.png";
import { NavLink } from "react-router-dom";
import image5 from "../images/cloud.png";
import image6 from "../images/settings.png";
import { Link } from "react-router-dom";
import "../styles/navbar.css";

export default class Iot1hour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: 0,
      qfa1: false,
      qfa2: false,
      qfa3: false,
      qfa4: false,
      qfa5: false,
      qfa25: false,

      ques25: false,
      ques5: false,
      ques4: false,
      ques3: false,
      code: "91",
      ques2: false,
      ques1: false,
      password: "",
    };
  }
  toggle = () => {
    this.setState({ qfa1: !this.state.qfa1 });
  };
  toggle1 = () => {
    this.setState({ qfa2: !this.state.qfa2 });
  };
  toggle2 = () => {
    this.setState({ qfa3: !this.state.qfa3 });
  };
  toggle3 = () => {
    this.setState({ qfa4: !this.state.qfa4 });
  };
  toggle4 = () => {
    this.setState({ qfa5: !this.state.qfa5 });
  };

  toggle25 = () => {
    this.setState({ qfa25: !this.state.qfa25 });
  };
  render() {
    return (
      <div
        style={{
          overflow: "hidden",
          backgroundImage:
            "linear-gradient(to right, #ff7686 5%, #911bed 20%, #2e38bf 30%, #090920 45%)",
          backgroundSize: "100% 100%",
          backgroundPosition: "left top",
        }}
      >
        <div>
          <nav class="navbar navbar-expand-lg bg-body-tertiary " id="navbar">
            <div class="container-fluid">
              <a class="navbar-brand" href="#">
                <img id="logo" src={image1} alt="logo"></img>
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    {/* <a class="nav-link active" aria-current="page" href="#">
                Home
              </a> */}
                    <a
                      className="nav-link"
                      href="/"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Home
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      className="nav-link"
                      href="/Skill"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Why skills&nbsp;?
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/Corporates"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Corporates
                    </a>
                  </li>
                  <div className="courseonly">
                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="/technologies"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Courses
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="tag" src={image2}></img> &nbsp;
                              Software dev
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Database
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Game development
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Mobile development
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Programming languages
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Web Development
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="shield" src={image3}></img>&nbsp;
                              Security
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Certification
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Governance,risk,& compliance
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security architecture & engineering
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security operations
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security testing
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="brain" src={image4}></img>&nbsp;
                              Machine learning
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Big data
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Business intelligence
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Data visualization
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Databases
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Languages & libraries
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Machine learning
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="cloud" src={image5}></img> &nbsp;
                              Cloud
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Cloud architecture & design
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Cloud platforms
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Salesforce CRM
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="shield" src={image3}></img>&nbsp; IT
                              Ops
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Client operating systems
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Collaboration platforms
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Configuration management
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Containers
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              IT automation
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Network architecture
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Virtualization
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </div>
                  <div className="courseonly1">
                    <li class="nav-item dropdown dropdown-mega position-static">
                      <a
                        href="/technologies"
                        class="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="Outside"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Courses
                      </a>

                      <div class="dropdown-menu shadow container-fluid col-11 w-70 mx-5">
                        <div class="mega-content px-md-4">
                          <div class="conatiner-fluid">
                            <div class="row">
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-3">
                                  <img class="tag" src={image2}></img>
                                  <h5
                                    class="drop-title my-2 mx-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Software dev
                                  </h5>
                                </div>
                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Database
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Game development
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Mobile development
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Programming languages
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Web development
                                  </a>
                                </div>
                                <div class="logs mb-3">
                                  <img class="shield" src={image3}></img>
                                  <h5
                                    class="drop-title my-3 mt-4 mx-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Certification
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Governance,risk,& compliance
                                  </a>
                                  <a
                                    class="dropdown-item "
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security architecture & engineering
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security operations
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security testing
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-2">
                                  <img class="brain" src={image4}></img>
                                  <h5
                                    class="drop-title mt-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Data & machine learning
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Big data
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Business intelligence
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Data visualization
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Databases
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Languages & libraries
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Machine learning
                                  </a>
                                </div>
                                <div class="logs mb-3">
                                  <img class="cloud" src={image5}></img>
                                  <h5
                                    class="drop-title my-3 px-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud architecture & design
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud platforms
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Salesforce CRM
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-3">
                                  <img class="setting" src={image6}></img>
                                  <h5
                                    class="drop-title my-2 mx-1"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    IT Ops
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Client operating systems
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Collaboration platforms
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Configuration management
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Containers
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    IT automation
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Network architecture
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Virtualization
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      href="about"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Company
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/ContactUs"
                      style={{ fontFamily: "Prompt" }}
                    >
                      ContactUs&nbsp;
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/Register"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Free Register&nbsp;
                    </a>
                  </li>
                </ul>
                {/* <form class="d-flex" role="search">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button class="btn btn-outline-success" type="submit">
              Search
            </button>
          </form> */}
              </div>
            </div>
          </nav>
        </div>
        <div
          className="topcompany"
          style={{
            // backgroundColor: "rgb(4,27,61)",
            color: "white",
            textAlign: "center",
            fontSize: "38px",
            fontWeight: "900",

            fontFamily: "Prompt",
          }}
        >
          <br />

          <div className="col-sm-11 col-md-10 mx-auto my-5">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <h3 className="display-5 font-weight-bold">
                      Program Overview
                    </h3>
                  </div>
                  <div className="d-flex justify-content-between mr-3"></div>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-3">
                <p className="lead my-1 text-white">Duration</p>
                <p className=" my-1 font-weight-bold banner-highlights text-warning">
                  1 Hour
                  <br />
                </p>
              </div>
              <div className="col-sm-6 col-md-3">
                <p className="lead my-1 text-white">Format</p>
                <p className=" my-1 font-weight-bold banner-highlights text-warning">
                  Online
                </p>
              </div>
              <div className="col-sm-6 col-md-3">
                <p className="lead my-1 text-white">Hiring Partners</p>
                <p className=" my-1 font-weight-bold banner-highlights text-warning">
                  100+ companies
                </p>
              </div>{" "}
              <div className="col-sm-6 col-md-3">
                <p className="lead my-1 text-white">Max CTC upto</p>
                <p className=" my-1 font-weight-bold banner-highlights text-warning">
                  21L/annum
                </p>
              </div>
            </div>
          </div>
          <div className="row m-0 bg-white">
            <div className="col-sm-11 col-md-10 mx-auto my-5">
              <div>
                <h2 className="display-5 font-weight-bold bannerTitleText">
                  About KambGeeks
                </h2>
              </div>
              <div>
                <p className="lead cm p-text">
                  Step into the forefront of innovation with KambGeeks IoT
                  Mastery, a transformative three-month program meticulously
                  tailored to propel your career in the Internet of Things
                  (IoT)! Our unwavering commitment ensures Placement Guidance
                  upon completion. At KambGeeks, we bring you unmatched
                  mentoring and career guidance from experts representing
                  industry giants like Google, Microsoft, and Flipkart. Our
                  ultimate aim is to secure placements in top-tier companies
                  with enticing salaries. Immerse yourself in our comprehensive
                  IoT Program, strategically designed to cover the latest
                  technologies in your preferred language (English), empowering
                  you to excel as an IoT professional. Join us for 1 Hour of
                  immersive exploration into the dynamic world of IoT, and
                  unlock your potential in this cutting-edge field!
                </p>
              </div>
            </div>
            <div className="row m-0  ">
              <div className="col-sm-11 col-md-10 mx-auto py-5 text-white">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <h2 className="display-5 font-weight-bold bannerTitleText">
                      The Program Details
                    </h2>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div
                    style={{
                      padding: "3%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Row style={{ margin: "0px" }}>
                      <Col md="12" lg="12" sm="12" xs="12  text-dark">
                        <Fade right>
                          <div
                            class="faq-container"
                            style={{ padding: "1% 5% 2% 2%" }}
                          >
                            <div class="faq active">
                              <h3
                                class="faq-title"
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                IoT Program
                              </h3>
                              <br />
                              <h4
                                style={{
                                  fontFamily: "Prompt",
                                  color: "black",
                                  textAlign: "left",
                                  fontSize: "20",
                                  lineHeight: "2em",
                                  fontWeight: "400",
                                }}
                              >
                                The outcomes of a one-hour IoT course would
                                naturally be limited due to the short duration.
                                While a one-hour session can provide a brief
                                introduction to IoT concepts, it may not allow
                                sufficient time to develop practical skills or
                                gain a comprehensive understanding of the
                                subject. Participants should view it as an
                                introductory overview and consider further
                                education or training for a deeper exploration
                                of IoT concepts and applications.
                              </h4>

                              {this.state.qfa25 && (
                                <p
                                  class="faq-text"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "400",
                                  }}
                                >
                                  <ul>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Introduction to IoT Concepts:
                                      </strong>{" "}
                                      Participants will gain a basic
                                      understanding of Internet of Things (IoT)
                                      concepts, including its definition, scope,
                                      and significance in modern technology.
                                    </li>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Overview of IoT Technologies:
                                      </strong>{" "}
                                      They will learn about the technologies
                                      commonly used in IoT development, such as
                                      sensors, actuators, microcontrollers,
                                      communication protocols, and IoT
                                      platforms.
                                    </li>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Discussion of IoT Architecture:
                                      </strong>{" "}
                                      Participants will be introduced to the
                                      architecture of IoT systems, including
                                      edge devices, IoT gateways, cloud
                                      services, and data analytics.
                                    </li>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Practical Examples and Demonstrations:
                                      </strong>{" "}
                                      Depending on the format of the program,
                                      participants may have the opportunity to
                                      see demonstrations or examples of IoT
                                      applications, helping them understand how
                                      IoT technologies are used to create smart
                                      solutions.
                                    </li>
                                    <li>
                                      <strong style={{ color: "#333" }}>
                                        Q&A and Discussion:
                                      </strong>{" "}
                                      Participants will have the chance to ask
                                      questions and engage in discussions with
                                      the instructor or facilitator, allowing
                                      them to clarify concepts, explore specific
                                      areas of interest, and deepen their
                                      understanding of IoT development.
                                    </li>
                                  </ul>
                                </p>
                              )}
                              {this.state.ques25 ? (
                                <IoIosArrowDropdownCircle
                                  class="faq-toggle"
                                  style={{ color: "black", textAlign: "left" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques25: false },
                                      this.toggle25
                                    )
                                  }
                                />
                              ) : (
                                <IoIosArrowDroprightCircle
                                  class="faq-toggle"
                                  style={{ color: "black", textAlign: "left" }}
                                  onClick={() =>
                                    this.setState(
                                      { ques25: true },
                                      this.toggle25
                                    )
                                  }
                                />
                              )}
                            </div>
                            <div className="text-center mt-4 mb-3">
                              <a
                                className="btn get-started-free-btn"
                                href="/Register"
                              >
                                Register For Workshop
                              </a>
                            </div>
                            {/* <div class="faq active">
                      <h3 class="faq-title">
                        How much is the fee for this training?
                      </h3>
                      {this.state.qfa3 && (
                        <p class="faq-text">
                          The course fee is highly affordable and we provide
                          liberty to split the payments as during training &
                          after placement. Also, you can pay in installments
                        </p>
                      )}
                      {this.state.ques3 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: false }, this.toggle2)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: true }, this.toggle2)
                          }
                        />
                      )}
                    </div> */}
                            {/* <br /> */}
                          </div>
                        </Fade>
                      </Col>

                      <Col />
                    </Row>
                  </div>
                  <div className="col-sm-11 col-md-10 mx-auto my-5">
                    <div>
                      <h2
                        className="display-5 font-weight-bold bannerTitleText"
                        style={{ textAlign: "center" }}
                      >
                        For Detailed IoT Program Join our One to One Mentorship
                      </h2>
                    </div>
                    <div>
                      <Row style={{ margin: "0px" }}>
                        <Col md="6" lg="6" sm="6" xs="12  text-dark">
                          <Fade right>
                            <div
                              class="faq-container"
                              style={{ padding: "1% 5% 2% 2%" }}
                            >
                              <div class="faq active">
                                <h3
                                  class="faq-title"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "600",
                                  }}
                                >
                                  Module-0: Introduction to IoT
                                </h3>
                                {this.state.qfa1 && (
                                  <p
                                    class="faq-text"
                                    style={{
                                      fontFamily: "Prompt",
                                      color: "black",
                                      textAlign: "left",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <ul>
                                      <li>Overview of IoT Concepts</li>
                                      <li>IoT Architecture and Components</li>
                                      <li>
                                        Introduction to Sensors and Actuators
                                      </li>
                                      <li>Communication Protocols for IoT</li>
                                    </ul>
                                  </p>
                                )}
                                {this.state.ques1 ? (
                                  <IoIosArrowDropdownCircle
                                    class="faq-toggle"
                                    style={{
                                      color: "black",
                                      textAlign: "left",
                                    }}
                                    onClick={() =>
                                      this.setState(
                                        { ques1: false },
                                        this.toggle
                                      )
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDroprightCircle
                                    class="faq-toggle"
                                    style={{
                                      color: "black",
                                      textAlign: "left",
                                    }}
                                    onClick={() =>
                                      this.setState(
                                        { ques1: true },
                                        this.toggle
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <br />
                              <div class="faq active">
                                <h3
                                  class="faq-title"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "600",
                                  }}
                                >
                                  Module-1: IoT Device Development
                                </h3>
                                {this.state.qfa2 && (
                                  <p
                                    class="faq-text"
                                    style={{
                                      fontFamily: "Prompt",
                                      color: "black",
                                      textAlign: "left",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <ul>
                                      <li>
                                        Programming Microcontrollers (e.g.,
                                        Arduino, Raspberry Pi)
                                      </li>
                                      <li>
                                        Working with Sensors and Actuators
                                      </li>
                                      <li>IoT Data Processing and Analysis</li>
                                      <li>IoT Security Considerations</li>
                                    </ul>
                                  </p>
                                )}
                                {this.state.ques2 ? (
                                  <IoIosArrowDropdownCircle
                                    class="faq-toggle"
                                    style={{
                                      color: "black",
                                      textAlign: "left",
                                    }}
                                    onClick={() =>
                                      this.setState(
                                        { ques2: false },
                                        this.toggle1
                                      )
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDroprightCircle
                                    class="faq-toggle"
                                    style={{
                                      color: "black",
                                      textAlign: "left",
                                    }}
                                    onClick={() =>
                                      this.setState(
                                        { ques2: true },
                                        this.toggle1
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <br />
                            </div>
                          </Fade>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12  text-dark">
                          <Fade right>
                            <div
                              class="faq-container"
                              style={{ padding: "1% 5% 2% 2%" }}
                            >
                              {/* <div class="faq active">
                      <h3 class="faq-title">
                        How much is the fee for this training?
                      </h3>
                      {this.state.qfa3 && (
                        <p class="faq-text">
                          The course fee is highly affordable and we provide
                          liberty to split the payments as during training &
                          after placement. Also, you can pay in installments
                        </p>
                      )}
                      {this.state.ques3 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: false }, this.toggle2)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: true }, this.toggle2)
                          }
                        />
                      )}
                    </div> */}
                              {/* <br /> */}
                              <div class="faq active">
                                <h3
                                  class="faq-title"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "600",
                                  }}
                                >
                                  Module-2: IoT Communication and Networking
                                </h3>
                                {this.state.qfa3 && (
                                  <p
                                    class="faq-text"
                                    style={{
                                      fontFamily: "Prompt",
                                      color: "black",
                                      textAlign: "left",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <ul>
                                      <li>Wireless Communication Protocols</li>
                                      <li>IoT Networking Technologies</li>
                                      <li>
                                        MQTT, CoAP, and other IoT Protocols
                                      </li>
                                      <li>
                                        Cloud Integration for IoT Solutions
                                      </li>
                                    </ul>
                                  </p>
                                )}
                                {this.state.ques3 ? (
                                  <IoIosArrowDropdownCircle
                                    class="faq-toggle"
                                    style={{
                                      color: "black",
                                      textAlign: "left",
                                    }}
                                    onClick={() =>
                                      this.setState(
                                        { ques3: false },
                                        this.toggle2
                                      )
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDroprightCircle
                                    class="faq-toggle"
                                    style={{
                                      color: "black",
                                      textAlign: "left",
                                    }}
                                    onClick={() =>
                                      this.setState(
                                        { ques3: true },
                                        this.toggle2
                                      )
                                    }
                                  />
                                )}
                              </div>
                              <br />
                              <div class="faq active">
                                <h3
                                  class="faq-title"
                                  style={{
                                    fontFamily: "Prompt",
                                    color: "black",
                                    textAlign: "left",
                                    fontWeight: "600",
                                  }}
                                >
                                  Module-3: IoT Data Analytics and Visualization
                                </h3>
                                {this.state.qfa4 && (
                                  <p
                                    class="faq-text"
                                    style={{
                                      fontFamily: "Prompt",
                                      color: "black",
                                      textAlign: "left",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <ul>
                                      <li>
                                        Introduction to IoT Data Analytics
                                      </li>
                                      <li>
                                        Data Collection and Storage for IoT
                                      </li>
                                      <li>Data Preprocessing and Cleaning</li>
                                      <li>Exploratory Data Analysis (EDA)</li>
                                      <li>Statistical Analysis for IoT Data</li>
                                      <li>IoT Data Visualization Techniques</li>
                                      <li>
                                        Using Tools like Tableau, Power BI, etc.
                                      </li>
                                    </ul>
                                  </p>
                                )}
                                {this.state.ques4 ? (
                                  <IoIosArrowDropdownCircle
                                    class="faq-toggle"
                                    style={{
                                      color: "black",
                                      textAlign: "left",
                                    }}
                                    onClick={() =>
                                      this.setState(
                                        { ques4: false },
                                        this.toggle3
                                      )
                                    }
                                  />
                                ) : (
                                  <IoIosArrowDroprightCircle
                                    class="faq-toggle"
                                    style={{
                                      color: "black",
                                      textAlign: "left",
                                    }}
                                    onClick={() =>
                                      this.setState(
                                        { ques4: true },
                                        this.toggle3
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </Fade>
                        </Col>

                        <Col />
                      </Row>
                    </div>
                    <div className="text-center mt-4 mb-3">
                      <a className="btn get-started-free-btn" href="/Iot3month">
                        Join our IoT Program
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </div>
    );
  }
}
