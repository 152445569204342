import React, { Component } from "react";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/Register.css";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import question from "../images/Questions.png";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { ip } from "../Api";
import axios from "axios";
import Footer from "./footer";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import product1 from "./../images/productengineeringtools.png";
import { Row, Col, Card, Container } from "react-bootstrap";
import tech from "../images/tech.jpg";
import develop from "../images/develop.PNG";
import Navbar from "./Navbar.js";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const options = [
  { value: "Student", label: "Student" },
  { value: "WorkingProfessional", label: "Working Professional" },
  { value: "NonTech", label: "Non Technical" },
];
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ispassword: null,
      qfa1: false,
      qfa2: false,
      qfa3: false,
      qfa4: false,
      qfa5: false,
      ques5: false,
      categorylist: [],
      categoryerr: "",
      ques4: false,
      name: "",
      mobileNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      cnferr: "",
      passwordMatch: true,
      firstname: "",
      lastname: "",
      mobileno: "",
      email: "",
      collegename: "",
      category: "",
      firstnameerr: "",
      lastnameerr: "",
      emailerr: "",
      mobilenoerr: "",
      ques3: false,
      code: "91",
      ques2: false,
      ques1: false,
      password: "",
      codeErr: "",
      confirmPassword: "",
      cnferr: "",
      passwordMatch: true,
      firstname: "",
      lastname: "",
      mobileno: "",
      email: "",
      collegename: "",
      firstnameerr: "",
      lastnameerr: "",
      emailerr: "",
      mobilenoerr: "",
    };
  }

  validate = () => {
    let cnferr = "";
    let codeErr = "";
    let firstnameerr = "";
    let lastnameerr = "";
    let emailerr = "";
    let categoryerr = "";
    let mobilenoerr = "";
    let roleErr = "";
    let hasErr = true;
    // if (this.state.code === "") {
    //   codeErr = "This field is required";
    //   this.setState({ codeErr });
    //   console.log("code Err", this.state.codeErr, hasErr);
    //   hasErr = false;
    // }

    if (this.state.mobileno === "") {
      mobilenoerr = "This field is required";
      this.setState({ mobilenoerr });
      console.log("phone Err", this.state.mobilenoerr, hasErr);
      hasErr = false;
    }
    if (this.state.firstname === "") {
      firstnameerr = "This field is required";
      this.setState({ firstnameerr });
      console.log("firstname Err", this.state.firstnameerr, hasErr);
      hasErr = false;
    }
    if (this.state.category === "") {
      categoryerr = "This field is required";
      this.setState({ categoryerr });
      console.log("category Err", this.state.categoryerr, hasErr);
      hasErr = false;
    }
    if (this.state.lastname === "") {
      lastnameerr = "This field is required";
      this.setState({ lastnameerr });
      console.log("lastname Err", this.state.lastnameerr, hasErr);
      hasErr = false;
    }
    if (this.state.email === "") {
      emailerr = "This field is required";
      this.setState({ emailerr });
      console.log("email Err", this.state.emailerr, hasErr);
      hasErr = false;
    }
    return hasErr;
  };

  reset = () => {
    this.setState({
      firstnameerr: "",
      firstname: "",
      lastname: "",
      category: "",
      categoryerr: "",
      lastnameerr: "",
      mobileno: "",
      mobilenoerr: "",
      email: "",
      emailerr: "",
      collegename: "",
    });
  };
  componentDidMount() {
    // Show popup after 2 minutes
    setTimeout(() => {
      this.setState({ showPopup: true });
    }, 10000); // 2 minutes in milliseconds

    const options = [
      { value: "Student", label: "Student" },
      { value: "WorkingProfessional", label: "Working Professional" },
      { value: "NonTech", label: "Non Technical" },
    ];
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClose = () => this.setState({ isOpen: false });
  handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, for example, send data to backend or perform other actions
    console.log("Submitted:", this.state);
    // Close the popup after submission
    this.setState({ showPopup: false });
  };
  componentWillUnmount() {
    // Clear the timer when the component unmounts
    clearTimeout(this.timer);
  }
  togglemodal5 = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };
  handleEmailChange(e) {
    const email = e.target.value;
    this.setState({ email }, () => {
      this.validateEmail();
    });
  }

  validateEmail() {
    const { email } = this.state;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      this.setState({ emailerr: "Invalid email address" });
    } else {
      this.setState({ emailerr: "" });
    }
  }
  handleMobileNoChange(e) {
    const mobileno = e.target.value;
    this.setState({ mobileno }, () => {
      this.validateMobileNo();
    });
  }
  validateMobileNo() {
    const { mobileno } = this.state;

    // You can define your own regex pattern based on your validation criteria
    // Example: 10-digit mobile number (for India)
    const mobileRegex = /^[0-9]{10}$/;

    if (!mobileRegex.test(mobileno)) {
      this.setState({ mobilenoerr: "Invalid mobile number" });
    } else {
      this.setState({ mobilenoerr: "" });
    }
  }
  handleClosePopup = () => {
    // Handle closing the pop-up
    this.setState({ showPopup: false });
  };

  handleSubmit1 = (userData) => {
    // Handle submitting user details
    console.log("User data:", userData);
    // Here, you can send the user data to your backend or perform any necessary actions
    this.setState({ showPopup: false });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      let value;

      value = {
        email: this.state.email,
        first_name: this.state.firstname,
        last_name: this.state.lastname,
        college_name: this.state.collegename,
        phoneno: this.state.mobileno,
        country_code: "91",
        category: this.state.category,
      };

      // eslint-disable-next-line
      const response = axios
        .post(`${ip}/auth/register`, value)
        .then((response) => {
          if (response) {
            alert("Registered Successfully");
            this.reset();
          }
        })

        .catch((err) => {
          alert("Please Enter Valid details");
          this.setState({});
        });
    }
  };
  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value }, this.validatePassword);
  };

  handleConfirmPasswordChange = (e) => {
    this.setState({ confirmPassword: e.target.value }, this.validatePassword);
  };

  // Validate password and confirm password
  validatePassword = () => {
    const { password, confirmPassword } = this.state;
    if (this.state.password === this.state.confirmPassword) {
      this.setState({ ispassword: true });
    } else {
      this.setState({ ispassword: false });
    }
  };
  handleEmailChange(e) {
    const email = e.target.value;
    this.setState({ email }, () => {
      this.validateEmail();
    });
  }

  validateEmail() {
    const { email } = this.state;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      this.setState({ emailerr: "Invalid email address" });
    } else {
      this.setState({ emailerr: "" });
    }
  }
  togglePassword = () => {
    const { ispassword } = this.state;
    this.setState({ ispassword: !ispassword });
  };

  toggle = () => {
    this.setState({ qfa1: !this.state.qfa1 });
  };
  toggle1 = () => {
    this.setState({ qfa2: !this.state.qfa2 });
  };
  toggle2 = () => {
    this.setState({ qfa3: !this.state.qfa3 });
  };
  toggle3 = () => {
    this.setState({ qfa4: !this.state.qfa4 });
  };
  toggle4 = () => {
    this.setState({ qfa5: !this.state.qfa5 });
  };
  handleMobileNoChange(e) {
    const mobileno = e.target.value;
    this.setState({ mobileno }, () => {
      this.validateMobileNo();
    });
  }
  validateMobileNo() {
    const { mobileno } = this.state;

    // You can define your own regex pattern based on your validation criteria
    // Example: 10-digit mobile number (for India)
    const mobileRegex = /^[0-9]{10}$/;

    if (!mobileRegex.test(mobileno)) {
      this.setState({ mobilenoerr: "Invalid mobile number" });
    } else {
      this.setState({ mobilenoerr: "" });
    }
  }
  render() {
    return (
      <div
        style={{
          overflow: "hidden",
          backgroundImage:
            "linear-gradient(to right, #ff7686 5%, #911bed 20%, #2e38bf 30%, #090920 45%)",
          backgroundSize: "100% 100%",
          backgroundPosition: "left top",
        }}
      >
        <div>
          {" "}
          <Navbar />
        </div>
        {this.state.showPopup && (
          <Modal isOpen={this.togglemodal5} centered>
            <ModalHeader toggle={this.togglemodal5}>
              <p style={{ fontSize: "16px", color: "black" }}>
                Register with Kambgeeks to get 10% off on any course!
              </p>
            </ModalHeader>
            <ModalBody>
              <div className=" col-sm-12 col-12 ">
                <div className="container">
                  <form className="form1">
                    <div className="input_box">
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        value={this.state.firstname}
                        onChange={(e) =>
                          this.setState({
                            firstname: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input_box">
                      <input
                        type="text"
                        placeholder="Enter Your Email"
                        value={this.state.email}
                        onChange={(e) => this.handleEmailChange(e)}
                      />
                    </div>
                    <div className="input_box">
                      <input
                        type="number"
                        placeholder="Enter Mobile No"
                        value={this.state.mobileno}
                        onChange={(e) => this.handleMobileNoChange(e)}
                      />
                    </div>
                    <button
                      style={{
                        align: "center",
                        fontFamily: "Prompt",
                        color: "white",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Save
                    </button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <button
                      style={{
                        align: "center",
                        fontFamily: "Prompt",
                        color: "white",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Clear
                    </button>
                  </form>
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
        <div
          className="topcompany"
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "38px",
            fontWeight: "800",

            fontFamily: "Prompt",
          }}
        >
          <br />
          Free Register
          <br />
          <br />
          <h3
            class="centered"
            style={{
              marginLeft: "8%",
              marginRight: "8%",
              fontFamily: "Prompt",
            }}
          >
            Here's what you get with KambGeeks Mentorship
          </h3>
          <br />
          <ul>
            <li>
              <h3 style={{ fontFamily: "Prompt" }}>
                Personalized Learning Plan: Craft a custom learning path
                tailored to your goals, skill level, and career aspirations.
              </h3>
            </li>
            <li>
              <h3 style={{ fontFamily: "Prompt" }}>
                Expert Guidance: Get matched with a seasoned tech professional
                who understands the industry and can answer your specific
                questions.
              </h3>
            </li>
          </ul>
        </div>
        {/* <div
          className="topcompany"
          style={{
            backgroundColor: "rgb(4,27,61)",
            color: "white",
            textAlign: "center",
            fontSize: "26px",
            fontWeight: "800",

            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
          }}
        >
          <br />
          <div
            class="col-8 mx-auto"
            style={{
              backgroundColor: "rgb(4,27,61)",
              color: "white",
              textAlign: "center",
              fontSize: "26px",
              fontWeight: "800",

              fontFamily:
                "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
            }}
          >
            <br />
            Free register to know how to crack the interviews at top product
            base company like &nbsp;
            <span style={{ color: "aqua" }}>AMAZON</span> &nbsp;
            <span style={{ color: "aqua" }}> NETFLIX </span>&nbsp;{" "}
            <span style={{ color: "aqua" }}>GOOGLE</span>&nbsp;
            <span style={{ color: "aqua" }}> MICROSOFT </span>&nbsp; and
            <br /> 1000+ tech companies. Free interviews questions puzzle and
            tech events and community meetings to stay updated with industry
            trends.Kamb geeks learners are working in average of 15L CTC. Join
            now! No regrets later.
            <br />
            <br />
            <br />
          </div>
        </div> */}
        <br />
        <div>
          {/* <h1
            className="h-headingnew"
            style={{
              color: "#202b5d",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            To know how to crack the interviews at top product base company like
            AMAZON , NETFLIX , GOOGLE , MICROSOFT and 1000+ tech companies.
          </h1> */}
        </div>
        <div style={{ width: "100%" }}>
          <div class="page-content">
            <div class="form-v4-content">
              <div class="form-left">
                <h2
                  style={{
                    fontSize: "110px",
                    color: "yellow",
                    textAlign: "center",
                  }}
                >
                  ❛❛
                </h2>
                <h1 className="lets" style={{ fontFamily: "Prompt" }}>
                  Let's Make <br />
                  it Happen
                  <br /> Together!
                </h1>
                {/* <p class="text-2">
                  <span>
                    School does not assure Employment Skill Assure You
                  </span>
                </p> */}
                {/* <div class="form-left-last">
                  <input
                    type="submit"
                    name="account"
                    class="account"
                    value="Have An Account"
                  />
                </div> */}
              </div>
              <form class="form-detail" action="#" method="post" id="myform">
                <h2 style={{ fontFamily: "Prompt" }}>
                  Register Now! Take the first step towards Your Dream Career
                </h2>
                <div class="form-group">
                  <div class="form-row form-row-1">
                    <label
                      className="required"
                      style={{ fontFamily: "Prompt" }}
                    >
                      First Name
                    </label>{" "}
                    <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.firstnameerr}
                    </div>
                    <input
                      type="text"
                      class="input-text"
                      required
                      value={this.state.firstname}
                      onChange={(e) =>
                        this.setState({
                          firstname: e.target.value,
                          firstnameerr: "",
                        })
                      }
                    />
                  </div>
                  <div class="form-row form-row-1">
                    <label
                      className="required"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Last Name
                    </label>{" "}
                    <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.lastnameerr}
                    </div>
                    <input
                      type="text"
                      class="input-text"
                      required
                      value={this.state.lastname}
                      onChange={(e) =>
                        this.setState({
                          lastname: e.target.value,
                          lastnameerr: "",
                        })
                      }
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-row form-row-1">
                    <label
                      className="required"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Your Email
                    </label>{" "}
                    <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.emailerr}
                    </div>
                    <input
                      type="text"
                      class="input-text"
                      required
                      value={this.state.email}
                      onChange={(e) => this.handleEmailChange(e)}
                      // pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}"
                    />
                  </div>

                  <div class="form-row form-row-1 webonlyr">
                    <label className="required">Mobile No</label>
                    <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.mobilenoerr}
                    </div>
                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <input
                            type="alphanumeric"
                            className="firstinput"
                            value={this.state.code}
                            onChange={(e) =>
                              this.setState({
                                code: e.target.value,
                                phnoErr: "",
                                codeErr: "",
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="9">
                        <input
                          required
                          type="number"
                          //   id="last_name"
                          class="input-text"
                          value={this.state.mobileno}
                          onChange={(e) => this.handleMobileNoChange(e)}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div class="form-row form-row-1 mobonlyr">
                    <label
                      className="required"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Mobile No
                    </label>{" "}
                    <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.mobilenoerr}
                    </div>
                    <Row>
                      <Col>
                        <FormGroup>
                          <input
                            type="alphanumeric"
                            className="firstinput"
                            value={this.state.code}
                            onChange={(e) =>
                              this.setState({
                                code: e.target.value,
                                phnoErr: "",
                                codeErr: "",
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col style={{ marginLeft: "-4%" }}>
                        <input
                          type="text"
                          required
                          className="firstinput1"
                          value={this.state.mobileno}
                          onChange={(e) => this.handleMobileNoChange(e)}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-row form-row-1  ">
                    <label style={{ fontFamily: "Prompt" }}>College Name</label>
                    <input
                      className="required"
                      type="text"
                      //   id="your_email"
                      class="input-text"
                      value={this.state.collegename}
                      onChange={(e) =>
                        this.setState({
                          collegename: e.target.value,
                        })
                      }
                      // pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}"
                    />
                  </div>
                  <div class="form-row form-row-1">
                    <label style={{ fontFamily: "Prompt" }}>Category</label>
                    <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.categoryerr}
                    </div>
                    <select
                      className="input-text"
                      value={this.state.category}
                      onChange={(e) => {
                        this.setState({
                          category: e.target.value,
                          categoryerr: "",
                        });
                      }}
                    >
                      <option value="" hidden id="select-placeholder">
                        Select Category...
                      </option>
                      {options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div class="form-row-last">
                  <input
                    type="submit"
                    class="register"
                    onClick={this.handleSubmit}
                  />
                </div>
                <div>
                  {" "}
                  <h6 className="footerc" style={{ fontFamily: "Prompt" }}>
                    By creating an account I have read and agree to Kambgeeks
                    &nbsp;
                    <a
                      href="/terms"
                      className="term"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Terms
                    </a>
                    &nbsp;
                  </h6>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div>
          <h1
            className="h-heading3"
            style={{
              color: "white",
              fontWeight: "500",
              textAlign: "center",
              fontFamily: "Prompt",
            }}
          >
            Questions?
          </h1>
          <br />
          <div>
            <Row style={{ margin: "0px" }}>
              <Col md="6" lg="6" sm="0" xs="0">
                <Fade>
                  <img
                    src={question}
                    alt="discussion"
                    width="100%"
                    height="100%"
                    className="pic_acc1"
                  />
                </Fade>
              </Col>
              <Col md="6" lg="6" sm="12" xs="12">
                <Fade right>
                  <div class="faq-container">
                    <div class="faq active">
                      <h3 class="faq-title" style={{ fontFamily: "Prompt" }}>
                        Who can take this training?
                      </h3>
                      {this.state.qfa1 && (
                        <p class="faq-text" style={{ fontFamily: "Prompt" }}>
                          College student who want to make them solid tech
                          engineer and stand out of crowd for the future jobs
                          opportunity.
                        </p>
                      )}
                      {this.state.ques1 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques1: false }, this.toggle)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques1: true }, this.toggle)
                          }
                        />
                      )}
                    </div>
                    <br />
                    <div class="faq active">
                      <h3 class="faq-title" style={{ fontFamily: "Prompt" }}>
                        What are the pre-requisites?
                      </h3>
                      {this.state.qfa2 && (
                        <p class="faq-text" style={{ fontFamily: "Prompt" }}>
                          You need to have a laptop and dedication to learning
                          development.
                        </p>
                      )}
                      {this.state.ques2 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques2: false }, this.toggle1)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques2: true }, this.toggle1)
                          }
                        />
                      )}
                    </div>
                    <br />
                    {/* <div class="faq active">
                      <h3 class="faq-title">
                        How much is the fee for this training?
                      </h3>
                      {this.state.qfa3 && (
                        <p class="faq-text">
                          The course fee is highly affordable and we provide
                          liberty to split the payments as during training &
                          after placement. Also, you can pay in installments
                        </p>
                      )}
                      {this.state.ques3 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: false }, this.toggle2)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: true }, this.toggle2)
                          }
                        />
                      )}
                    </div> */}
                    {/* <br /> */}
                    <div class="faq active">
                      <h3 class="faq-title" style={{ fontFamily: "Prompt" }}>
                        Do you provide placement assistance?
                      </h3>
                      {this.state.qfa4 && (
                        <p class="faq-text" style={{ fontFamily: "Prompt" }}>
                          Definitely Yes. We just expect you to practice more &
                          more to get placed. We keep referring you for job
                          opportunities till you get placed.
                        </p>
                      )}
                      {this.state.ques4 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques4: false }, this.toggle3)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques4: true }, this.toggle3)
                          }
                        />
                      )}
                    </div>
                    <br />
                    <div class="faq active">
                      <h3 class="faq-title" style={{ fontFamily: "Prompt" }}>
                        Is online training available?
                      </h3>
                      {this.state.qfa5 && (
                        <p class="faq-text" style={{ fontFamily: "Prompt" }}>
                          Yes, you can join our training in online module as
                          well.
                        </p>
                      )}

                      {this.state.ques5 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques5: false }, this.toggle4)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques5: true }, this.toggle4)
                          }
                        />
                      )}
                    </div>
                  </div>
                </Fade>
              </Col>

              <Col />
            </Row>
          </div>
        </div>
        <br />
        <section className="how-it-works-section pb-5">
          <div className="container">
            <div class="container">
              <div class="row">
                <div class="col-md-4">
                  <div class="apply-company-and-developers text-center">
                    <br />
                    <a class="btn btn-type-div-border-cst" href="ContactUs">
                      Save a Month on hiring!
                    </a>
                    <br />
                    <h1>
                      For
                      <span class="cst-secondary-color"> Company</span>
                    </h1>
                    <p>
                      Hire developers locally, offshore, or
                      <br />
                      nearshore, all vetted by Generative AI and
                      <br />
                      handpicked by humans.
                    </p>
                    <a class="btn hire-software-engineer" href="ContactUs">
                      Hire Software Engineers
                    </a>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="apply-company-and-developers text-center">
                    <br />
                    <a class="btn btn-type-div-border-cst" href="ContactUs">
                      Learn What Actually Need
                    </a>
                    <br />
                    <h1>
                      For
                      <span class="cst-secondary-color"> Community</span>
                    </h1>
                    <p>
                      Supercharge Your Learning with Kamb Geeks
                      <br />
                      Build Real-World Skills, Network with Peers,
                      <br />
                      and Unlock Career Opportunities.
                    </p>
                    <a class="btn hire-software-engineer" href="ContactUs">
                      Join Community
                    </a>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="border-line-col-cst text-center">
                    <br /> <br />
                    <button class="btn btn-type-div-border-cst">
                      You deserve a better pay.
                    </button>
                    <h1>
                      For
                      <span class="cst-secondary-color"> Talents</span>
                    </h1>
                    <p>
                      Traditional job portals are dead.
                      <br />
                      Let KambGeeks match you with high-paying
                      <br />
                      full time tech jobs.
                    </p>
                    <a class="btn hire-software-engineer" href="ContactUs">
                      Apply for jobs
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        <div>
          <br />
          <h4
            className="h-heading"
            align="center"
            style={{ fontFamily: "Prompt" }}
          >
            There is no alternative to build tech Career
          </h4>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a href="/ContactUs">
              <div
                className="getdemo-center"
                align="center"
                style={{ fontFamily: "Prompt" }}
              >
                Contact Us
              </div>
            </a>
          </div>
          <br />
          <br />
          <Footer />
        </div>
      </div>
    );
  }
}
export default Register;
