import React from "react";
import Footer from "./footer.js";
import Navbar from "./Navbar.js";
import "../styles/footer.css";
import "../styles/newhp.css";
import LightSpeed from "react-reveal/LightSpeed";
import "../styles/navbar.css";
import iot from "../images/iot.png";
import blockchain1 from "../images/blockchain1.svg";
import Bounce from "react-reveal/Bounce";
import img from "../images/study.jpg";
import ai1 from "../images/AI1.svg";
import ml1 from "../images/ML1.svg";
import chatbot from "../images/Chatbots.svg";
import vr1 from "../images/vr1.svg";
import ar1 from "../images/ar1.svg";
import { Icon } from "@iconify/react";
import "../styles/hp.css";
import image from "../images/New6.png";
import Image30 from "../images/Community.png";
import Image2 from "../images/Home.png";
import Image3 from "../images/artificial-intelligence.png";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import Image83 from "../images/2204.i201.043.F.m004.c9.walking people isometric.jpg";
import Image4 from "../images/backend.png";
import Image5 from "../images/bitcoin.png";
import Image6 from "../images/chatbot.png";
import Image7 from "../images/iot.png";
import Image8 from "../images/headset.png";
import Image9 from "../images/machine-learning.png";
import Image10 from "../images/bookmark.png";
import Image11 from "../images/user-verification-interface-symbol.png";
import Image12 from "../images/working.png";
import Image13 from "../images/24-hour-clock.png";
import Image14 from "../images/Orgs.png";
import Image15 from "../images/Connected-cuate.png";
import Image16 from "../images/group-chat.png";
import Image17 from "../images/diversity.png";
import Image18 from "../images/learning.png";
import Image19 from "../images/number-one.png";
import Image20 from "../images/number-2.png";
import Image21 from "../images/number-3.png";
import Fade from "react-reveal/Fade";
import Image22 from "../images/people.png";
import Image23 from "../images/faq.png";
import Image24 from "../images/faq1.png";
import { Container, Row, Col } from "react-bootstrap";
import { FiArrowDownCircle, FiArrowUpCircle } from "react-icons/fi";
import { useState } from "react";
function Home() {
  const [isCollapsed1, setIsCollapsed1] = useState(false);

  const toggle1 = () => {
    setIsCollapsed1(!isCollapsed1);
  };
  const [isCollapsed2, setIsCollapsed2] = useState(false);

  const toggle2 = () => {
    setIsCollapsed2(!isCollapsed2);
  };
  const [isCollapsed3, setIsCollapsed3] = useState(false);

  const toggle3 = () => {
    setIsCollapsed3(!isCollapsed3);
  };
  const [isCollapsed4, setIsCollapsed4] = useState(false);

  const toggle4 = () => {
    setIsCollapsed4(!isCollapsed4);
  };
  const [isCollapsed5, setIsCollapsed5] = useState(false);

  const toggle5 = () => {
    setIsCollapsed5(!isCollapsed5);
  };
  return (
    <div className="App" style={{ overflow: "hidden" }}>
      <Navbar />

      <br />
      <br />
      <div className="content_acc">
        <Container>
          <Row
            style={{
              margin: "0px",
            }}
          >
            <Col md={6} xs={12} lg={6}>
              <Fade bottom>
                <div className="quote-align">
                  <h1 className="quote">
                    Education <br /> that gets you industry-ready
                  </h1>
                  <br />
                  <h6
                    className="quote-desc"
                    style={{ color: "#202b5d", paddingBottom: "5%" }}
                  >
                    College & bookish knowledge doesn't prepare you for
                    real-world and modern careers.Travail Academy, co-creates
                    programs with top industry professionals who can help you
                    get years ahead in your career.
                  </h6>
                  <br />
                  <a
                    data-text="Get Started"
                    className="quo"
                    style={{ alignSelf: "center" }}
                    href="#contact"
                  >
                    Get Started
                  </a>
                </div>
              </Fade>
            </Col>
            <Col md={6} xs={12} lg={6}>
              <Bounce>
                <img src={img} className="pic_acc" />
              </Bounce>
            </Col>
          </Row>
        </Container>
      </div>
      <br />
      <br />
      <Row>
        <div>
          <Row>
            <Col md="12">
              <h1 style={{ color: "#1c2b70", textAlign: "center" }}>
                Kamb Geeks
              </h1>
              <br />
              <br />
              <br />
              <div class="information-container ">
                <div class="information-box" data-aos="flip-left">
                  <h5 class="highlight1">
                    <b>100+</b>
                  </h5>
                  <p>Students</p>
                </div>
                <div class="information-box" data-aos="flip-left">
                  <h5 class="highlight2">
                    <b>10+</b>
                  </h5>
                  <p>Programs</p>
                </div>
                <div class="information-box" data-aos="flip-left">
                  <h5 class="highlight3">
                    <b>8.1/10</b>
                  </h5>
                  <p>Average Rating</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <br />
        <br />u
        <div>
          <Row>
            <div id="special">
              <div>
                <h1
                  className="h-heading"
                  style={{
                    color: "#202b5d",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  We specialize in?
                </h1>
                <br />
                <LightSpeed left>
                  <div className="splbox">
                    <Row style={{ margin: "0px" }}>
                      <Col md="4" lg="3" sm="6" xs="6">
                        <div className="splimgbox">
                          <img src={iot} className="specialimg" />
                          <p>IoT</p>
                        </div>
                      </Col>
                      <Col md="4" lg="3" sm="6" xs="6">
                        <div className="splimgbox">
                          <img src={blockchain1} className="specialimg" />
                          <p>Blockchain</p>
                        </div>
                      </Col>
                      <Col md="4" lg="3" sm="6" xs="6">
                        <div className="splimgbox">
                          <img src={ai1} className="specialimg" />
                          <p>Artificial Intelligence</p>
                        </div>
                      </Col>
                      <Col md="4" lg="3" sm="6" xs="6">
                        <div className="splimgbox">
                          <img src={ml1} className="specialimg" />
                          <p>Machine Learning</p>
                        </div>
                      </Col>
                      <Col md="4" lg="4" sm="6" xs="6">
                        <div className="splimgbox">
                          <img src={chatbot} className="specialimg" />
                          <p>Chat Bots</p>
                        </div>
                      </Col>
                      <Col md="4" lg="4" sm="6" xs="6">
                        <div className="splimgbox">
                          <img src={vr1} className="specialimg" />
                          <p>Virtual Reality</p>
                        </div>
                      </Col>
                      <Col md="4" lg="4" sm="6" xs="6">
                        <div className="splimgbox">
                          <img src={ar1} className="specialimg" />
                          <p>Software Development</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </LightSpeed>
              </div>
            </div>
          </Row>
        </div>
        <div>
          <h1
            className="h-heading"
            style={{
              color: "#202b5d",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            An immersive learning experience
          </h1>
          <br />
          <div className="ace-container">
            <div>
              <Row>
                <Col>
                  <Icon
                    className="ace-icon"
                    icon="mdi:marker-tick"
                    color="#1d60e3"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="ae-head">
                    Develop skills for
                    <br />
                    real career Travail
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="h-content">
                    Cutting edge curriculam designed in guidance with industry
                    and academia to develop job skills.
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>
                  <Icon
                    className="ace-icon"
                    icon="clarity:assign-user-solid"
                    color="#1d60e3"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="ae-head">
                    Learn from experts active in their field, not out-of-touch
                    trainers
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="h-content">
                    Leading practitioners who bring current best practices and
                    case studies to sessions that for into your work schedule
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>
                  <Icon
                    className="ace-icon"
                    icon="ic:outline-code"
                    color="#1d60e3"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="ae-head">
                    Learn by working on real-world problems
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="h-content">
                    Capstone projects involving <br /> real world data sets with
                    virtual labs for hands-on learning
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>
                  <Icon
                    className="ace-icon"
                    icon="mdi:alarm-clock-check"
                    color="#1d60e3"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="ae-head">
                    Structured guidance ensuring learning never stops
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="h-content">
                    24 X 7 Learning support from mentors and a community of
                    like-minded peers to resolve any conceptual doubts
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <br />
          <br />
          <Row>
            <div>
              <h1
                className="h-heading"
                style={{
                  color: "#202b5d",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Trusted by Tech Professionals at Thousands of Organizations
              </h1>
              <br />
              <Fade>
                <Col md="2" />
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <img src={Image14} style={{ width: "70%" }} />
                </Col>
              </Fade>
            </div>
          </Row>
          <br />
          <br />
          <Row>
            <Col md="12">
              <h4
                className="h-heading"
                style={{
                  color: "#202b5d",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                What Makes us Different
              </h4>
            </Col>
            <br />
            <br />
            <div>
              <Row>
                <Col md="6">
                  <div class="right-image" data-aos="fade-right">
                    <img src={Image16} alt="Right Image 2"></img>
                    <div class="details">
                      <h6>
                        <b style={{ color: "#1d60e3" }}>
                          Interact with the best
                        </b>
                      </h6>
                      <p>
                        Go beyond pre-recorded courses with Travail School LIVE
                        and get doubts cleared personally
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div class="right-image" data-aos="fade-right">
                    <img src={Image17} alt="Right Image 2"></img>
                    <div class="details">
                      <h6>
                        <b style={{ color: "#1d60e3" }}>
                          The community at the center
                        </b>
                      </h6>
                      <p>
                        Enjoy the best of peer-led learning with tons of events
                        while building lifelong relationships
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div class="right-image" data-aos="fade-right">
                    <img src={Image18} alt="Right Image 3"></img>
                    <div class="details">
                      <h6>
                        <b style={{ color: "#1d60e3" }}>Learn by Doing</b>
                      </h6>
                      <p>
                        Actionable programs to offset the Pareto Principle; you
                        will execute projects using what you learned
                      </p>
                    </div>
                  </div>
                  <hr />
                </Col>
                <Col md="6">
                  <div className="CummunityImg">
                    <img src={Image30} className="eduimage"></img>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <Col md="12">
              <h1
                className="h-heading"
                style={{
                  color: "#202b5d",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                <b style={{ color: "#1c2b70" }}>
                  Community-led learning is the future
                </b>
              </h1>
              <h4 style={{ color: "#1d60e3" }}>
                We are at school facilitate a dynamic community to build
                real-world probem solving skills
              </h4>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <div>
            <Row>
              <Col md="6">
                <div class="picture">
                  <img
                    style={{ width: "100%", marginTop: "5%" }}
                    src={Image83}
                    alt="Main Image"
                    data-aos="zoom-in"
                  ></img>
                </div>
              </Col>
              <Col md="6">
                <div class="details2">
                  <div class="details2-item" data-aos="fade-right">
                    <img src={Image19} alt="Small Image 1"></img>
                    <div>
                      <h6>
                        <b style={{ color: "#1c2b70" }}>
                          Build relationship beyond networks
                        </b>
                      </h6>
                      <p>
                        <br></br>Work with motivated peers having fresh
                        out-of-the-box thinking and showing you new ways to do
                        things
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div class="details2-item" data-aos="fade-right">
                    <img src={Image20} alt="Small Image 2"></img>
                    <div>
                      <h6>
                        <b style={{ color: "#1c2b70" }}>
                          Live events to engage in
                        </b>
                      </h6>
                      <p>
                        <br></br>Participate in live Q&As, debates, hackathons,
                        and other healthy contests to drive home the learning
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div class="details2-item" data-aos="fade-right">
                    <img src={Image21} alt="Small Image 3"></img>
                    <div>
                      <h6>
                        <b style={{ color: "#1c2b70" }}>
                          Find career opportunities
                        </b>
                      </h6>
                      <p>
                        <br></br>Become a part of an ecosystem where members
                        help access opportunities for jobs and freelance
                        projects
                      </p>
                    </div>
                  </div>
                  <hr />
                </div>
              </Col>
            </Row>
          </div>
          <br />
          <br />
          <Row>
            <Col md="12">
              {" "}
              <h4>
                <b className="details" style={{ color: "#1c2b70" }}>
                  Questions?
                </b>
              </h4>
            </Col>
          </Row>
          <br />
          <br />
          <div>
            <Row>
              <Col md="6">
                <div class="faq-container">
                  <div class="faq active">
                    <h3 class="faq-title">Who can take this training?</h3>
                    {isCollapsed1 && (
                      <p class="faq-text">
                        College student who want to make them solid tech
                        engineer and stand out of crowd for the future jobs
                        opportunity.
                      </p>
                    )}
                    {isCollapsed1 ? (
                      <IoIosArrowDropdownCircle
                        class="faq-toggle"
                        onClick={toggle1}
                      />
                    ) : (
                      <IoIosArrowDroprightCircle
                        class="faq-toggle"
                        onClick={toggle1}
                      />
                    )}
                  </div>
                  <br />
                  <div class="faq active">
                    <h3 class="faq-title">What are the pre-requisites?</h3>
                    {isCollapsed2 && (
                      <p class="faq-text">
                        You need to have a laptop and dedication to learning
                        development.
                      </p>
                    )}
                    {isCollapsed2 ? (
                      <IoIosArrowDropdownCircle
                        class="faq-toggle"
                        onClick={toggle2}
                      />
                    ) : (
                      <IoIosArrowDroprightCircle
                        class="faq-toggle"
                        onClick={toggle2}
                      />
                    )}
                  </div>
                  <br />
                  <div class="faq active">
                    <h3 class="faq-title">
                      How much is the fee for this training?
                    </h3>
                    {isCollapsed3 && (
                      <p class="faq-text">
                        The course fee is highly affordable and we provide
                        liberty to split the payments as during training & after
                        placement. Also, you can pay in installments
                      </p>
                    )}
                    {isCollapsed3 ? (
                      <IoIosArrowDropdownCircle
                        class="faq-toggle"
                        onClick={toggle3}
                      />
                    ) : (
                      <IoIosArrowDroprightCircle
                        class="faq-toggle"
                        onClick={toggle3}
                      />
                    )}
                  </div>
                  <br />
                  <div class="faq active">
                    <h3 class="faq-title">
                      Do you provide placement assistance?
                    </h3>
                    {isCollapsed4 && (
                      <p class="faq-text">
                        Definitely Yes. We just expect you to practice more &
                        more to get placed. We keep referring you for job
                        opportunities till you get placed.
                      </p>
                    )}
                    {isCollapsed4 ? (
                      <IoIosArrowDropdownCircle
                        class="faq-toggle"
                        onClick={toggle4}
                      />
                    ) : (
                      <IoIosArrowDroprightCircle
                        class="faq-toggle"
                        onClick={toggle4}
                      />
                    )}
                  </div>
                  <br />
                  <div class="faq active">
                    <h3 class="faq-title">Is online training available?</h3>
                    {isCollapsed5 && (
                      <p class="faq-text">
                        Yes, you can join our training in online module as well.
                      </p>
                    )}

                    {isCollapsed5 ? (
                      <IoIosArrowDropdownCircle
                        class="faq-toggle"
                        onClick={toggle5}
                      />
                    ) : (
                      <IoIosArrowDroprightCircle
                        class="faq-toggle"
                        onClick={toggle5}
                      />
                    )}
                  </div>
                </div>
              </Col>
              <Col md="6">
                <img src={Image24} class="ques-img" data-aos="zoom-in"></img>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
      <Footer />
    </div>
  );
}
export default Home;
