import React, { Component } from "react";
import Image24 from "../images/faq1.png";
import "../styles/newhp.css";
import { Row, Col, Card, Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import "../styles/navbar.css";
import ajith from "../images/ajith.jpeg";
import noo from "../images/noo.png";
import why from "../images/why.jpg";
import travail from "../images/travail.png";
import image1 from "../images/kam1.jpeg";
import image2 from "../images/devops.png";
import image3 from "../images/shield.png";
import image4 from "../images/human-brain.png";
import { NavLink } from "react-router-dom";
import image5 from "../images/cloud.png";
import image6 from "../images/settings.png";
import { Link } from "react-router-dom";
import q1 from "../images/q1.jpg";
import q2 from "../images/q2.jpg";
import tech from "../images/tech.jpg";
// import OwlCarousel from "react-owl-carousel";
import f1 from "../images/f1.png";
import f2 from "../images/f2.png";
import profile from "../images/profile.jpg";
import f3 from "../images/f3.png";
import f4 from "../images/f4.png";
import f5 from "../images/f5.png";
import p1 from "../images/p1.jpeg";
import skil from "../images/skil.jpg";
import p2 from "../images/p2.jpeg";
import p3 from "../images/p3.jpeg";
import p4 from "../images/p4.jpeg";
import p5 from "../images/p5.jpeg";
import p6 from "../images/p6.jpeg";
import threed from "../images/3d.jpg";
import loc from "../images/loc.jpg";
import inf from "../images/inf.jpg";
import vis from "../images/vis.jpg";
import question from "../images/question.png";
import Image83 from "../images/2204.i201.043.F.m004.c9.walking people isometric.jpg";
import img from "../images/study.jpg";
import LightSpeed from "react-reveal/LightSpeed";
import iot from "../images/iot1.svg";
import blockchain1 from "../images/blockchain1.svg";
import ai1 from "../images/AI1.svg";
import ml1 from "../images/ML1.svg";
import balaji from "../images/Balaji.jpeg";
import chatbot from "../images/Chatbots.svg";
import vr1 from "../images/vr1.svg";
import ar1 from "../images/ar1.svg";
import { Icon } from "@iconify/react";
import ace1 from "../images/ace1.jpg";
import grp from "../images/grp.jpg";
import numone from "../images/num1.png";
import numtwo from "../images/num2.png";
import numthree from "../images/num3.png";
import company from "../images/company1.png";
import message from "../images/chat.png";
import group from "../images/group.png";
import single from "../images/study1.png";
import Footer from "./footer";
import onen from "../images/onen.jpg";
import vision from "../images/vision.jpg";
import "../styles/navbar.css";
import "../styles/hp.css";
import Navbar from "./Navbar.js";
import { useState } from "react";
import search from "../images/search.jpg";
import search1 from "../images/onen.jpg";
import search2 from "../images/search2.jpg";
import search3 from "../images/vis1.jpg";
import search4 from "../images/search4.jpg";
import search5 from "../images/section5.jpg";
import search6 from "../images/second.jpg";
import path from "../images/path.jpg";
// const [isCollapsed1, setIsCollapsed1] = useState(false);

// const toggle1 = () => {
//   setIsCollapsed1(!isCollapsed1);
// };
// const [isCollapsed2, setIsCollapsed2] = useState(false);

// const toggle2 = () => {
//   setIsCollapsed2(!isCollapsed2);
// };
// const [isCollapsed3, setIsCollapsed3] = useState(false);

// const toggle3 = () => {
//   setIsCollapsed3(!isCollapsed3);
// };
// const [isCollapsed4, setIsCollapsed4] = useState(false);

// const toggle4 = () => {
//   setIsCollapsed4(!isCollapsed4);
// };
// const [isCollapsed5, setIsCollapsed5] = useState(false);

// const toggle5 = () => {
//   setIsCollapsed5(!isCollapsed5);
// };
export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qfa1: false,
      qfa2: false,
      qfa3: false,
      qfa4: false,
      qfa5: false,
      ques5: false,
      ques4: false,
      ques3: false,
      ques2: false,
      ques1: false,
      images: [search6, search1, search2, search5],
      imageContent1: ["10+", "8.1/10", "1K+", "70+"],
      imageContent: [
        "Total Programs",
        "Average Rating",
        "Total Students ",
        "Institutions",
      ],

      currentImageIndex: 0,
    };
  }
  nextImage = () => {
    this.setState((prevState) => ({
      currentImageIndex: (prevState.currentImageIndex + 1) % 4,
    }));
  };
  prevImage = () => {
    this.setState((prevState) => ({
      currentImageIndex:
        prevState.currentImageIndex === 0 ? 3 : prevState.currentImageIndex - 1,
    }));
  };
  toggle = () => {
    this.setState({ qfa1: !this.state.qfa1 });
  };
  toggle1 = () => {
    this.setState({ qfa2: !this.state.qfa2 });
  };
  toggle2 = () => {
    this.setState({ qfa3: !this.state.qfa3 });
  };
  toggle3 = () => {
    this.setState({ qfa4: !this.state.qfa4 });
  };
  toggle4 = () => {
    this.setState({ qfa5: !this.state.qfa5 });
  };

  componentDidMount() {
    // Automatically advance the image slider every 5 seconds
    this.sliderInterval = setInterval(this.nextImage, 4000); // 5000 milliseconds = 5 seconds
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to prevent memory leaks
    clearInterval(this.sliderInterval);
  }
  render() {
    return (
      <div
        style={{
          overflow: "hidden",
          backgroundImage:
            "linear-gradient(to right, #ff7686 5%, #911bed 20%, #2e38bf 30%, #090920 45%)",
          backgroundSize: "100% 100%",
          backgroundPosition: "left top",
        }}
      >
        <div>
          <nav class="navbar navbar-expand-lg bg-body-tertiary " id="navbar">
            <div class="container-fluid">
              <a class="navbar-brand" href="#">
                <img id="logo" src={image1} alt="logo"></img>
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    {/* <a class="nav-link active" aria-current="page" href="#">
                Home
              </a> */}
                    <a
                      className="nav-link"
                      href="/"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Home
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      className="nav-link"
                      href="/Skill"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Why skills&nbsp;?
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/Corporates"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Corporates
                    </a>
                  </li>
                  <div className="courseonly">
                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="/technologies"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Courses
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="tag" src={image2}></img> &nbsp;
                              Software dev
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Database
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Game development
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Mobile development
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Programming languages
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Web Development
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="shield" src={image3}></img>&nbsp;
                              Security
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Certification
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Governance,risk,& compliance
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security architecture & engineering
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security operations
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security testing
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="brain" src={image4}></img>&nbsp;
                              Machine learning
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Big data
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Business intelligence
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Data visualization
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Databases
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Languages & libraries
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Machine learning
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="cloud" src={image5}></img> &nbsp;
                              Cloud
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Cloud architecture & design
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Cloud platforms
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Salesforce CRM
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="shield" src={image3}></img>&nbsp; IT
                              Ops
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Client operating systems
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Collaboration platforms
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Configuration management
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Containers
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              IT automation
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Network architecture
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Virtualization
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </div>
                  <div className="courseonly1">
                    <li class="nav-item dropdown dropdown-mega position-static">
                      <a
                        href="/technologies"
                        class="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="Outside"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Courses
                      </a>

                      <div class="dropdown-menu shadow container-fluid col-11 w-70 mx-5">
                        <div class="mega-content px-md-4">
                          <div class="conatiner-fluid">
                            <div class="row">
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-3">
                                  <img class="tag" src={image2}></img>
                                  <h5
                                    class="drop-title my-2 mx-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Software dev
                                  </h5>
                                </div>
                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Database
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Game development
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Mobile development
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Programming languages
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Web development
                                  </a>
                                </div>
                                <div class="logs mb-3">
                                  <img class="shield" src={image3}></img>
                                  <h5
                                    class="drop-title my-3 mt-4 mx-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Certification
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Governance,risk,& compliance
                                  </a>
                                  <a
                                    class="dropdown-item "
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security architecture & engineering
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security operations
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security testing
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-2">
                                  <img class="brain" src={image4}></img>
                                  <h5
                                    class="drop-title mt-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Data & machine learning
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Big data
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Business intelligence
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Data visualization
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Databases
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Languages & libraries
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Machine learning
                                  </a>
                                </div>
                                <div class="logs mb-3">
                                  <img class="cloud" src={image5}></img>
                                  <h5
                                    class="drop-title my-3 px-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud architecture & design
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud platforms
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Salesforce CRM
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-3">
                                  <img class="setting" src={image6}></img>
                                  <h5
                                    class="drop-title my-2 mx-1"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    IT Ops
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Client operating systems
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Collaboration platforms
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Configuration management
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Containers
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    IT automation
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Network architecture
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Virtualization
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      href="about"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Company
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/ContactUs"
                      style={{ fontFamily: "Prompt" }}
                    >
                      ContactUs&nbsp;
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/Register"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Free Register&nbsp;
                    </a>
                  </li>
                </ul>
                {/* <form class="d-flex" role="search">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button class="btn btn-outline-success" type="submit">
              Search
            </button>
          </form> */}
              </div>
            </div>
          </nav>
        </div>
        <div
          className="topcompany"
          style={{
            // backgroundColor: "rgb(4,27,61)",
            color: "white",
            textAlign: "center",
            fontSize: "38px",
            fontWeight: "900",

            fontFamily: "Prompt",
          }}
        >
          <br />
          About us
          <br />
        </div>
        <div className="salesnew">
          <div className="container-fluid col-10">
            <Row>
              <div className="col-lg-6 col-mg-6 col-12 column2">
                <Bounce>
                  <img src={threed} className="conimg2 " />
                </Bounce>
              </div>
              <div className="col-lg-6 col-mg-6 col-12 column1">
                <div className="salesimgt2 " data-aos="fade-right">
                  <h1 className="conimgc2" style={{ fontFamily: "Prompt" }}>
                    What do we do @ Kamb geeks Inc.?
                  </h1>
                  <br />
                  <p className="conimgpara2" style={{ fontFamily: "Prompt" }}>
                    Kamb Geeks we providing AI Learning Management Platform
                    offer online learning in many different vernacular languages
                    along with English and connect mentors from top product base
                    companies with students in best ways it helps developments
                    signal a significant need for Indian learners to bridge the
                    critical skills gap to ensure this digital potential does
                    not turn into a lost opportunity and also we help students
                    to fill the gap between academics and Industry needs and
                    also we help industry people to update their skills as
                    industry need and place in good packages.
                    <br />
                    <br /> 100+ our learners now working in top product base
                    companies with great CTC. We also help students and
                    university on the research based projects and Industry
                    projects with our one to one mentors.
                  </p>
                  <br />
                </div>
                <br />
                <Row>
                  <Col>
                    <div>
                      <a
                        className="salebtn1"
                        href="ContactUs"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Explore Kambgeeks
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
          </div>
        </div>
        <br />
        <br />
        <div className="con3">
          <div className="container-fluid col-10">
            <Row>
              <div className="col-lg-6 col-mg-6 col-12 aboutnew1">
                <Bounce>
                  <img src={q2} className="conimg3 " />
                </Bounce>
              </div>
              <div className="col-lg-6 col-mg-6 col-12 column1">
                <div className="salesimgt2 " data-aos="fade-right">
                  <h1 className="conimgc2" style={{ fontFamily: "Prompt" }}>
                    Why Kamb geeks?
                  </h1>
                  <br />

                  <p className="conimgpara3" style={{ fontFamily: "Prompt" }}>
                    Despite having a working-age population of over half a
                    billion, less than a fraction of them are considered
                    employable. These developments signal a significant need for
                    Indian learners to bridge the critical skills gap to ensure
                    this digital potential does not turn into a lost
                    opportunity.
                    <br />
                    <br /> Moreover, the tech ecosystem needs to ensure that
                    what is a skill gap right now does not turn into a skill
                    crisis hampering the country's economic growth and
                    development, as businesses struggle to find qualified talent
                    to fill critical roles.
                  </p>
                  <br />
                </div>
                <br />
                <Row>
                  <Col>
                    <div>
                      <a
                        className="salebtn1"
                        href="ContactUs"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Explore Kambgeeks
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="col-lg-6 col-mg-6 col-12 aboutnew">
                <Bounce>
                  <img src={q2} className="conimg3 " />
                </Bounce>
              </div>
            </Row>
          </div>
        </div>
        <br />
        <div className="con3">
          <div className="container-fluid col-10">
            <Row>
              <div className="col-lg-6 col-mg-6 col-12 column2">
                <Bounce>
                  <img src={vis} className="conimg4 " />
                </Bounce>
              </div>
              <div className="col-lg-6 col-mg-6 col-12 column1">
                <div className="salesimgt2 " data-aos="fade-right">
                  <h1 className="conimgc2" style={{ fontFamily: "Prompt" }}>
                    Vision & Mission
                  </h1>
                  <br />

                  <p className="conimgpara3" style={{ fontFamily: "Prompt" }}>
                    <b> VISION:</b> &nbsp; Our technical education in India by
                    2047 is to develop a skilled workforce that is globally
                    competitive and can contribute to the growth and development
                    of the country. It aims to provide access to quality
                    education to students, regardless of their background, and
                    to promote innovation and entrepreneurship.
                  </p>
                  <br />
                  <p className="conimgpara3" style={{ fontFamily: "Prompt" }}>
                    <b> MISION:</b>&nbsp; Our mission is to empower students and
                    educators with the latest technology and tools, and to make
                    education accessible to all, regardless of location or
                    background. We strive to provide comprehensive,
                    user-friendly, and cost-effective solutions that enhance
                    learning outcomes and enable personal growth.
                  </p>
                  <br />
                </div>
                <br />
                <Row>
                  <Col>
                    <div>
                      <a
                        className="salebtn1"
                        href="ContactUs"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Explore Kambgeeks
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div style={{ color: "white" }}>
          <br />

          <div className="container">
            <div class="row justify-content-center justify-content-lg-start">
              <br />

              <div class="col-8 mx-auto">
                {/* <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br /> */}

                <h2
                  class="pathnew mb-2 mb-lg-4"
                  style={{ fontFamily: "Prompt" }}
                >
                  Our path to success
                </h2>
                <p className="pathnew1" style={{ fontFamily: "Prompt" }}>
                  In 2020, we started The Kamb Geeks to simplify the Tech
                  educations and empower Tech consumers to regularly. A 2023
                  later, with 1000K+ learns and 1K+ learners who works in top
                  product base company with great Best of all? We do it
                  hassle-free, which means no spam only growth to the Tech
                  peoples and students to grow in the Tech career
                </p>
                <br />
                <br />

                {/* <br /> */}
              </div>

              {/* <div className="col-1"></div>
              <div class="col-4  ">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <img src={path} className="Bimg" />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div> */}
            </div>
          </div>
          {/* <div className="container">
            <div className="row" style={{ margin: "0px" }}>
              <div className="col-md-4 col-sm-12 col-12">
                <img src={ajith} className="Bimg" />
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-7 col-sm-12 col-12">
                <h4
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    marginTop: "30px",
                    textAlign: "left",
                  }}
                >
                  {" "}
                  TeamHead
                </h4>
                <h4
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    marginTop: "15px",
                    textAlign: "left",
                  }}
                >
                  Founder
                </h4>
                <br />
                <p>
                  Ajith Kumar is a Tech entrepreneur and Product Developer who
                  built and scaled multiple AI-infused software products from
                  idea to launch. Also made the first git push for Kamb geeks AI
                  learning platform . Ajith completed his Master of Science from
                  the College of Engineering, Guindy (CEG). Published Multiple
                  research papers related to the field of Artificial
                  intelligence and computer science in international journals.
                  Ajith has a proven record of building a highly productive team
                  and organization from scratch. At Kamb geeks Ajith is
                  responsible for the technology roadmap and overall growth of
                  the company.
                </p>
              </div>
            </div>

            <br />
            <hr></hr>
            <br />
          </div>
          <div className="container">
            <div className="row" style={{ margin: "0px" }}>
              <div className="col-md-4 col-sm-12 col-12">
                <img src={balaji} className="Bimg" />
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-7 col-sm-12 col-12">
                <h4
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    marginTop: "30px",
                    textAlign: "left",
                  }}
                >
                  {" "}
                  Director
                </h4>
                <h4
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    marginTop: "15px",
                    textAlign: "left",
                  }}
                >
                  Chief Sales Officer
                </h4>
                <br />
                <p className="Bcontent">
                  Dr. Balaji, CSO of kamb Geeks a versatile and dynamic
                  personality from the Indian engineering industry,As a
                  recognized researcher and key note speaker, he has contributed
                  to various events worldwide, including serving as the Chief
                  Guest, International Key Note Speaker in 33+ countries, which
                  speaks volumes about his reputation and contribution.He has
                  been involved in 70+ research publications in renowned Scopus
                  and SCI-E Indexed Journals and operating as Reviewer at Publon
                  London Journal and Served as a Reviewer for DRDO-DSJ (Defense
                  Science Journal), Ministry of Defense and awarded by DESIDOC.
                  He is also the Editor-in-Chief for Journal of Material Science
                  and Engineering, UAE.Records, Asia Book of Records and
                  recognized as a Guinness Record Holder for being an Editor for
                  the World's Thickest Book (ISBN: 978-93-95196-75-8).
                </p>
              </div>
            </div>
          </div> */}
          <br />
        </div>
        <br /> <br />
        {/* <div>
          <h4
            className="h-heading"
            style={{ align: "center", color: "rgb(32, 43, 93)" }}
          >
            {" "}
            Industry and Research Program
          </h4>
          <Row>
            <Col style={{ paddingLeft: "25%" }}>
              <img src={noo} className="Bimg1  mx-auto" alt="Image 1" />
            </Col>
            <Col style={{ paddingRight: "25%" }}>
              <img src={travail} className="Bimg2  mx-auto" alt="Image 2" />
            </Col>
          </Row>
        </div> */}
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-10">
              <div className="image-slider text-center">
                <h4
                  className="skillnew"
                  align="center"
                  style={{
                    color: "white",
                    fontWeight: "900",
                    fontFamily: "Prompt",
                  }}
                >
                  Kamb Geeks
                </h4>
                <Icon
                  icon="fa-solid:less-than"
                  color="white"
                  style={{ cursor: "pointer" }}
                  onClick={this.prevImage}
                  className="lefti"
                />

                {/* <button onClick={this.prevImage}>Previous</button> */}
                <img
                  className="conimgnew equal-width-image"
                  src={this.state.images[this.state.currentImageIndex]}
                  alt={`Image ${this.state.currentImageIndex + 1}`}
                />

                <Icon
                  icon="fa-solid:greater-than"
                  color="white"
                  onClick={this.nextImage}
                  style={{ cursor: "pointer" }}
                  className="righti"
                />

                <div className="image-content1">
                  <p>
                    {this.state.imageContent1[this.state.currentImageIndex]}
                  </p>
                </div>
                <div className="image-content2">
                  <p>{this.state.imageContent[this.state.currentImageIndex]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div style={{ color: "white" }}>
          <br />

          <div className="pt-3 pt-lg-4 pb-3 pb-lg-8 about-office">
            <div className="container">
              <div class="row justify-content-center">
                <Col>
                  <h2
                    className="office-headline h1 mb-2 mb-lg-4"
                    style={{ fontFamily: "Prompt" }}
                  >
                    Our workplace
                  </h2>
                </Col>
              </div>
              <br /> <br />
              {/* <div className="cardcontact">
                <div className=" col-10 col-lg-4">
                  <img src={image1} class="profileimage " />
                  <div class="card-body">
                    <h5 class="display-sm">99%</h5>
                    <div className="panel-text">
                      <a>
                        {" "}
                        of employees are active members of The Zebra’s Employee
                        Resource Groups.
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-10 col-lg-4">
                  <img src={image1} class="profileimage " />
                  <div class="card-body">
                    <h5 class="display-sm">60%</h5>
                    <div className="panel-text">
                      <a>
                        {" "}
                        of employees eligible for our remote-optional workplace.
                        (Apologies to our office manager.)
                      </a>
                    </div>
                  </div>
                </div>
                <div className=" col-10 col-lg-4">
                  <img src={image1} class="profileimage " />
                  <div class="card-body">
                    <h5 class="display-sm">∞</h5>
                    <div className="panel-text">
                      <a>
                        {" "}
                        custom caffeinated beverages and snacks available to you
                        at The Zebra HQ.
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row hidden-md-down justify-content-center">
                <div className="col-10 col-lg-4">
                  <div className="office-panel1">
                    <img src={profile} className="profileimage " />
                    <h3 className="display-sm panel-headline">60%</h3>
                    <p className="panel-text" style={{ fontFamily: "Prompt" }}>
                      of employees are active members of All tech platform.
                    </p>
                  </div>
                </div>
                <div className="col-10 col-lg-4">
                  <div className="office-panel2">
                    <img src={loc} className="profileimage " />
                    <h3 className="display-sm panel-headline">99%</h3>
                    <p className="panel-text" style={{ fontFamily: "Prompt" }}>
                      of employees eligible for our remote-optional workplace.
                      (Apologies to our office manager.)
                    </p>
                  </div>
                </div>
                <div className="col-10 col-lg-4">
                  <div className="office-panel3">
                    <img src={inf} className="profileimage " />
                    <h3 className="display-sm panel-headline">∞</h3>
                    <p className="panel-text" style={{ fontFamily: "Prompt" }}>
                      custom caffeinated beverages and snacks available to you
                      at The Kambgeeks.
                    </p>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
            </div>
          </div>
          <br />
        </div>
        {/* <div className="py-4 pt-lg-6 pb-lg-8 about-stats">
          <div className="container">
            <div class="row justify-content-center">
              <div className="col-9 col-md-10">
                <button className="btn slider-button slider-prev">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="384"
                    height="512"
                    viewBox="0 0 384 512"
                  >
                    <title>Previous</title>
                    <path
                      fill="currentColor"
                      d="M380.6 81.7c7.9 15.8 1.5 35-14.3 42.9L103.6 256l262.7 131.4c15.8 7.9 22.2 27.1 14.3 42.9s-27.1 22.2-42.9 14.3l-320-160C6.8 279.2 0 268.1 0 256s6.8-23.2 17.7-28.6l320-160c15.8-7.9 35-1.5 42.9 14.3z"
                    />
                  </svg>
                </button>
                <div
                  className="stats-slider slider"
                  data-slider-loop
                  data-slider-desktop
                  style="overflow: hidden; direction: ltr; cursor: -webkit-grab;"
                >
                  <div
                    style={{
                      width: "5340px",
                      transition: "all 400ms ease-in-out 0s",
                      transform: "translate3d(-3204px, 0px, 0px)",
                    }}
                  >
                    <div className="float: left; width: 20%;">
                      <div className="stats-slider-item">
                        <div className="stat-icon-wrapper mb-2 mb-lg-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <br />
        <br />
        <br />
        <h4
          className="h-heading"
          align="center"
          style={{ fontFamily: "Prompt" }}
        >
          There is no alternative to build tech Career
        </h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/ContactUs">
            <div
              className="getdemo-center"
              align="center"
              style={{ fontFamily: "Prompt" }}
            >
              Contact Us
            </div>
          </a>
        </div>
        <br />
        <br />
        <br />
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}
