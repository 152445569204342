import React from "react";
import Navbar from "./Navbar.js";
import Footer from "./footer.js";
import "../styles/footer.css";
import "../styles/terms.css";

import image1 from "../images/corp-img1.png";
import img1 from "../images/upskill1.png";
import shake from "../images/shake.jpg";
import img2 from "../images/learn.png";
import img3 from "../images/Assess.png";
import img4 from "../images/data.jpg";
import second from "../images/second.jpg";
import img from "../images/study.jpg";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import skill from "../images/skill.jpg";
import { Icon } from "@iconify/react";
import newsvg from "../images/newsvg.svg";
import inn from "../images/inn.svg";
import { Row, Col, Container } from "react-bootstrap";
import onen from "../images/onen1.jpg";
import a1 from "../images/a1.jpg";
import build from "../images/Build.png";
import a2 from "../images/a2.jpg";
import dev from "../images/dev.jpg";
import up from "../images/up.jpg";
import skill1 from "../images/skil.jpg";
function Terms() {
  return (
    <div>
      <Navbar />
      <div className="termscon">
        <br />

        <h2 className="h-headingcon" align="center">
          Updation of Terms
        </h2>
        <h2 className="h-headingsub">
          These ToS may be updated, amended, modified or revised by usfrom time
          to time. Each such updation or modification to the ToS will be
          communicated to you via email, through the Website or any other
          medium. Your continued use of the Kambgeeks Services after any such
          changes shall constitute your consent to such changes. We encourage
          you to refer to these ToS from time to time to ensure that you are
          aware of any additions, revisions, amendments or modifications that we
          may have made.
        </h2>

        <h2 className="h-headingup" align="left">
          Termination
        </h2>
        <h2 className="h-headingup1">
          Kamb geeks reserves the right to suspend or terminate your access to
          the Platform and the services with or without notice and to exercise
          any other remedy available under law, in cases where:
        </h2>
        <h2 className="h-headingup1">
          <li>You are in breach of any terms and conditions of this ToS;</li>

          <li>
            {" "}
            Kamb geeks is unable to verify or authenticate any information
            provided to Kamb geeks by you;{" "}
          </li>

          <li>
            {" "}
            Kamb geeks has reasonable grounds for suspecting any illegal,
            fraudulent or abusive activity on your part.
          </li>
        </h2>
        <h2 className="h-headingup1">
          Once temporarily suspended, indefinitely suspended or terminated, you
          may not continue to use the Platform under the same account, a
          different account or re-register under a new account. Kamb geeks may
          destroy and/or delete all the data and other information related to
          your account as per its discretion. All provisions of the ToS, which
          by their nature should survive termination, shall survive termination,
          including without limitation, warranty disclaimers, indemnity and
          limitations of liability.
        </h2>
        <h2 className="h-headingup" align="left">
          Use of your Information and User Data
        </h2>
        <h2 className="h-headingup1">
          All User Data that we collect for creating the account you will be
          become students and learner of the kambGeeks by consuming our free
          contents and materials . By regsister for membership you agreed to
          user your data for marketing and calling you to join our one to one
          mentor programs. after successfull placements we can user your data
          for sharing the growth stories in oor socilamedias.
        </h2>
        <h2 className="h-headingup" align="left">
          Content Ownership and Copyright Conditions of Access
        </h2>
        <h2 className="h-headingup1">
          The Platform and the Kambgeeks Content, including but not limited to
          all text, graphics, user interfaces, visual interfaces, sounds,
          artwork and computer code are owned and controlled by Kamb geeks
          and/or its licensors and the design, structure, selection, expression
          and arrangement of such content mentioned hereinabove is protected by
          copyright, patent and trademark laws, and other applicable laws on
          intellectual property rights. We reserve all rights, title, and
          ownership in and to the Platform and Kambgeeks Content. Kamb geeks’s
          logos, trademarks and service marks that may appear on the Platform,
          including but not limited to “Kamb geeks”, “Kamb geeks ”, “Kambgeeks
          Edge”, “Kambgeeks Academy” and “Kambgeeks Plus” (collectively, “Kamb
          geeks Marks”) are the property of Kamb geeks and are protected under
          applicable laws on intellectual property rights. Your copying,
          distributing, displaying, reproducing, recording, downloading,
          modifying, or in any other way using of the Kambgeeks Content in any
          manner not specified in these ToS, will be a violation of copyright
          and other intellectual property rights and Kamb geeks reserves its
          rights under applicable law accordingly.
        </h2>
        <h2 className="h-headingup" align="left">
          Reviews and Feedback
        </h2>
        <h2 className="h-headingup1">
          You agree that we may contact you through telephone, email, SMS, or
          any other electronic means of communication for the purpose of:
        </h2>
        <h2 className="h-headingup1">
          <li>
            Obtaining feedback in relation to the Platform or Kambgeeks
            Services; and/or and for our one to one mentorship program
          </li>

          <li>
            {" "}
            Resolving any complaints, information, or queries received from you
            regarding the same.
          </li>
        </h2>
        <h2 className="h-headingup1">
          Any feedback provided by you may be used by Kamb geeks without any
          restriction or obligation to compensate you, and we are under no
          obligation to keep such feedback confidential.
        </h2>
        <br />
      </div>
      <br />
      <Footer />
    </div>
  );
}
export default Terms;
