import React, { Component } from "react";
import Navbar from "./Navbar.js";
import Footer from "./footer.js";
import "../styles/footer.css";
import "../styles/navbar.css";
import "../styles/whyskill1.css";
import c2 from "../images/c2.png";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import c3 from "../images/c3.png";
import cloud from "../images/cloud1.png";
import c4 from "../images/c4.png";
import c5 from "../images/c5.png";
import c6 from "../images/c6.png";
import c7 from "../images/c7.png";
import skillnew from "../images/skillnew.png";
import image1 from "../images/corp-img1.png";
import img1 from "../images/upskill1.png";
import shake from "../images/shake.jpg";
import img2 from "../images/learn.png";
import img3 from "../images/Assess.png";
import img4 from "../images/data.jpg";
import second from "../images/second.jpg";
import img from "../images/study.jpg";
import Bounce from "react-reveal/Bounce";
import Fade from "react-reveal/Fade";
import skill from "../images/skill.jpg";
import { Icon } from "@iconify/react";
import newsvg from "../images/newsvg.svg";
import inn from "../images/inn.svg";
import { Row, Col, Container } from "react-bootstrap";
import onen from "../images/onen1.jpg";
import a1 from "../images/a1.jpg";
import build from "../images/Build.png";
import a2 from "../images/a2.jpg";
import dev from "../images/dev.jpg";
import up from "../images/up.jpg";
import skill1 from "../images/skil.jpg";
class Skillnew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qfa1: false,
      qfa2: false,
      qfa3: false,
      qfa4: false,
      activeButton: 1,
      qfa5: false,
      showPopup: false,
      name: "",
      mobileNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      cnferr: "",
      passwordMatch: true,
      firstname: "",
      lastname: "",
      mobileno: "",
      email: "",
      collegename: "",
      firstnameerr: "",
      lastnameerr: "",
      emailerr: "",
      mobilenoerr: "",

      ques5: false,
      ques4: false,
      ques3: false,
      ques2: false,
      ques1: false,

      servicesList: [
        "Research Training",
        "Industrial Training Programme",
        "On-Campus Training Programme",
        "Hospital Based Training Programme",
        " Laboratory based Training Programme",
        " Research Paper Publication Support - Scopus, SCI, SCI-E, WoS, ABDC, and UGC Listed Journals",
        " Indian & International Patent Support upto Grant (UK, Vietnam, Canadian, German, South African",
        " Copyright Grant Support",
        " Book Publication support",
        " Technical Training Certification Courses",
        " On-Board Training Programme",
        " Pre-Final Year and Final Year Projects",
        " Govt. Funded Project Support",
        " Expert Training Programme",
        " Award Programme and Conclave Support",
        " Book of Records Support",
        " Faculty Development Programme",
        " Workshop and Seminars",
        " Credit Courses and Curriculum Designing",
        " Entrepreneurship Cell Development",
        " Paper Writing & Publication Services",
        " NAAC, NIRF, AIIRA, NBA Support",
        " Placement Support",
        " Consultancy Linkage",
      ],
    };
    this.timer = null;
  }
  componentDidMount() {
    // Show popup after 2 minutes
    setTimeout(() => {
      this.setState({ showPopup: true });
    }, 10000); // 2 minutes in milliseconds
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClose = () => this.setState({ isOpen: false });
  handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, for example, send data to backend or perform other actions
    console.log("Submitted:", this.state);
    // Close the popup after submission
    this.setState({ showPopup: false });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, for example, send data to backend or perform other actions
    console.log("Submitted:", this.state);
    // Close the popup after submission
    this.setState({ showPopup: false });
  };
  componentWillUnmount() {
    // Clear the timer when the component unmounts
    clearTimeout(this.timer);
  }
  togglemodal5 = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };
  handleEmailChange(e) {
    const email = e.target.value;
    this.setState({ email }, () => {
      this.validateEmail();
    });
  }

  validateEmail() {
    const { email } = this.state;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      this.setState({ emailerr: "Invalid email address" });
    } else {
      this.setState({ emailerr: "" });
    }
  }
  handleMobileNoChange(e) {
    const mobileno = e.target.value;
    this.setState({ mobileno }, () => {
      this.validateMobileNo();
    });
  }
  validateMobileNo() {
    const { mobileno } = this.state;

    // You can define your own regex pattern based on your validation criteria
    // Example: 10-digit mobile number (for India)
    const mobileRegex = /^[0-9]{10}$/;

    if (!mobileRegex.test(mobileno)) {
      this.setState({ mobilenoerr: "Invalid mobile number" });
    } else {
      this.setState({ mobilenoerr: "" });
    }
  }
  handleClosePopup = () => {
    // Handle closing the pop-up
    this.setState({ showPopup: false });
  };

  handleSubmit = (userData) => {
    // Handle submitting user details
    console.log("User data:", userData);
    // Here, you can send the user data to your backend or perform any necessary actions
    this.setState({ showPopup: false });
  };

  render() {
    return (
      <div>
        <Navbar />
        <div
          style={{
            overflow: "hidden",
            backgroundImage:
              "linear-gradient(to right, #ff7686 5%, #911bed 20%, #2e38bf 30%, #090920 45%)",
            backgroundSize: "100% 100%",
            backgroundPosition: "left top",
          }}
        >
          <div
            className="totals"
            style={{
              overflow: "hidden",
              backgroundImage:
                "linear-gradient(to right, #ff7686 5%, #911bed 20%, #2e38bf 30%, #090920 45%)",
              backgroundSize: "100% 100%",
              backgroundPosition: "left top",
            }}
          >
            <br />
            <div className="content_acc">
              <Container>
                <Row
                  style={{
                    margin: "0px",
                  }}
                >
                  <Col md={6} xs={12} lg={6}>
                    <Fade bottom>
                      <div className="quote-align">
                        <h1 className="quote" style={{ fontFamily: "Prompt" }}>
                          Education <br /> that gets you industry-ready
                        </h1>
                        <br />
                        <h6
                          className="quote-desc"
                          style={{
                            color: "white",
                            paddingBottom: "5%",
                            fontWeight: "Prompt",
                          }}
                        >
                          College & bookish knowledge doesn't prepare you for
                          real-world and modern careers.Kambgeeks, co-creates
                          programs with top industry professionals who can help
                          you get years ahead in your career.
                        </h6>
                        <br />
                        <a
                          data-text="Get Started"
                          className="quo"
                          style={{ alignSelf: "center", fontWeight: "Prompt" }}
                          href="ContactUs"
                        >
                          Get Started
                        </a>
                      </div>
                    </Fade>
                  </Col>
                  <Col md={6} xs={12} lg={6}>
                    <Bounce>
                      <img src={cloud} className="pic_acc" />
                    </Bounce>
                  </Col>
                </Row>
              </Container>
            </div>
            <br />
            <br />

            <div className="seconda">
              <br />
              <h1
                className="h-heading"
                style={{
                  color: "white",
                  fontWeight: "900",
                  textAlign: "center",
                }}
              >
                Kamb Geeks
              </h1>
              <br />
              <br />
              <div className="academy-container">
                <div className="academy-1">
                  <Row>
                    <Col>
                      <p className="academy-head highlight1">1K+</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="academy-content">Total Students</p>
                    </Col>
                  </Row>
                </div>
                <div className="academy-2">
                  <Row>
                    <Col>
                      <p className="academy-head highlight2">10+</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="academy-content">Programs</p>
                    </Col>
                  </Row>
                </div>
                <div className="academy-3">
                  <Row>
                    <Col>
                      <p className="academy-head highlight3">8.1/10</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="academy-content">Average Rating</p>
                    </Col>
                  </Row>
                </div>
                <div className="academy-4">
                  <Row>
                    <Col>
                      <p className="academy-head highlight4">70+</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="academy-content">Institutions</p>
                    </Col>
                  </Row>
                </div>
              </div>
              <br />
              <div className="academy-container1">
                <div className="academy-1">
                  <Row>
                    <Col>
                      <p className="academy-head1 highlight5">200+</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="academy-content">Hiring Patners</p>
                    </Col>
                  </Row>
                </div>
                <div className="academy-2">
                  <Row>
                    <Col>
                      <p className="academy-head1 highlight6">10 LPA</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="academy-content">Average CTC</p>
                    </Col>
                  </Row>
                </div>
                <div className="academy-3">
                  <Row>
                    <Col>
                      <p className="academy-head1 highlight7">32-35 LPA</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="academy-content">Highest CTC</p>
                    </Col>
                  </Row>
                </div>
              </div>
              <br />
              <br />
            </div>

            <div style={{ backgroundColor: "white" }}>
              <br />
              <br />
              <h1
                className="h-heading"
                style={{
                  color: "white",
                  fontWeight: "900",
                  color: "black",
                  textAlign: "center",
                }}
              >
                Skills
              </h1>
              <br />
              <div className="ace-container1">
                <div>
                  <Row>
                    <Col>
                      <Icon
                        className="ace-icon"
                        icon="mdi:marker-tick"
                        color="#1d60e3"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="ae-head1" style={{ fontFamily: "Prompt" }}>
                        Upskill & reskill
                        <br />
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="h-content" style={{ fontFamily: "Prompt" }}>
                        Empower your to produce key business outcomes by making
                        upskilling and re-skilling as easy as powering up your
                        career technically. Tap into the power of curated
                        learning paths to guide teams through the exact skills
                        they need to progress from novice to guru across a
                        variety of tech skills.
                      </p>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col>
                      <Icon
                        className="ace-icon"
                        icon="clarity:assign-user-solid"
                        color="#1d60e3"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="ae-head1" style={{ fontFamily: "Prompt" }}>
                        Learn by doing
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="h-content" style={{ fontFamily: "Prompt" }}>
                        More than 100+ hands-on enable technologists to develop
                        stronger proficiencies in specific skills or
                        technologies through step-by-step instruction, practice
                        exercises, and projects. In fact, Kamb Geeks is the only
                        upskilling platform that offers across all technology
                        domains learning. We’ve totally got you covered.
                      </p>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col>
                      <Icon
                        className="ace-icon"
                        icon="ic:outline-code"
                        color="#1d60e3"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="ae-head1" style={{ fontFamily: "Prompt" }}>
                        Assess & evaluate
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="h-content" style={{ fontFamily: "Prompt" }}>
                        See where your skills stand with skill assessments. With
                        Kamb Geeks, you can benchmark your tech skills, identify
                        knowledge gaps and strengths—or find out where you stand
                        yourself. You’ll also get tailored learning
                        recommendations to help your career level up. Then
                        celebrate the milestones as your advances toward
                        mastery.
                      </p>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col>
                      <Icon
                        className="ace-icon"
                        icon="mdi:alarm-clock-check"
                        color="#1d60e3"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="ae-head1" style={{ fontFamily: "Prompt" }}>
                        Dive into the data
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="h-content" style={{ fontFamily: "Prompt" }}>
                        Gain visibility into who has what skills and enable
                        technologists to learn the skills most related to your
                        top priorities. Then track progress with insights into
                        skill development over time to ensure your org is
                        prepared to deliver on key initiatives—all with
                        quantified, assessment-backed data you can trust.
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>

          <section className="how-it-works-section pb-5">
            <div className="container">
              <div class="container">
                <div class="row">
                  <div class="col-md-4">
                    <div class="apply-company-and-developers text-center">
                      <br />
                      <a class="btn btn-type-div-border-cst" href="ContactUs">
                        Save a Month on hiring!
                      </a>
                      <br />
                      <h1>
                        For
                        <span class="cst-secondary-color"> Company</span>
                      </h1>
                      <p>
                        Hire developers locally, offshore, or
                        <br />
                        nearshore, all vetted by AI and
                        <br />
                        handpicked by humans.
                      </p>
                      <a class="btn hire-software-engineer" href="ContactUs">
                        Hire Software Engineers
                      </a>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="apply-company-and-developers text-center">
                      <br />
                      <a class="btn btn-type-div-border-cst" href="ContactUs">
                        Learn What Actually Need
                      </a>
                      <br />
                      <h1>
                        For
                        <span class="cst-secondary-color"> Community</span>
                      </h1>
                      <p>
                        Supercharge Your Learning with Kamb Geeks
                        <br />
                        Build Real-World Skills, Network with Peers,
                        <br />
                        and Unlock Career Opportunities.
                      </p>
                      <a class="btn hire-software-engineer" href="ContactUs">
                        Join Community
                      </a>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="border-line-col-cst text-center">
                      <br /> <br />
                      <button class="btn btn-type-div-border-cst">
                        You deserve a better pay.
                      </button>
                      <h1>
                        For
                        <span class="cst-secondary-color"> Talents</span>
                      </h1>
                      <p>
                        Traditional job portals are dead.
                        <br />
                        Let KambGeeks match you with high-paying
                        <br />
                        full time tech jobs in USA.
                      </p>
                      <a class="btn hire-software-engineer" href="ContactUs">
                        Apply for jobs
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
          <br />
          <br />
          <h4
            className="h-heading"
            align="center"
            style={{ fontFamily: "Prompt" }}
          >
            There is no alternative to build tech Career
          </h4>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a href="/ContactUs">
              <div
                className="getdemo-center"
                align="center"
                style={{ fontFamily: "Prompt" }}
              >
                Contact Us
              </div>
              <br />
              <br /> <br />
            </a>
          </div>
        </div>
        {this.state.showPopup && (
          <Modal isOpen={this.togglemodal5} centered>
            <ModalHeader toggle={this.togglemodal5}>
              <p style={{ fontSize: "16px", color: "black" }}>
                Register with Kambgeeks to get 10% off on any course!
              </p>
            </ModalHeader>
            <ModalBody>
              <div className=" col-sm-12 col-12 ">
                <div className="container">
                  <form className="form1">
                    <div className="input_box">
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        value={this.state.firstname}
                        onChange={(e) =>
                          this.setState({
                            firstname: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input_box">
                      <input
                        type="text"
                        placeholder="Enter Your Email"
                        value={this.state.email}
                        onChange={(e) => this.handleEmailChange(e)}
                      />
                    </div>
                    <div className="input_box">
                      <input
                        type="number"
                        placeholder="Enter Mobile No"
                        value={this.state.mobileno}
                        onChange={(e) => this.handleMobileNoChange(e)}
                      />
                    </div>
                    <button
                      style={{
                        align: "center",
                        fontFamily: "Prompt",
                        color: "white",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Save
                    </button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <button
                      style={{
                        align: "center",
                        fontFamily: "Prompt",
                        color: "white",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Clear
                    </button>
                  </form>
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
        <Footer />
      </div>
    );
  }
}
export default Skillnew;
