import React, { Component } from "react";
import Navbar from "./Navbar.js";
import "../styles/ContactUs.css";
// import image1 from "../images/Contact.jpg";
import contactnew from "../images/C1.png";
import { Row, Col, Container } from "react-bootstrap";
import img from "../images/login.jpg";
import newphoto from "../images/newimg3.png";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import question from "../images/Questions.png";
import axios from "axios";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { ip } from "../Api.js";
import Footer from "./footer";
import dev from "../images/dev.jpg";
import cont from "../images/Cont.jpg";
import iot from "../images/iot1.svg";
import { Icon } from "@iconify/react";
import image1 from "../images/kam1.jpeg";
import image2 from "../images/devops.png";
import image3 from "../images/shield.png";
import image4 from "../images/human-brain.png";
import { NavLink } from "react-router-dom";
import image5 from "../images/cloud.png";
import image6 from "../images/settings.png";
import { Link } from "react-router-dom";
import p1 from "../images/p1.jpeg";
import p2 from "../images/p2.jpeg";
import p3 from "../images/p3.jpeg";
import p4 from "../images/p4.jpeg";
import p5 from "../images/p5.jpeg";
import p6 from "../images/p6.jpeg";
const options = [
  { value: "Student", label: "Student" },
  { value: "WorkingProfessional", label: "Working Professional" },
  { value: "NonTech", label: "Non Technical" },
];
export default class Newcontact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qfa1: false,
      qfa2: false,
      showPopup: false,
      name: "",
      categorylist: [],
      categoryerr: "",
      category: "",
      mobileNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      cnferr: "",
      passwordMatch: true,
      firstname: "",
      lastname: "",
      mobileno: "",
      email: "",
      collegename: "",
      firstnameerr: "",
      lastnameerr: "",
      emailerr: "",
      mobilenoerr: "",
      qfa3: false,
      qfa4: false,
      qfa5: false,
      ques5: false,
      ques4: false,
      ques3: false,
      ques2: false,
      ques1: false,
      password: "",
      confirmPassword: "",
      cnferr: "",
      passwordMatch: true,
      firstname: "",
      lastname: "",
      mobileno: "",
      email: "",
      collegename: "",
      firstnameerr: "",
      lastnameerr: "",
      emailerr: "",
      mobilenoerr: "",
      images: [p1, p2, p3, p4, p5, p6],
    };
  }
  componentDidMount() {
    // Show popup after 2 minutes
    setTimeout(() => {
      this.setState({ showPopup: true });
    }, 10000); // 2 minutes in milliseconds
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClose = () => this.setState({ isOpen: false });
  handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, for example, send data to backend or perform other actions
    console.log("Submitted:", this.state);
    // Close the popup after submission
    this.setState({ showPopup: false });
  };
  componentWillUnmount() {
    // Clear the timer when the component unmounts
    clearTimeout(this.timer);
  }
  togglemodal5 = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };
  handleEmailChange(e) {
    const email = e.target.value;
    this.setState({ email }, () => {
      this.validateEmail();
    });
  }

  validateEmail() {
    const { email } = this.state;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      this.setState({ emailerr: "Invalid email address" });
    } else {
      this.setState({ emailerr: "" });
    }
  }
  handleMobileNoChange(e) {
    const mobileno = e.target.value;
    this.setState({ mobileno }, () => {
      this.validateMobileNo();
    });
  }
  validateMobileNo() {
    const { mobileno } = this.state;

    // You can define your own regex pattern based on your validation criteria
    // Example: 10-digit mobile number (for India)
    const mobileRegex = /^[0-9]{10}$/;

    if (!mobileRegex.test(mobileno)) {
      this.setState({ mobilenoerr: "Invalid mobile number" });
    } else {
      this.setState({ mobilenoerr: "" });
    }
  }
  handleClosePopup = () => {
    // Handle closing the pop-up
    this.setState({ showPopup: false });
  };

  handleSubmit = (userData) => {
    // Handle submitting user details
    console.log("User data:", userData);
    // Here, you can send the user data to your backend or perform any necessary actions
    this.setState({ showPopup: false });
  };
  toggle = () => {
    this.setState({ qfa1: !this.state.qfa1 });
  };
  toggle1 = () => {
    this.setState({ qfa2: !this.state.qfa2 });
  };
  toggle2 = () => {
    this.setState({ qfa3: !this.state.qfa3 });
  };
  toggle3 = () => {
    this.setState({ qfa4: !this.state.qfa4 });
  };
  toggle4 = () => {
    this.setState({ qfa5: !this.state.qfa5 });
  };
  validate = () => {
    let cnferr = "";
    let codeErr = "";
    let firstnameerr = "";
    let lastnameerr = "";
    let emailerr = "";
    let mobilenoerr = "";
    let roleErr = "";
    let hasErr = true;
    // if (this.state.code === "") {
    //   codeErr = "This field is required";
    //   this.setState({ codeErr });
    //   console.log("code Err", this.state.codeErr, hasErr);
    //   hasErr = false;
    // }

    if (this.state.mobileno === "") {
      mobilenoerr = "This field is required";
      this.setState({ mobilenoerr });
      console.log("phone Err", this.state.mobilenoerr, hasErr);
      hasErr = false;
    }
    if (this.state.firstname === "") {
      firstnameerr = "This field is required";
      this.setState({ firstnameerr });
      console.log("firstname Err", this.state.firstnameerr, hasErr);
      hasErr = false;
    }
    if (this.state.lastname === "") {
      lastnameerr = "This field is required";
      this.setState({ lastnameerr });
      console.log("lastname Err", this.state.lastnameerr, hasErr);
      hasErr = false;
    }
    if (this.state.email === "") {
      emailerr = "This field is required";
      this.setState({ emailerr });
      console.log("email Err", this.state.emailerr, hasErr);
      hasErr = false;
    }
    return hasErr;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      let value;

      value = {
        email: this.state.email,
        first_name: this.state.firstname,
        last_name: this.state.lastname,
        college_name: this.state.collegename,
        phoneno: this.state.mobileno,
        country_code: "91",
        category: this.state.category,
      };

      // eslint-disable-next-line
      const response = axios
        .post(`${ip}/auth/register`, value)
        .then((response) => {
          if (response) {
            alert("Registered Successfully");
            this.reset();
          }
        })

        .catch((err) => {
          alert("Please Enter Valid details");
          this.setState({});
        });
    }
  };
  reset = () => {
    this.setState({
      firstnameerr: "",
      firstname: "",
      lastname: "",
      lastnameerr: "",
      mobileno: "",
      mobilenoerr: "",
      email: "",
      category: "",
      categoryerr: "",
      emailerr: "",
      collegename: "",
    });
  };
  handleEmailChange(e) {
    const email = e.target.value;
    this.setState({ email }, () => {
      this.validateEmail();
    });
  }

  validateEmail() {
    const { email } = this.state;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      this.setState({ emailerr: "Invalid email address" });
    } else {
      this.setState({ emailerr: "" });
    }
  }
  handleMobileNoChange(e) {
    const mobileno = e.target.value;
    this.setState({ mobileno }, () => {
      this.validateMobileNo();
    });
  }
  validateMobileNo() {
    const { mobileno } = this.state;

    // You can define your own regex pattern based on your validation criteria
    // Example: 10-digit mobile number (for India)
    const mobileRegex = /^[0-9]{10}$/;

    if (!mobileRegex.test(mobileno)) {
      this.setState({ mobilenoerr: "Invalid mobile number" });
    } else {
      this.setState({ mobilenoerr: "" });
    }
  }
  render() {
    return (
      <div
        style={{
          overflow: "hidden",
          backgroundImage:
            "linear-gradient(to right, #ff7686 5%, #911bed 20%, #2e38bf 30%, #090920 45%)",
          backgroundSize: "100% 100%",
          backgroundPosition: "left top",
        }}
      >
        <div>
          <nav class="navbar navbar-expand-lg bg-body-tertiary " id="navbar">
            <div class="container-fluid">
              <a class="navbar-brand" href="#">
                <img id="logo" src={image1} alt="logo"></img>
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    {/* <a class="nav-link active" aria-current="page" href="#">
                Home
              </a> */}
                    <a
                      className="nav-link"
                      href="/"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Home
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      className="nav-link"
                      href="/Skill"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Why skills&nbsp;?
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/Corporates"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Corporates
                    </a>
                  </li>
                  <div className="courseonly">
                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="/technologies"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Courses
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="tag" src={image2}></img> &nbsp;
                              Software dev
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Database
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Game development
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Mobile development
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Programming languages
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Web Development
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="shield" src={image3}></img>&nbsp;
                              Security
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Certification
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Governance,risk,& compliance
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security architecture & engineering
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security operations
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Security testing
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="brain" src={image4}></img>&nbsp;
                              Machine learning
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Big data
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Business intelligence
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Data visualization
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Databases
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Languages & libraries
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Machine learning
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="cloud" src={image5}></img> &nbsp;
                              Cloud
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Cloud architecture & design
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Cloud platforms
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Salesforce CRM
                            </a>
                          </li>
                        </ul>
                        <ul class="list-group">
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              <img class="shield" src={image3}></img>&nbsp; IT
                              Ops
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Client operating systems
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Collaboration platforms
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Configuration management
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Containers
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              IT automation
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Network architecture
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="/technologies"
                              style={{ fontFamily: "Prompt" }}
                            >
                              Virtualization
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </div>
                  <div className="courseonly1">
                    <li class="nav-item dropdown dropdown-mega position-static">
                      <a
                        href="/technologies"
                        class="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="Outside"
                        style={{ fontFamily: "Prompt" }}
                      >
                        Courses
                      </a>

                      <div class="dropdown-menu shadow container-fluid col-11 w-70 mx-5">
                        <div class="mega-content px-md-4">
                          <div class="conatiner-fluid">
                            <div class="row">
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-3">
                                  <img class="tag" src={image2}></img>
                                  <h5
                                    class="drop-title my-2 mx-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Software dev
                                  </h5>
                                </div>
                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Database
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Game development
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Mobile development
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Programming languages
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="/technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Web development
                                  </a>
                                </div>
                                <div class="logs mb-3">
                                  <img class="shield" src={image3}></img>
                                  <h5
                                    class="drop-title my-3 mt-4 mx-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Certification
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Governance,risk,& compliance
                                  </a>
                                  <a
                                    class="dropdown-item "
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security architecture & engineering
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security operations
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Security testing
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-2">
                                  <img class="brain" src={image4}></img>
                                  <h5
                                    class="drop-title mt-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Data & machine learning
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Big data
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Business intelligence
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Data visualization
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Databases
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Languages & libraries
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Machine learning
                                  </a>
                                </div>
                                <div class="logs mb-3">
                                  <img class="cloud" src={image5}></img>
                                  <h5
                                    class="drop-title my-3 px-2"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud architecture & design
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Cloud platforms
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Salesforce CRM
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-12 py-4 px-xl-5 px-4 mx-5">
                                <div class="logs mb-3">
                                  <img class="setting" src={image6}></img>
                                  <h5
                                    class="drop-title my-2 mx-1"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    IT Ops
                                  </h5>
                                </div>

                                <div class="list-group">
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Client operating systems
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Collaboration platforms
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Configuration management
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Containers
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    IT automation
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Network architecture
                                  </a>
                                  <a
                                    class="dropdown-item"
                                    href="technologies"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    Virtualization
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                  {/* <li className="nav-item">
                    <a
                      className="nav-link"
                      href="about"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Company
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/ContactUs"
                      style={{ fontFamily: "Prompt" }}
                    >
                      ContactUs&nbsp;
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/Register"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Free Register&nbsp;
                    </a>
                  </li>
                </ul>
                {/* <form class="d-flex" role="search">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button class="btn btn-outline-success" type="submit">
              Search
            </button>
          </form> */}
              </div>
            </div>
          </nav>
        </div>
        <br />
        <br />
        <div className="content_acc">
          <Container>
            <Row
              style={{
                margin: "0px",
              }}
            >
              <Col md={6} xs={12} lg={6}>
                <Fade bottom>
                  <div className="quote-align">
                    <h1 className="quotenew">
                      Master In-Demand Tech & Data Skills at KambGeeks
                    </h1>
                    <br />
                    <h6
                      className="quote-desc"
                      style={{ color: "white", paddingBottom: "5%" }}
                    >
                      We offer industry-focused courses led by experts to help
                      you become job-ready in data science, programming, and
                      more.
                      {/* 100+ our learners now working in top
                      product base companies. */}
                    </h6>
                    <br />
                    <a
                      data-text="  Explore courses"
                      className="quo"
                      style={{ alignSelf: "center" }}
                      href="/ContactUs"
                    >
                      Explore Programs
                    </a>
                  </div>
                </Fade>
              </Col>
              <Col md={6} xs={12} lg={6}>
                <Bounce>
                  <img src={newphoto} className="pic_accofnew" />
                </Bounce>
              </Col>
            </Row>
          </Container>
        </div>
        <br />
        <br />
        {this.state.showPopup && (
          <Modal isOpen={this.togglemodal5} centered>
            <ModalHeader toggle={this.togglemodal5}>
              <p style={{ fontSize: "16px", color: "black" }}>
                Register with Kambgeeks to get 10% off on any course!
              </p>
            </ModalHeader>
            <ModalBody>
              <div className=" col-sm-12 col-12 ">
                <div className="container">
                  <form className="form1">
                    <div className="input_box">
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        value={this.state.firstname}
                        onChange={(e) =>
                          this.setState({
                            firstname: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input_box">
                      <input
                        type="text"
                        placeholder="Enter Your Email"
                        value={this.state.email}
                        onChange={(e) => this.handleEmailChange(e)}
                      />
                    </div>
                    <div className="input_box">
                      <input
                        type="number"
                        placeholder="Enter Mobile No"
                        value={this.state.mobileno}
                        onChange={(e) => this.handleMobileNoChange(e)}
                      />
                    </div>
                    <button
                      style={{
                        align: "center",
                        fontFamily: "Prompt",
                        color: "white",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Save
                    </button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <button
                      style={{
                        align: "center",
                        fontFamily: "Prompt",
                        color: "white",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Clear
                    </button>
                  </form>
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
        <div className="seconda">
          <br />
          <h1
            className="h-heading"
            style={{
              color: "white",
              fontWeight: "900",
              textAlign: "center",
            }}
          >
            Kamb Geeks
          </h1>
          <br />
          <br />
          <div className="academy-container">
            <div className="academy-1">
              <Row>
                <Col>
                  <p className="academy-head highlight1">1K+</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="academy-content">Total Students</p>
                </Col>
              </Row>
            </div>
            <div className="academy-2">
              <Row>
                <Col>
                  <p className="academy-head highlight2">10+</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="academy-content">Programs</p>
                </Col>
              </Row>
            </div>
            <div className="academy-3">
              <Row>
                <Col>
                  <p className="academy-head highlight3">8.1/10</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="academy-content">Average Rating</p>
                </Col>
              </Row>
            </div>
            <div className="academy-4">
              <Row>
                <Col>
                  <p className="academy-head highlight4">70+</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="academy-content">Institutions</p>
                </Col>
              </Row>
            </div>
          </div>
          <br />
          <div className="academy-container1">
            <div className="academy-1">
              <Row>
                <Col>
                  <p className="academy-head1 highlight5">200+</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="academy-content">Hiring Patners</p>
                </Col>
              </Row>
            </div>
            <div className="academy-2">
              <Row>
                <Col>
                  <p className="academy-head1 highlight6">10 LPA</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="academy-content">Average CTC</p>
                </Col>
              </Row>
            </div>
            <div className="academy-3">
              <Row>
                <Col>
                  <p className="academy-head1 highlight7">32-35 LPA</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="academy-content">Highest CTC</p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div
          className="topcompany"
          style={{
            color: "white",
            textAlign: "center",
            fontWeight: "800",
            fontSize: "38px",
            fontFamily: "Prompt",
          }}
        >
          Contact Us
          <br />
        </div>
        <div className="corp2">
          <div className="container-fluid col-10">
            <Row>
              <br />

              <div className="col-lg-6 col-mg-6 col-12 column1">
                <div className="corpc1 ">
                  <p
                    className="skillnew"
                    style={{ fontFamily: "Prompt", color: "white" }}
                  >
                    <br />
                    <br />
                    Get Skills, Get Hired
                    <br /> <br />
                    Contact Us Today!
                    <br />
                    <br />
                    {/* our learners now working in top product base companies. */}
                  </p>
                  <br />
                </div>
              </div>
              {/* <div className="col-lg-5 col-mg-6 col-12 column2">
                <img src={contactnew} className="saleimg21 " />
              </div> */}
            </Row>
          </div>
        </div>
        <div className="totalcontact1">
          <div className="container">
            <div className="row" style={{ margin: "10px" }}>
              <h4
                className="h-headingc"
                style={{ align: "Left", fontFamily: "Prompt", color: "white" }}
              >
                ContactUs
              </h4>
              <div className="col-md-6 col-sm-12 col-12">
                <p
                  className="contone"
                  style={{ fontFamily: "Prompt", color: "white" }}
                >
                  We're here to help you unlock your tech potential and land
                  your dream career.
                  <br />
                  If you need our help with your user account, have questions
                  about how to use the platform or are experiencing technical
                  difficulties,please do not hesitate to contact us.
                </p>
                <br />
                <br />

                <br />
                <div className="col-md-4 col-sm-12 col-12">
                  {/* <div className="second1">
                    <br></br>
                    <Icon icon="ic:round-phone" className="iconph" />

                    <a className="phtext">&nbsp;&nbsp;+91-7017899611</a>
                  </div> */}

                  <div className="second2">
                    <br></br>
                    <Icon
                      icon="ic:baseline-mail"
                      className="iconph"
                      style={{ color: "white" }}
                    />

                    <a
                      className="phtext"
                      style={{ fontFamily: "Prompt", color: "white" }}
                    >
                      &nbsp;&nbsp;support@kambgeeks.com
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5 col-sm-12 col-12 newtop1">
                <div className="container">
                  <form className="form">
                    <div className="input_box">
                      <label
                        className="required"
                        style={{ fontFamily: "Prompt", color: "white" }}
                      >
                        First Name
                      </label>
                      <div style={{ fontSize: 12, color: "red" }}>
                        {this.state.firstnameerr}
                      </div>
                      <input
                        type="text"
                        placeholder="Enter First Name"
                        required
                        value={this.state.firstname}
                        onChange={(e) =>
                          this.setState({
                            firstname: e.target.value,
                            firstnameerr: "",
                          })
                        }
                      />
                    </div>
                    <div className="input_box">
                      <label
                        className="required"
                        style={{ fontFamily: "Prompt", color: "white" }}
                      >
                        Last Name
                      </label>
                      <div style={{ fontSize: 12, color: "red" }}>
                        {this.state.lastnameerr}
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Last Name"
                        required
                        value={this.state.lastname}
                        onChange={(e) =>
                          this.setState({
                            lastname: e.target.value,
                            lastnameerr: "",
                          })
                        }
                      />
                    </div>

                    <div className="input_box">
                      <label
                        className="required"
                        style={{ fontFamily: "Prompt", color: "white" }}
                      >
                        Email
                      </label>
                      <div style={{ fontSize: 12, color: "red" }}>
                        {this.state.emailerr}
                      </div>
                      <input
                        type="text"
                        placeholder="Enter Email Address"
                        required
                        value={this.state.email}
                        onChange={(e) => this.handleEmailChange(e)}
                      />
                    </div>

                    <div className="input_box">
                      <label
                        className="required"
                        style={{ fontFamily: "Prompt", color: "white" }}
                      >
                        Mobile No
                      </label>{" "}
                      <div style={{ fontSize: 12, color: "red" }}>
                        {this.state.mobilenoerr}
                      </div>
                      <input
                        type="number"
                        placeholder="Enter Mobile No"
                        required
                        value={this.state.mobileno}
                        onChange={(e) => this.handleMobileNoChange(e)}
                      />
                    </div>
                    <div className="input_box">
                      <label style={{ fontFamily: "Prompt", color: "white" }}>
                        College Name
                      </label>
                      <input
                        type="text"
                        placeholder="Enter College Name"
                        value={this.state.collegename}
                        onChange={(e) =>
                          this.setState({
                            collegename: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input_box">
                      <label style={{ fontFamily: "Prompt", color: "white" }}>
                        Category
                      </label>
                      <div style={{ fontSize: 12, color: "red" }}>
                        {this.state.categoryerr}
                      </div>
                      <select
                        // className="input-text"
                        value={this.state.category}
                        onChange={(e) => {
                          this.setState({
                            category: e.target.value,
                            categoryerr: "",
                          });
                        }}
                      >
                        <option value="" hidden id="select-placeholder">
                          Select Category...
                        </option>
                        {options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <button
                      style={{
                        align: "right",
                        fontFamily: "Prompt",
                        color: "white",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>

        <br />
        <section className="how-it-works-section pb-5">
          <div className="container">
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <div class="apply-company-and-developers text-center">
                    <br />
                    <a class="btn btn-type-div-border-cst" href="ContactUs">
                      Save 6 weeks on hiring!
                    </a>
                    <br />
                    <h1>
                      For
                      <span class="cst-secondary-color"> Company</span>
                    </h1>
                    <p>
                      Hire developers locally, offshore, or
                      <br />
                      nearshore, all vetted by Generative AI and
                      <br />
                      handpicked by humans.
                    </p>
                    <a class="btn hire-software-engineer" href="ContactUs">
                      Hire Software Engineers
                    </a>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="border-line-col-cst text-center">
                    <br /> <br />
                    <button class="btn btn-type-div-border-cst">
                      You deserve a better pay.
                    </button>
                    <h1>
                      For
                      <span class="cst-secondary-color"> Talents</span>
                    </h1>
                    <p>
                      Traditional job portals are dead.
                      <br />
                      Let KambGeeks match you with high-paying
                      <br />
                      full time tech jobs in USA.
                    </p>
                    <a class="btn hire-software-engineer" href="ContactUs">
                      Apply for jobs
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        <h4
          className="h-heading"
          align="center"
          style={{ fontFamily: "Prompt" }}
        >
          There is no alternative to build tech Career
        </h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/ContactUs">
            <div
              className="getdemo-center"
              align="center"
              style={{ fontFamily: "Prompt" }}
            >
              Contact Us
            </div>
          </a>
        </div>
        <br />
        <br />
        <Footer />
      </div>
    );
  }
}
