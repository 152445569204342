import React from "react";
import "../styles/ForgotPassword.css";

function ForgotPassword() {
  return (
    <div class="ForgotPassword">
      <h1 class="forgot-txt">
        <b>Forgot Password</b>
      </h1>
      <p class="forgot-p">
        Enter your email address below to reset your password.
      </p>
      <form>
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="email"
            required
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="new-password"
            id="new-password1"
            class="form-control mb-3"
            minLength="8"
            required
          ></input>
        </div>
        <div>
          <input
            type="password"
            placeholder="Re-Enter new-password"
            id="new-password2"
            class="form-control mb-4"
            minLength="8"
            required
          ></input>
        </div>
        <button type="submit" class="btn btn-success forgot-btn">
          Reset Password
        </button>
      </form>

      <p class="forgot-p text-center mt-3">
        Remember your password? <a href="/SignIn">Signin</a>
      </p>
    </div>
  );
}

export default ForgotPassword;
