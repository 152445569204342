// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.termscon {
  margin-top: 7%;
}
.h-headingcon {
  font-size: 30px;
  line-height: 60px;
  padding-top: 20px;
  font-weight: 600;
  padding-bottom: 20px;
}
.h-headingsub {
  font-size: 17px;
  line-height: 31px;
  font-weight: 400;
  text-align: justify;

  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 20px;
}
.h-headingup {
  font-size: 30px;
  line-height: 60px;
  font-weight: 600;
  padding-top: 20px;
  padding-left: 5%;
  padding-bottom: 20px;
}
.h-headingup1 {
  font-size: 17px;
  line-height: 31px;
  font-weight: 400;
  text-align: justify;

  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 20px;
}
@media screen and (max-width: 900px) {
  .h-headingup {
    font-size: 25px;
    padding-left: 5%;

    line-height: 32px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/terms.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;;EAEnB,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;;EAEnB,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE;IACE,eAAe;IACf,gBAAgB;;IAEhB,iBAAiB;EACnB;AACF","sourcesContent":[".termscon {\n  margin-top: 7%;\n}\n.h-headingcon {\n  font-size: 30px;\n  line-height: 60px;\n  padding-top: 20px;\n  font-weight: 600;\n  padding-bottom: 20px;\n}\n.h-headingsub {\n  font-size: 17px;\n  line-height: 31px;\n  font-weight: 400;\n  text-align: justify;\n\n  padding-left: 5%;\n  padding-right: 5%;\n  padding-bottom: 20px;\n}\n.h-headingup {\n  font-size: 30px;\n  line-height: 60px;\n  font-weight: 600;\n  padding-top: 20px;\n  padding-left: 5%;\n  padding-bottom: 20px;\n}\n.h-headingup1 {\n  font-size: 17px;\n  line-height: 31px;\n  font-weight: 400;\n  text-align: justify;\n\n  padding-left: 5%;\n  padding-right: 5%;\n  padding-bottom: 20px;\n}\n@media screen and (max-width: 900px) {\n  .h-headingup {\n    font-size: 25px;\n    padding-left: 5%;\n\n    line-height: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
