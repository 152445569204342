import React, { Component, useState, useEffect } from "react";
// import img1desktop from "../Images/logo.png";
// import img1mobile from "../Images/mobile_logo.png";
// import img1mobile2 from "../Images/mobile_logo2.png";
// import img2 from "../Images/logojoint.png";
import "../styles/Newnav.css";
import { Row, Col } from "react-bootstrap";

import { Icon } from "@iconify/react";
import image1 from "../images/kam1.jpeg";
import image2 from "../images/devops.png";
import image3 from "../images/shield.png";
import image4 from "../images/human-brain.png";
import { NavLink } from "react-router-dom";
import image5 from "../images/cloud.png";
import image6 from "../images/settings.png";

class NewNavbar extends Component {
  /*for menu icon */
  state = {
    clicked: false,
    backgroundColor: "#03081b",
    color: "white",
    isDropdownOpen: false,
    borderColor: "white",
  };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    const { backgroundColor, color, borderColor, currentImage } = this.state;

    return (
      <nav
        className="navbar navbar-expand-lg"
        id="navbar"
        style={{ backgroundColor: "#516BEB" }}
      >
        <div class="container-fluid">
          <a class="navbar-brand" href="#">
            Navbar
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Link
                </a>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="/technologies"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Dropdown
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <ul>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Software dev
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Database
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Game development
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Mobile development
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Programming languages
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Web Development
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Mega Menu Link
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Mega Menu Link
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Mega Menu Link
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Something else here
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Mega Menu Link
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Mega Menu Link
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Mega Menu Link
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/technologies">
                        Mega Menu Link
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a class="nav-link disabled">Disabled</a>
              </li>
            </ul>
            <form class="d-flex">
              <input
                class="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button class="btn btn-outline-light" type="submit">
                Search
              </button>
            </form>
          </div>
        </div>
      </nav>
    );
  }
}

export default NewNavbar;
