import React, { Component } from "react";
import Navbar from "./Navbar.js";
import "../styles/corporate.css";
import Footer from "./footer.js";
import "../styles/footer.css";
import axios from "axios";
import "../styles/navbar.css";
import grad from "../images/grad.png";
import { ip } from "../Api";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import talent from "../images/talent.png";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import newphoto from "../images/newimg3.png";
import image1 from "../images/corp-img1.png";
import image2 from "../images/product1.png";
import gra from "../images/gra.jpg";
import deve from "../images/de.jpg";
import c8 from "../images/c8.png";
import train from "../images/train.png";
import pipe from "../images/pipe.jpg";
import image3 from "../images/product2.png";
import image4 from "../images/product3.png";
import image5 from "../images/product4.png";
import image6 from "../images/product5.png";
import briefcase from "../images/briefcase.png";
import sea from "../images/search1.jpg";
import second from "../images/second.jpg";
import image7 from "../images/languagesimg.png";
import image8 from "../images/mentor.png";
import image9 from "../images/tv.png";
import image10 from "../images/bulb.png";
import image11 from "../images/jobsearch.png";
import image12 from "../images/human-brain.png";
import image13 from "../images/scorecard.png";
import image14 from "../images/interview.png";
import image15 from "../images/intern.png";
import image16 from "../images/calender.png";
import shake from "../images/shake.jpg";
import { Row, Col, Card, Container } from "react-bootstrap";

class Corp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qfa1: false,
      qfa2: false,
      qfa3: false,
      qfa4: false,
      activeButton: 1,
      qfa5: false,
      showPopup: false,
      name: "",
      mobileNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      cnferr: "",
      passwordMatch: true,
      firstname: "",
      lastname: "",
      mobileno: "",
      email: "",
      collegename: "",
      businessname: "",
      firstnameerr: "",
      lookingtohire: "",
      lastnameerr: "",
      emailerr: "",
      mobilenoerr: "",
      designation: "",
      ques5: false,
      ques4: false,
      ques3: false,
      ques2: false,
      ques1: false,

      servicesList: [
        "Research Training",
        "Industrial Training Programme",
        "On-Campus Training Programme",
        "Hospital Based Training Programme",
        " Laboratory based Training Programme",
        " Research Paper Publication Support - Scopus, SCI, SCI-E, WoS, ABDC, and UGC Listed Journals",
        " Indian & International Patent Support upto Grant (UK, Vietnam, Canadian, German, South African",
        " Copyright Grant Support",
        " Book Publication support",
        " Technical Training Certification Courses",
        " On-Board Training Programme",
        " Pre-Final Year and Final Year Projects",
        " Govt. Funded Project Support",
        " Expert Training Programme",
        " Award Programme and Conclave Support",
        " Book of Records Support",
        " Faculty Development Programme",
        " Workshop and Seminars",
        " Credit Courses and Curriculum Designing",
        " Entrepreneurship Cell Development",
        " Paper Writing & Publication Services",
        " NAAC, NIRF, AIIRA, NBA Support",
        " Placement Support",
        " Consultancy Linkage",
      ],
    };
    this.timer = null;
  }
  componentDidMount() {
    // Show popup after 2 minutes
    setTimeout(() => {
      this.setState({ showPopup: true });
    }, 10000); // 2 minutes in milliseconds
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit1 = (e) => {
    e.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      let value;

      value = {
        email: this.state.email,
        first_name: this.state.firstname,
        Designation: this.state.designation,
        Businessname: this.state.businessname,
        lookingtohire: this.state.lookingtohire,
        phoneno: this.state.mobileno,
        country_code: "91",
      };

      // eslint-disable-next-line
      const response = axios
        .post(`${ip}/auth/cooperate`, value)
        .then((response) => {
          if (response) {
            alert("Registered Successfully");
            this.reset();
          }
        })

        .catch((err) => {
          alert("Please Enter Valid details");
          this.setState({});
        });
    }
  };
  reset = () => {
    this.setState({
      firstnameerr: "",
      firstname: "",
      lastname: "",
      designation: "",
      businessname: "",
      lookingtohire: "",

      category: "",
      categoryerr: "",
      lastnameerr: "",
      mobileno: "",
      mobilenoerr: "",
      email: "",
      emailerr: "",
      collegename: "",
    });
  };

  handleClose = () => this.setState({ isOpen: false });
  handleSubmit = (e) => {
    e.preventDefault();

    console.log("Submitted:", this.state);

    this.setState({ showPopup: false });
  };
  componentWillUnmount() {
    // Clear the timer when the component unmounts
    clearTimeout(this.timer);
  }
  togglemodal5 = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };
  handleEmailChange(e) {
    const email = e.target.value;
    this.setState({ email }, () => {
      this.validateEmail();
    });
  }

  validateEmail() {
    const { email } = this.state;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      this.setState({ emailerr: "Invalid email address" });
    } else {
      this.setState({ emailerr: "" });
    }
  }
  handleMobileNoChange(e) {
    const mobileno = e.target.value;
    this.setState({ mobileno }, () => {
      this.validateMobileNo();
    });
  }
  validate = () => {
    let cnferr = "";
    let codeErr = "";
    let firstnameerr = "";
    let lastnameerr = "";
    let emailerr = "";
    let categoryerr = "";
    let mobilenoerr = "";
    let roleErr = "";
    let hasErr = true;
    // if (this.state.code === "") {
    //   codeErr = "This field is required";
    //   this.setState({ codeErr });
    //   console.log("code Err", this.state.codeErr, hasErr);
    //   hasErr = false;
    // }

    if (this.state.mobileno === "") {
      mobilenoerr = "This field is required";
      this.setState({ mobilenoerr });
      console.log("phone Err", this.state.mobilenoerr, hasErr);
      hasErr = false;
    }
    if (this.state.firstname === "") {
      firstnameerr = "This field is required";
      this.setState({ firstnameerr });
      console.log("firstname Err", this.state.firstnameerr, hasErr);
      hasErr = false;
    }
    if (this.state.category === "") {
      categoryerr = "This field is required";
      this.setState({ categoryerr });
      console.log("category Err", this.state.categoryerr, hasErr);
      hasErr = false;
    }
    if (this.state.lastname === "") {
      lastnameerr = "This field is required";
      this.setState({ lastnameerr });
      console.log("lastname Err", this.state.lastnameerr, hasErr);
      hasErr = false;
    }
    if (this.state.email === "") {
      emailerr = "This field is required";
      this.setState({ emailerr });
      console.log("email Err", this.state.emailerr, hasErr);
      hasErr = false;
    }
    return hasErr;
  };

  validateMobileNo() {
    const { mobileno } = this.state;

    // You can define your own regex pattern based on your validation criteria
    // Example: 10-digit mobile number (for India)
    const mobileRegex = /^[0-9]{10}$/;

    if (!mobileRegex.test(mobileno)) {
      this.setState({ mobilenoerr: "Invalid mobile number" });
    } else {
      this.setState({ mobilenoerr: "" });
    }
  }
  handleClosePopup = () => {
    // Handle closing the pop-up
    this.setState({ showPopup: false });
  };

  handleSubmit = (userData) => {
    // Handle submitting user details
    console.log("User data:", userData);
    // Here, you can send the user data to your backend or perform any necessary actions
    this.setState({ showPopup: false });
  };
  render() {
    return (
      <div
        style={{
          overflow: "hidden",
          backgroundImage:
            "linear-gradient(to right, #ff7686 5%, #911bed 20%, #2e38bf 30%, #090920 45%)",
          backgroundSize: "100% 100%",
          backgroundPosition: "left top",
        }}
      >
        <Navbar />

        {/* <br /> */}

        <section className="hero-section">
          <br />
          <br />
          <br />
          <div className="container">
            <div className="text-center">
              <button className="btn-type-div-border-cst">
                Staffing companies sucks. We built Kambgeeks to bring
                transparency.
              </button>
            </div>
            <div className="d-flex justify-content-center text-center">
              <div className="col-sm-6 col-12">
                <h1>
                  Hire world-class
                  <br />
                  software developers Globally vetted by
                  <span className="cst-secondary-color"> AI</span>
                </h1>
              </div>
            </div>

            <h5 className="text-center mt-4">
              Global payroll, compliance, and benefits, all handled by us.
            </h5>
            <div className="text-center mt-4 mb-3">
              <a className="btn get-started-free-btn" href="/Register">
                Get Started for FREE
              </a>
            </div>

            <div className="text-center">
              <span className="text-center mt-2 pb-5">
                Save up to <b>70%</b> On hiring
              </span>
            </div>

            {/* <div className="bad-hire-costly-section"></div> */}
          </div>
          <br />
          <br />
          <br />
          <br />
        </section>
        <br />
        <br />

        <div>
          <h1
            style={{
              color: "white",
              fontWeight: "500",
              fontWeight: "Prompt",
              textAlign: "center",
            }}
          >
            It's free until you hire!
          </h1>
          <p
            style={{
              fontSize: "20px",
              color: "white",
              fontWeight: "500",
              fontWeight: "Prompt",
              textAlign: "center",
            }}
          >
            Hire full-time talent globally, with no hidden costs!
          </p>
          <div className="centered">
  <Row style={{ justifyContent: "center", margin: "0" }}>
    <Col
      className="col-lg-3 col-md-6 col-12 mb-4 aos-init aos-animate d-flex justify-content-center"
      style={{ padding: "0 10px", marginBottom: "3%" }}
    >
      <Card style={{ width: "300px", height: "600px" }}>
        <p style={{ paddingTop: "30px", paddingLeft: "30px", color: "#4B2C91", fontSize: "17px", fontFamily: "sans-serif", fontWeight: "bold" }}>
          Free Forever
        </p>
        <p style={{ marginTop: "-20px", paddingLeft: "30px", color: "#4B2C91", fontSize: "36px", fontFamily: "sans-serif", fontWeight: "bold" }}>
          $0
        </p>
        <p style={{ marginTop: "-20px", paddingLeft: "30px", color: "grey", fontSize: "16px", fontFamily: "sans-serif", fontWeight: "bold" }}>
          /month
        </p>
        <p style={{ paddingLeft: "30px", color: "grey", fontSize: "16px", fontFamily: "sans-serif", fontWeight: "bold", paddingRight: "20px" }}>
          Kambgeeks is like your own free recruiter. We make finding the perfect candidates super easy for you.
        </p>
        <p className="ptaglist">Unlimited Job Post</p>
        <p className="ptaglist">Unlimited Talent Search</p>
        <p className="ptaglist">GPT Vetting</p>
        <p className="ptaglist">Dedicated Account Manager</p>
        <p className="ptaglist">Add On: Outsourced Interview $30/Candidate</p>
        <div style={{ paddingLeft: "30px" }}>
          <Button style={{ width: "240px", fontFamily: "sans-serif", fontWeight: "bold", backgroundColor: "#4B2C91" }}>
            Start Hiring
          </Button>
          <Button style={{ width: "240px", fontFamily: "sans-serif", fontWeight: "bold", marginTop: "10px", color: "#4B2C91", backgroundColor: "white" }}>
            Book a demo
          </Button>
        </div>
      </Card>
    </Col>

    <Col
      className="col-lg-3 col-md-6 col-12 mb-4 aos-init aos-animate d-flex justify-content-center"
      style={{ padding: "0 10px", marginBottom: "3%" }}
    >
      <Card style={{ width: "300px", height: "600px" }}>
        <p style={{ paddingTop: "30px", paddingLeft: "30px", color: "#4B2C91", fontSize: "17px", fontFamily: "sans-serif", fontWeight: "bold" }}>
          Direct-hire on your payroll
        </p>
        <p style={{ marginTop: "-20px", paddingLeft: "30px", color: "#4B2C91", fontSize: "36px", fontFamily: "sans-serif", fontWeight: "bold" }}>
          10%
        </p>
        <p style={{ marginTop: "-20px", paddingLeft: "30px", paddingRight: "40px", color: "grey", fontSize: "16px", fontFamily: "sans-serif", fontWeight: "bold" }}>
          one-time of talent's annual salary
        </p>
        <p style={{ paddingLeft: "30px", color: "grey", fontSize: "16px", fontFamily: "sans-serif", fontWeight: "bold", paddingRight: "20px" }}>
          It's free until you hire! Pay only once you find the perfect candidate.
        </p>
        <p className="ptaglist">Everything in Free Forever, plus</p>
        <p className="ptaglist">Deeply Vetted Talent</p>
        <p className="ptaglist">90-days Replacement</p>
        <p className="ptaglist">Soft Background Check</p>
        <div style={{ paddingLeft: "30px", paddingTop: "60px" }}>
          <Button style={{ width: "240px", fontFamily: "sans-serif", fontWeight: "bold", backgroundColor: "#4B2C91" }}>
            Start Hiring
          </Button>
          <Button style={{ width: "240px", fontFamily: "sans-serif", fontWeight: "bold", marginTop: "10px", color: "#4B2C91", backgroundColor: "white" }}>
            Book a demo
          </Button>
        </div>
      </Card>
    </Col>

    <Col
      className="col-lg-3 col-md-6 col-12 mb-4 aos-init aos-animate d-flex justify-content-center"
      style={{ padding: "0 10px", marginBottom: "3%" }}
    >
      <Card style={{ backgroundColor: "#4B2C91", width: "300px", height: "600px" }}>
        <p style={{ paddingTop: "30px", paddingLeft: "30px", color: "white", fontSize: "17px", fontFamily: "sans-serif", fontWeight: "bold" }}>
          Hire on KambGeeks Payroll
        </p>
        <p style={{ marginTop: "-20px", paddingLeft: "30px", color: "white", fontSize: "36px", fontFamily: "sans-serif", fontWeight: "bold" }}>
          25%
        </p>
        <p style={{ marginTop: "-20px", paddingLeft: "30px", paddingRight: "40px", color: "white", fontSize: "16px", fontFamily: "sans-serif", fontWeight: "bold" }}>
          monthly of talent's salary
        </p>
        <p style={{ paddingLeft: "30px", color: "white", fontSize: "16px", fontFamily: "sans-serif", fontWeight: "bold", paddingRight: "20px" }}>
          Focus on growing your business while we manage all your hiring, payroll, and HR requirements.
        </p>
        <p className="ptaglist" style={{ color: "white" }}>Everything in Free Forever, plus</p>
        <p className="ptaglist" style={{ color: "white" }}>Deeply Vetted Talent</p>
        <p className="ptaglist" style={{ color: "white" }}>Life-Time Replacement</p>
        <p className="ptaglist" style={{ color: "white" }}>Background Check</p>
        <div style={{ paddingLeft: "30px", paddingTop: "60px" }}>
          <Button style={{ color: "#4B2C91", width: "240px", fontFamily: "sans-serif", fontWeight: "bold", backgroundColor: "white" }}>
            Start Hiring
          </Button>
          <Button style={{ color: "white", width: "240px", fontFamily: "sans-serif", fontWeight: "bold", marginTop: "10px", backgroundColor: "#4B2C91" }}>
            Book a demo
          </Button>
        </div>
      </Card>
    </Col>
  </Row>

  <style jsx>{`
    @media (max-width: 1024px) {
      .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    
    @media (max-width: 768px) { 
      .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  `}</style>
</div>


        </div>
        <br />
        <br />
        <br />

        <div class="centered" style={{ marginLeft: "4%" }}>
          <h1
            className="h-heading"
            style={{
              color: "white",
              fontWeight: "500",
              fontWeight: "Prompt",
              textAlign: "center",
            }}
          >
            Our Products & Services
          </h1>
          <h4 className="sub-desc" style={{ fontFamily: "Prompt" }}>
            A simple goal; make skill based Tech education Simple.
          </h4>
          <br></br> <br></br>
          <div class="image-section2">
            <div class="image-item2 col-lg-3 corp-2" data-aos="flip-left">
              <img src={image10} alt="Image 1" class="img-fluid"></img>
              <h6>
                <b style={{ fontFamily: "Prompt" }}>Corporate Traning Suite</b>
              </h6>
              <p style={{ fontFamily: "Prompt" }}>
                Eliminate your talent gaps,empower your employess to build the
                latest IT Skills.
              </p>
            </div>
            <div class="image-item21 col-lg-3" data-aos="flip-left">
              <img src={image3} alt="Image 2" class="img-fluid"></img>
              <h6>
                <b style={{ fontFamily: "Prompt" }}>Hire Trained Freshers</b>
              </h6>
              <p style={{ fontFamily: "Prompt" }}>
                Hire full-stack engineers,Data
                Scientists,Testers,Security-Analysts & more who are commited to
                their work.
              </p>
            </div>
            <div class="image-item22 col-lg-3" data-aos="flip-left">
              <img src={image4} alt="Image 3" class="img-fluid"></img>
              <h6>
                <b style={{ fontFamily: "Prompt" }}>Lateral Hiring</b>
              </h6>
              <p style={{ fontFamily: "Prompt" }}>
                Save your valuable time via our Lateral Hiring Team. Find the
                bet mid-level talent with ease.
              </p>
            </div>
          </div>
          <br /> <br /> <br /> <br />
          <div class="image-section2">
            <div class="image-item23 col-lg-3" data-aos="flip-left">
              <img src={image5} alt="Image 3" class="img-fluid"></img>
              <h6>
                <b style={{ fontFamily: "Prompt" }}>HYRE Assessment</b>
              </h6>
              <p style={{ fontFamily: "Prompt" }}>
                A Seamless end-to-end recruiment tool to filter the best
                candidates.
              </p>
            </div>
            <div class="image-item24 col-lg-3" data-aos="flip-left">
              <img src={image6} alt="Image 3" class="img-fluid"></img>
              <h6>
                <b style={{ fontFamily: "Prompt" }}>End-to-End Campus Hiring</b>
              </h6>
              <p style={{ fontFamily: "Prompt" }}>
                {" "}
                High Entry-level developers for any enterprise need
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
        <div style={{ backgroundColor: "white" }}>
          <br />
          <br />
          <h1
            className="h-heading"
            style={{
              color: "white",
              fontWeight: "900",
              color: "black",
              textAlign: "center",
            }}
          >
            Our Corporate Services
          </h1>
          <br />
          <div className="ace-container2">
            <div>
              <Row>
                <Col>
                  <img src={image8} class="sub-icon img-fluid" alt="img"></img>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="ae-head1" style={{ fontFamily: "Prompt" }}>
                    Corporate Training Suite
                    <br />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="h-content" style={{ fontFamily: "Prompt" }}>
                    Empower your to produce key business outcomes by making
                    upskilling and re-skilling as easy as powering up your
                    career technically. Tap into the power of curated learning
                    paths to guide teams through the exact skills they need to
                    progress from novice to guru across a variety of tech
                    skills.
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>
                  <img src={image6} alt="Image 3" class="img-fluid"></img>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="ae-head1" style={{ fontFamily: "Prompt" }}>
                    Pre-Structured Assessment Pipeline
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="h-content" style={{ fontFamily: "Prompt" }}>
                    our EdTech and running Tech company experience helped us in
                    creating a unique employee competency assessment tool.
                    Evaluate coding skills and behavioral patterns of
                    candidates.
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>
                  <img src={image13} class="sub-icon img-fluid" alt="img"></img>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="ae-head1" style={{ fontFamily: "Prompt" }}>
                    End to End Campus Recruitment
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="h-content" style={{ fontFamily: "Prompt" }}>
                    Hire Entry-Level Talents and save your time by interviewing
                    only the top performers. Get access to fresh talents from
                    top colleges & universities.
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <br />
          <br />
        </div>

        <section className="how-it-works-section pb-5">
          <div className="container">
            <div class="container">
              <div class="row">
                <div class="col-md-4">
                  <div class="apply-company-and-developers text-center">
                    <br />
                    <a class="btn btn-type-div-border-cst" href="ContactUs">
                      Save a Month on hiring!
                    </a>
                    <br />
                    <h1>
                      For
                      <span class="cst-secondary-color"> Company</span>
                    </h1>
                    <p>
                      Hire developers locally, offshore, or
                      <br />
                      nearshore, all vetted by AI and
                      <br />
                      handpicked by humans.
                    </p>
                    <a
                      className="get-started-free-btn"
                      style={{ textDecoration: "none" }}
                      href="Register"
                    >
                      Hire Software Engineers
                    </a>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="apply-company-and-developers text-center">
                    <br />
                    <a class="btn btn-type-div-border-cst" href="ContactUs">
                      Learn What Actually Need
                    </a>
                    <br />
                    <h1>
                      For
                      <span class="cst-secondary-color"> Community</span>
                    </h1>
                    <p>
                      Supercharge Your Learning with Kamb Geeks
                      <br />
                      Build Real-World Skills, Network with Peers,
                      <br />
                      and Unlock Career Opportunities.
                    </p>
                    <a
                      className="get-started-free-btn"
                      style={{ textDecoration: "none" }}
                      href="Register"
                    >
                      Join Community
                    </a>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="border-line-col-cst text-center">
                    <br /> <br />
                    <button class="btn btn-type-div-border-cst">
                      You deserve a better pay.
                    </button>
                    <h1>
                      For
                      <span class="cst-secondary-color"> Talents</span>
                    </h1>
                    <p>
                      Traditional job portals are dead.
                      <br />
                      Let KambGeeks match you with high-paying
                      <br />
                      full time tech jobs.
                    </p>
                    <a
                      className="get-started-free-btn"
                      style={{ textDecoration: "none" }}
                      href="Register"
                    >
                      Apply for jobs
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />
        {this.state.showPopup && (
          <Modal isOpen={this.togglemodal5} centered>
            <ModalHeader toggle={this.togglemodal5}>
              <p style={{ fontSize: "16px", color: "black" }}>
                Register with Kambgeeks to get 10% off on any course!
              </p>
            </ModalHeader>
            <ModalBody>
              <div className=" col-sm-12 col-12 ">
                <div className="container">
                  <form className="form1">
                    <div className="input_box">
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        value={this.state.firstname}
                        onChange={(e) =>
                          this.setState({
                            firstname: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input_box">
                      <input
                        type="text"
                        placeholder="Enter Your Email"
                        value={this.state.email}
                        onChange={(e) => this.handleEmailChange(e)}
                      />
                    </div>
                    <div className="input_box">
                      <input
                        type="number"
                        placeholder="Enter Mobile No"
                        value={this.state.mobileno}
                        onChange={(e) => this.handleMobileNoChange(e)}
                      />
                    </div>
                    <button
                      style={{
                        align: "center",
                        fontFamily: "Prompt",
                        color: "white",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Save
                    </button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <button
                      style={{
                        align: "center",
                        fontFamily: "Prompt",
                        color: "white",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Clear
                    </button>
                  </form>
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}

        <div style={{ width: "100%" }}>
          <div class="page-content">
            <div class="form-v4-content">
              <div class="form-left">
                <h2
                  style={{
                    fontSize: "110px",
                    color: "yellow",
                    textAlign: "center",
                  }}
                >
                  ❛❛
                </h2>
                <h1 className="lets" style={{ fontFamily: "Prompt" }}>
                  Let's Make <br />
                  it Happen
                  <br /> Together!
                </h1>
                {/* <p class="text-2">
                  <span>
                    School does not assure Employment Skill Assure You
                  </span>
                </p> */}
                {/* <div class="form-left-last">
                  <input
                    type="submit"
                    name="account"
                    class="account"
                    value="Have An Account"
                  />
                </div> */}
              </div>
              <form class="form-detail" action="#" method="post" id="myform">
                <h2 style={{ fontFamily: "Prompt" }}>
                  Register Your Business With Us
                </h2>
                <div class="form-group">
                  <div class="form-row form-row-1">
                    <label
                      // className="required"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Name
                    </label>{" "}
                    {/* <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.firstnameerr}
                    </div> */}
                    <input
                      type="text"
                      class="input-text"
                      required
                      value={this.state.firstname}
                      onChange={(e) =>
                        this.setState({
                          firstname: e.target.value,
                          firstnameerr: "",
                        })
                      }
                    />
                  </div>
                  <div class="form-row form-row-1">
                    <label
                      // className="required"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Designation
                    </label>{" "}
                    {/* <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.lastnameerr}
                    </div> */}
                    <input
                      type="text"
                      class="input-text"
                      // required
                      value={this.state.lastname}
                      onChange={(e) =>
                        this.setState({
                          lastname: e.target.value,
                          lastnameerr: "",
                        })
                      }
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-row form-row-1">
                    <label
                      // className="required"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Your Email
                    </label>{" "}
                    <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.emailerr}
                    </div>
                    <input
                      type="text"
                      class="input-text"
                      required
                      value={this.state.email}
                      onChange={(e) => this.handleEmailChange(e)}
                      // pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}"
                    />
                  </div>

                  <div class="form-row form-row-1 webonlyr">
                    <label
                    // className="required"
                    >
                      Mobile No
                    </label>
                    {/* <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.mobilenoerr}
                    </div> */}
                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <input
                            type="alphanumeric"
                            className="firstinput"
                            value={this.state.code}
                            onChange={(e) =>
                              this.setState({
                                code: e.target.value,
                                phnoErr: "",
                                codeErr: "",
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="9">
                        <input
                          // required
                          type="number"
                          //   id="last_name"
                          class="input-text"
                          value={this.state.mobileno}
                          onChange={(e) => this.handleMobileNoChange(e)}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div class="form-row form-row-1 mobonlyr">
                    <label
                      // className="required"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Mobile No
                    </label>{" "}
                    {/* <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.mobilenoerr}
                    </div> */}
                    <Row>
                      <Col>
                        <FormGroup>
                          <input
                            type="alphanumeric"
                            className="firstinput"
                            value={this.state.code}
                            onChange={(e) =>
                              this.setState({
                                code: e.target.value,
                                phnoErr: "",
                                codeErr: "",
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col style={{ marginLeft: "-4%" }}>
                        <input
                          type="text"
                          required
                          className="firstinput1"
                          value={this.state.mobileno}
                          onChange={(e) => this.handleMobileNoChange(e)}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-row form-row-1  ">
                    <label style={{ fontFamily: "Prompt" }}>
                      Business Name
                    </label>
                    <input
                      type="text"
                      //   id="your_email"
                      class="input-text"
                      value={this.state.businessname}
                      onChange={(e) =>
                        this.setState({
                          businessname: e.target.value,
                        })
                      }
                      // pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}"
                    />
                  </div>
                  <div class="form-row form-row-1  ">
                    <label style={{ fontFamily: "Prompt" }}>
                      Looking to Hire
                    </label>
                    <input
                      type="text"
                      //   id="your_email"
                      class="input-text"
                      value={this.state.lookingtohire}
                      onChange={(e) =>
                        this.setState({
                          lookingtohire: e.target.value,
                        })
                      }
                      // pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}"
                    />
                  </div>
                </div>

                <div class="form-row-last">
                  <input
                    type="submit"
                    class="register"
                    onClick={this.handleSubmit1}
                  />
                </div>
                <div>
                  {" "}
                  <h6 className="footerc" style={{ fontFamily: "Prompt" }}>
                    By creating an account I have read and agree to Kambgeeks
                    &nbsp;
                    <a
                      href="/terms"
                      className="term"
                      style={{ fontFamily: "Prompt" }}
                    >
                      Terms
                    </a>
                    &nbsp;
                  </h6>
                </div>
              </form>
            </div>
          </div>
        </div>
        <h4
          className="h-heading"
          align="center"
          style={{ fontFamily: "Prompt" }}
        >
          There is no alternative to build tech Career
        </h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/ContactUs">
            <div
              className="getdemo-center"
              align="center"
              style={{ fontFamily: "Prompt" }}
            >
              Contact Us
            </div>
          </a>
        </div>
        <br />
        <br />
        <Footer />
      </div>
    );
  }
}
export default Corp;
