// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForgotPassword {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 10%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), -1px -1px 5px rgba(0, 0, 0, 0.5);
}

.forgot-p {
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.forgot-btn {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
}

.forgot-btn {
  border: none;
  color: #fff;
}

.text-center {
  text-align: center;
}

.mt-3 {
  margin-top: 1rem;
}

a {
  color: #007bff;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ForgotPassword.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,aAAa;EACb,kBAAkB;EAClB,4EAA4E;AAC9E;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB","sourcesContent":[".ForgotPassword {\n  max-width: 400px;\n  margin: 0 auto;\n  margin-top: 10%;\n  padding: 20px;\n  border-radius: 5px;\n  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), -1px -1px 5px rgba(0, 0, 0, 0.5);\n}\n\n.forgot-p {\n  text-align: center;\n}\n\n.form-group {\n  margin-bottom: 15px;\n}\n\n.form-control {\n  width: 100%;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 10px;\n}\n\n.forgot-btn {\n  display: block;\n  width: 100%;\n  padding: 10px;\n  border-radius: 4px;\n}\n\n.forgot-btn {\n  border: none;\n  color: #fff;\n}\n\n.text-center {\n  text-align: center;\n}\n\n.mt-3 {\n  margin-top: 1rem;\n}\n\na {\n  color: #007bff;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
