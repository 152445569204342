import React, { Component } from "react";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../styles/technologies.css";
import Bounce from "react-reveal/Bounce";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Footer from "./footer";
import product1 from "./../images/productengineeringtools.png";
import { Row, Col, Card, Container } from "react-bootstrap";
import tech from "../images/tech.jpg";
import develop from "../images/develop.PNG";
import Navbar from "./Navbar.js";
class Technologies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qfa1: false,
      qfa2: false,
      qfa3: false,
      qfa4: false,
      activeButton: 1,
      qfa5: false,
      showPopup: false,
      name: "",
      mobileNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      cnferr: "",
      passwordMatch: true,
      firstname: "",
      lastname: "",
      mobileno: "",
      email: "",
      collegename: "",
      firstnameerr: "",
      lastnameerr: "",
      emailerr: "",
      mobilenoerr: "",

      ques5: false,
      ques4: false,
      ques3: false,
      ques2: false,
      ques1: false,

      servicesList: [
        "Research Training",
        "Industrial Training Programme",
        "On-Campus Training Programme",
        "Hospital Based Training Programme",
        " Laboratory based Training Programme",
        " Research Paper Publication Support - Scopus, SCI, SCI-E, WoS, ABDC, and UGC Listed Journals",
        " Indian & International Patent Support upto Grant (UK, Vietnam, Canadian, German, South African",
        " Copyright Grant Support",
        " Book Publication support",
        " Technical Training Certification Courses",
        " On-Board Training Programme",
        " Pre-Final Year and Final Year Projects",
        " Govt. Funded Project Support",
        " Expert Training Programme",
        " Award Programme and Conclave Support",
        " Book of Records Support",
        " Faculty Development Programme",
        " Workshop and Seminars",
        " Credit Courses and Curriculum Designing",
        " Entrepreneurship Cell Development",
        " Paper Writing & Publication Services",
        " NAAC, NIRF, AIIRA, NBA Support",
        " Placement Support",
        " Consultancy Linkage",
      ],
    };
    this.timer = null;
  }
  componentDidMount() {
    // Show popup after 2 minutes
    setTimeout(() => {
      this.setState({ showPopup: true });
    }, 10000); // 2 minutes in milliseconds
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClose = () => this.setState({ isOpen: false });
  handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, for example, send data to backend or perform other actions
    console.log("Submitted:", this.state);
    // Close the popup after submission
    this.setState({ showPopup: false });
  };
  componentWillUnmount() {
    // Clear the timer when the component unmounts
    clearTimeout(this.timer);
  }
  togglemodal5 = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };
  handleEmailChange(e) {
    const email = e.target.value;
    this.setState({ email }, () => {
      this.validateEmail();
    });
  }

  validateEmail() {
    const { email } = this.state;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      this.setState({ emailerr: "Invalid email address" });
    } else {
      this.setState({ emailerr: "" });
    }
  }
  handleMobileNoChange(e) {
    const mobileno = e.target.value;
    this.setState({ mobileno }, () => {
      this.validateMobileNo();
    });
  }
  validateMobileNo() {
    const { mobileno } = this.state;

    // You can define your own regex pattern based on your validation criteria
    // Example: 10-digit mobile number (for India)
    const mobileRegex = /^[0-9]{10}$/;

    if (!mobileRegex.test(mobileno)) {
      this.setState({ mobilenoerr: "Invalid mobile number" });
    } else {
      this.setState({ mobilenoerr: "" });
    }
  }
  handleClosePopup = () => {
    // Handle closing the pop-up
    this.setState({ showPopup: false });
  };

  handleSubmit = (userData) => {
    // Handle submitting user details
    console.log("User data:", userData);
    // Here, you can send the user data to your backend or perform any necessary actions
    this.setState({ showPopup: false });
  };
  render() {
    return (
      <div
        style={{
          overflow: "hidden",
          backgroundImage:
            "linear-gradient(to right, #ff7686 5%, #911bed 20%, #2e38bf 30%, #090920 45%)",
          backgroundSize: "100% 100%",
          backgroundPosition: "left top",
        }}
      >
        <div>
          {" "}
          <Navbar />
        </div>

        <br />

        <br />

        <br />
        {this.state.showPopup && (
          <Modal isOpen={this.togglemodal5} centered>
            <ModalHeader toggle={this.togglemodal5}>
              <p style={{ fontSize: "16px", color: "black" }}>
                Register with Kambgeeks to get 10% off on any course!
              </p>
            </ModalHeader>
            <ModalBody>
              <div className=" col-sm-12 col-12 ">
                <div className="container">
                  <form className="form1">
                    <div className="input_box">
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        value={this.state.firstname}
                        onChange={(e) =>
                          this.setState({
                            firstname: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="input_box">
                      <input
                        type="text"
                        placeholder="Enter Your Email"
                        value={this.state.email}
                        onChange={(e) => this.handleEmailChange(e)}
                      />
                    </div>
                    <div className="input_box">
                      <input
                        type="number"
                        placeholder="Enter Mobile No"
                        value={this.state.mobileno}
                        onChange={(e) => this.handleMobileNoChange(e)}
                      />
                    </div>
                    <button
                      style={{
                        align: "center",
                        fontFamily: "Prompt",
                        color: "white",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Save
                    </button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <button
                      style={{
                        align: "center",
                        fontFamily: "Prompt",
                        color: "white",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Clear
                    </button>
                  </form>
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
        <br />
        {/* <Row>
          <Col style={{ marginLeft: "7%", paddingTop: "10%", color: "white" }}>
            {" "}
            <div>
              {" "}
              <p
                className="career-tophead"
                align="left"
                style={{ fontFamily: "Prompt" }}
              >
                We Do Not Use Technologies <br /> We Live With Technologies
              </p>{" "}
            </div>
          </Col>

          <Col>
            <img src={tech} width="90%" height="90%" />
          </Col>
          <br />
          <br />
        </Row>
        <br /> */}
        <br />
        <br />
        <div
          class="centered"
          style={{ marginLeft: "4%", fontFamily: "Prompt" }}
          id="technologies"
        >
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "white",
                fontWeight: "600",
                textAlign: "left",
                fontFamily: "Prompt",
              }}
            >
              Data engineering Courses
            </h1>
            <br />
          </Bounce>
          <div class="flex-container" style={{ fontFamily: "Prompt" }}>
            <div>Appache Spark </div>
            <div>Cloudera</div>
            <div>kafka</div>
            <div>Spark SQL</div>
            <div>Spark Streaming</div>
            <div>mongoDB</div>
            <div>amazon web services</div>
            <div>Google Cloud</div>
            <div>Hive</div>
            <div>hadoop</div>
            <div>presto</div>
            <div>Appache Storm</div>
            <div>Cloudbase</div>
          </div>
          <br />
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "white",
                fontWeight: "600",
                textAlign: "left",
                fontWeight: "Prompt",
              }}
            >
              {" "}
              Data Science and Machine Learning Courses
            </h1>
            <br />
          </Bounce>
          <div class="flex-container" style={{ fontFamily: "Prompt" }}>
            <div>Appache Spark ML</div>
            <div>TensorFlow</div>
            <div>Caffe</div>
            <div>theano</div>
            <div>
              H<sub>2</sub>O.ai
            </div>
            <div>amazon webservices</div>
            <div>Microsoft Azure</div>
            <div>Google Cloud</div>
          </div>

          <br />
          <br />
          <Bounce>
            <h1
              className="tech-heading"
              style={{
                color: "white",
                fontWeight: "600",
                fontFamily: "Prompt",
                textAlign: "left",
              }}
            >
              {" "}
              Full Stack Development
            </h1>
          </Bounce>
          <div class="flex-container">
            <div>ReactJS</div>
            <div>NodeJS</div>
            <div>NestJS</div>
            <div>Python</div>
            <div>Flutter</div>
            <div>RestApi</div>
          </div>
          <br />
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "white",
                fontWeight: "600",
                fontFamily: "Prompt",
                textAlign: "left",
              }}
            >
              {" "}
              BI Courses
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>Pentaho</div>
            <div>Power BI</div>
            <div>Qlik</div>
            <div>Tableau</div>
            <div>metatron Discovery</div>
            <div>microStrategy</div>
            <div>looker</div>
          </div>
          <br />
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "white",
                fontFamily: "Prompt",
                fontWeight: "600",
                textAlign: "left",
              }}
            >
              {" "}
              Blockchain Courses
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>Ethereum</div>
            <div>Smart Contracts</div>
            <div>Quorum</div>
            <div>Corda</div>
            <div>Stellar</div>
            <div>Hyperledger</div>
          </div>
          <br />
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "white",
                fontWeight: "600",
                fontFamily: "Prompt",
                textAlign: "left",
              }}
            >
              {" "}
              Mobility Courses
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>Android</div>
            <div>iOS</div>
            <div>Xamarin</div>
            <div>Ionic</div>
            <div>React Native</div>
            <div>Flutter</div>
            <div>HTML5</div>
          </div>
          <br />
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "white",
                fontWeight: "600",
                textAlign: "left",
                fontFamily: "Prompt",
              }}
            >
              {" "}
              Open Source Courses
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>Java</div>
            <div>Python</div>
            <div>PHP</div>
            <div>Angular</div>
            <div>ReactJS</div>
            <div>NodeJS</div>
            <div>MEAN</div>
            <div>MERN</div>
          </div>
          <br />
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "white",
                fontWeight: "600",
                textAlign: "left",
                fontFamily: "Prompt",
              }}
            >
              {" "}
              Cloud Courses
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>AWS</div>
            <div>Azure</div>
            <div>Google CLoud</div>
          </div>
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "white",
                fontWeight: "600",
                fontFamily: "Prompt",
                textAlign: "left",
              }}
            >
              {" "}
              AR/VR Courses
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>2D dimensions</div>
            <div>3D dimensions</div>
            <div>XR</div>
            <div>Gaming</div>
            <div>XR Industry</div>
          </div>
          <br />
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "white",
                fontWeight: "600",
                fontFamily: "Prompt",
                textAlign: "left",
              }}
            >
              {" "}
              UI/UX Courses
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>Adobe XD</div>
            <div>Invision</div>
            <div>Storybook</div>
            <div>Sketch</div>
            <div>UXPin</div>
            <div>Figma</div>
            <div>Whimsical</div>
            <div>OmniGraffle</div>
            <div>Balsamiq</div>
            <div>JustInMind</div>
          </div>
          <br />
          <br />
          <br />
        </div>

        <section className="how-it-works-section pb-5">
          <div className="container">
            <div class="container">
              <div class="row">
                <div class="col-md-4">
                  <div class="apply-company-and-developers text-center">
                    <br />
                    <a class="btn btn-type-div-border-cst" href="ContactUs">
                      Save a Month on hiring!
                    </a>
                    <br />
                    <h1>
                      For
                      <span class="cst-secondary-color"> Company</span>
                    </h1>
                    <p>
                      Hire developers locally, offshore, or
                      <br />
                      nearshore, all vetted by AI and
                      <br />
                      handpicked by humans.
                    </p>
                    <a class="btn hire-software-engineer" href="ContactUs">
                      Hire Software Engineers
                    </a>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="apply-company-and-developers text-center">
                    <br />
                    <a class="btn btn-type-div-border-cst" href="ContactUs">
                      Learn What Actually Need
                    </a>
                    <br />
                    <h1>
                      For
                      <span class="cst-secondary-color"> Community</span>
                    </h1>
                    <p>
                      Supercharge Your Learning with Kamb Geeks
                      <br />
                      Build Real-World Skills, Network with Peers,
                      <br />
                      and Unlock Career Opportunities.
                    </p>
                    <a class="btn hire-software-engineer" href="ContactUs">
                      Join Community
                    </a>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="border-line-col-cst text-center">
                    <br /> <br />
                    <button class="btn btn-type-div-border-cst">
                      You deserve a better pay.
                    </button>
                    <h1>
                      For
                      <span class="cst-secondary-color"> Talents</span>
                    </h1>
                    <p>
                      Traditional job portals are dead.
                      <br />
                      Let KambGeeks match you with high-paying
                      <br />
                      full time tech jobs.
                    </p>
                    <a class="btn hire-software-engineer" href="ContactUs">
                      Apply for jobs
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        <br />

        <div>
          <br />
          <h4 className="h-heading" align="center">
            There is no alternative to build tech Career
          </h4>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontFamily: "Prompt",
            }}
          >
            <a href="/ContactUs">
              <div
                className="getdemo-center"
                align="center"
                style={{ fontFamily: "Prompt" }}
              >
                Contact Us
              </div>
            </a>
          </div>
          <br />
          <br />
          <Footer />
        </div>
      </div>
    );
  }
}
export default Technologies;
